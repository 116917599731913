.sopac-modifier-customizeblock {
  & .cart-food-quantity-change {
    display: flex;
    align-items: center;

    .cart-food-quantity {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }
    @include quantitiyPlusButton();
    @include quantitiyMinusButton();
  }
}
