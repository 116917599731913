@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($custom-breakpoints, $breakpoint) {
    $value: map-get($custom-breakpoints, $breakpoint);

    @if $value==0 {
      @content;
    } @else {
      @media (min-width: $value) {
        @content;
      }
    }
  } @else {
    @warn "Invalid breakpoint: `#{$breakpoint}`. Available breakpoints are: #{map-keys($custom-breakpoints)}.";
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($custom-breakpoints, $breakpoint) {
    $value: map-get($custom-breakpoints, $breakpoint);

    @if $value==0 {
      @content;
    } @else {
      @media (max-width: ($value - 0.02px)) {
        @content;
      }
    }
  } @else {
    @warn "Invalid breakpoint: `#{$breakpoint}`. Available breakpoints are: #{map-keys($custom-breakpoints)}.";
  }
}

@mixin height-line($size) {
  height: $size;
  line-height: $size;
}
