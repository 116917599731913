.orderConfirmScreen {
  display: flex;
  justify-content: center;
  height: 526px;
  width: 100%;
  left: 0;
  top: 100px;
  border-radius: 0;
  background-color: $Brand-Black;
  border-bottom: 1px solid $Dark-Gray;

  .currentStatus {
    font-family: $National2Condensed;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #f8f7f5;
    padding-top: 25px;
    padding-bottom: 16px;
  }

  .progressBar {
    font-family: $National2Condensed;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #f8f7f5;
    padding-top: 23px;
    padding-bottom: 84px;
  }

  .Progress {
    width: 700px;
    height: 12px;
    // background-color: grey;
    border-radius: 20px;
    background: repeating-linear-gradient(65deg, #222 4px, #222 6px, #333 6px, #333 8px);
  }

  .bar {
    width: 10px;
    height: 12px;
    background-color: #e4002b;
    border-radius: 20px;
    transition: width 6s;
  }

  .confirmOrder {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
  }
}

.orderConfirmScreenMob {
  display: flex;
  justify-content: center;
  height: 336px;
  width: 100%;
  left: 0;
  top: 100px;
  border-radius: 0;
  background-color: $Brand-Black;
  border-bottom: 1px solid $Dark-Gray;

  .ProgressMob {
    width: 100%;
    height: 10.19px;
    // background-color: grey;
    border-radius: 20px;
    background: repeating-linear-gradient(65deg, #222 4px, #222 6px, #333 6px, #333 8px);
  }

  .barMob {
    width: 10px;
    height: 10.19px;
    background-color: #e4002b;
    border-radius: 20px;
    transition: width 6s;
  }

  .confirmOrderMobile {
    font-family: $National2Condensed;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  .currentStatusMob {
    font-family: $National2Condensed;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #f8f7f5;
    padding-top: 25px;
    padding-bottom: 16px;
  }

  .progressBarMob {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #e3e3e3;
    padding-top: 23px;
    padding-bottom: 70px;
  }
}
.progress-bar-list {
  display: flex;
  justify-content: space-around;
  margin: 0 12%;
  & .ProgressDelivery {
    width: 15%;
    height: 12px;
    border-radius: 20px;
    margin-top: 20px;
  }
  & .Bar {
    background: repeating-linear-gradient(65deg, transparent 4px, transparent 6px, $Dark-Gray 6px, $Dark-Gray 8px);
  }
  & .selectedBar {
    background-color: #e4002b;
  }
}
