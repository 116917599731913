.lookup-order-modal {
  & .modal-content {
    position: absolute;
    width: 580px;
    left: 32%;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 4%;
    min-height: 579px;
    max-height: 80%;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-header {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 100%;

      @media #{$breakpoint-below-tablet} {
        height: 6%;
        padding: 3% 1%;
        width: 100%;
        box-shadow: 0 0 black;
        font-family: $National2Regular;
        display: flex;
        justify-content: center;
      }
    }

    & .header-title {
      margin: 0 !important;
      padding-left: 20px;
      font-family: $National2Condensed;

      @media #{$breakpoint-below-tablet} {
        margin: 0 0 0 0% !important;
        padding-left: 0;
        font-family: $National2Regular !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #494949;
      }
    }

    & .modal-body {
      padding-right: 50px;
      padding-left: 50px;
      padding-top: 25.5px;
      padding-bottom: 11px;

      @media #{$breakpoint-below-tablet} {
        padding-right: 11px;
        padding-left: 11px;
        padding-top: 20.5px;
        padding-bottom: 68px;
      }
    }
  }
}

.Base-modal {
  & .modal-content {
    position: absolute;
    width: 420px;
    left: 32%;
    height: auto;
    top: 3%;
    // min-height: 451px;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-header {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @media #{$breakpoint-below-tablet} {
        font-family: $National2Regular;
        padding: 3% 1%;
        width: 100%;
        height: 6%;
        box-shadow: 0 0 black;
        display: flex;
        justify-content: center;
      }
    }

    & .header-title {
      margin: 0 !important;
      padding-left: 20px;
      font-family: $National2Condensed;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;

      @media #{$breakpoint-below-tablet} {
        font-family: $National2Regular !important;
        margin: 0 0 0 0% !important;
        padding-left: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-transform: capitalize;
        text-align: center;
        color: #494949;
      }
    }

    & .modal-body {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 25.5px;
      height: auto;
      background: white;

      @media #{$breakpoint-below-tablet} {
        padding-right: 11px;
        padding-left: 11px;
        padding-top: 20.5px;
        height: auto;
      }
    }
  }
}

.LookUpmodalContants {
  .detailsAccordian {
    margin-bottom: 30px;

    .global-accordian-container .global-accordian-header-container {
      height: 60px;
      padding: 19px;
      padding-left: 6px;
    }

    .global-accordian-container {
      border-top: 2px solid black;
      border-bottom: 2px solid black;
    }

    .global-accordian-container .global-accordian-header-container .global-accordian-arrow {
      width: 15px;
      height: 15px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
    }

    .global-accordian-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
    }
  }

  .InputArea {
    height: auto;
    width: 540px;
    border-radius: 3px;
    background: #f8f7f5;
    padding-top: 42px;
    padding-left: 19px;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #202124;
    padding-bottom: 20px;
    padding-right: 19px;

    @media #{$breakpoint-below-tablet} {
      width: auto;
      padding-top: 35px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .InputHeading {
    padding-left: 0;

    @media #{$breakpoint-below-tablet} {
      padding-left: 12px;
    }
  }

  .PhoneNumfield {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    background: #f8f7f5;
    color: #202124;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #202124;
    width: 98%;
  }

  .InputHeader {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #494949;
    letter-spacing: -0.1px;
    text-transform: capitalize;
  }

  .error-text {
    color: #ff2e00;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
  }

  .totalAmount {
    display: flex;
    justify-content: space-between;
    margin-top: 19.82px;
    margin-bottom: 0;
  }

  .inputLookUpNumbox {
    margin-top: 8px;

    .lookup-mandatory-note {
      font-family: $National2Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
      color: #494949;
      margin: 0px;
      text-align: right;
      text-transform: none;
    }

    .info-inputData {
      background: #f8f7f5;
      padding-bottom: 8px;
    }

    .info-labelData {
      color: #202124;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-transform: capitalize;
      letter-spacing: -0.1px;
    }

    .field {
      margin: 14px !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .inputLookUpIdbox {
    margin-top: 30px;
  }

  .OrdNumfield {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    background: #f8f7f5;
    color: #202124;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #202124;
    // border:none none 1px solid #202124 none;
    width: 98%;
  }

  .ErrorField {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    background: #f8f7f5;
    color: red;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid red;
    width: 98%;
    // margin-top: 32px;
  }

  .buttonItems {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .buttonItemsDone {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    .closebtn-cls {
      margin-right: 30px;
      border: 2px solid black;
    }
  }

  .errorMessage {
    color: red;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.1px;
    text-transform: capitalize;
    margin-top: 6px;
    margin-left: 20px;
  }

  .exMark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-family: $National2Regular;
    font-weight: normal;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid red;
    width: 1rem;
    height: 1rem;
    color: red;
    margin-top: 6px;
  }

  .dateCompleted {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #494949;
    text-align: left;
  }

  .fonts {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #494949;
    text-transform: capitalize;
  }

  .amount {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #202124;
  }

  .foodName {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202124;
  }

  .ItemPrice {
    text-align: right;
    font-family: $National2Regular;
    margin-top: 5px;
    font-weight: 600;
  }

  .orderLookUpData {
    .Orderheading {
      display: flex;
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: 20px;
      border-bottom: 2px solid #202124;
    }

    .yourOrder {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;

      @media #{$breakpoint-below-tablet} {
        width: 51%;
      }
    }

    .idNumber {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
    }

    .CompletedDateAndTime {
      display: grid;
      grid-column-gap: 0;
      grid-template-columns: 30% auto;
      padding: 10px;
      margin-bottom: 0;
      margin-top: 10px;

      @media #{$breakpoint-below-tablet} {
        margin-bottom: 10px;
        margin-top: 15px;
        grid-template-columns: 38% auto;
      }
    }

    .CompletedCallAndDirection {
      display: grid;
      grid-column-gap: 0;
      grid-template-columns: 30% auto;
      padding: 10px;
      margin-bottom: 10px;
      margin-top: 0;
      padding-top: 0;

      @media #{$breakpoint-below-tablet} {
        padding: 1px;
        margin-bottom: 10px;
        margin-top: 0;
        grid-template-columns: 39% auto;
      }
    }

    .anchorTags {
      color: black;
    }

    .KfcphoneNums {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #202124;
      margin-right: 37px;
      cursor: pointer;
      text-transform: capitalize;

      @media #{$breakpoint-below-tablet} {
        // margin-right: 75px;
        display: block;
        padding-bottom: 11px;
      }
    }

    .KfcDirectionIcon {
      margin-right: 11px;
    }
  }
}

::-webkit-scrollbar {
  width: 0.5px;
}

#maps {
  height: 641px;
  width: 100%;

  @media #{$breakpoint-below-tablet} {
    height: 575px;
  }
}

.LookUpmap-modal {
  & .modal-content {
    position: absolute;
    width: 60%;
    height: auto;
    left: 24%;
    top: 12%;
    bottom: 12%;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-body {
      padding: 1%;
    }

    & .modal-header {
      // border-bottom: 0;

      @media #{$breakpoint-below-tablet} {
        padding: 3% 1%;
      }

      // & .custom-modal-close {
      // 	width: 31px;
      // 	height: 8px;
      // 	right: 13px;

      // 	@media #{$breakpoint-below-tablet} {
      // 		width: 37px;
      // 		height: 16px;
      // 		right: 3px;
      // 	}
      // }
    }
  }
}
#lookupButton {
  border: 2px solid black;
}
