.setLocationData {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 9px 0;
  height: 50px;
  margin-right: 1.2%;

  @media #{$mobile-only} {
    padding: 8px 22px;
    height: auto;
    margin-right: 0.3%;
  }

  .google-location-img {
    height: 18px;
    width: 13px;
    margin-right: 10px;
    margin-top: -1px;
    @media (max-width: 580px) {
      margin-top: -20px;
      width: 11px;
      height: 16px;
    }
  }
  .setLocationText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $Brand-Black;
    font-family: $National2Regular;
    height: 32px;
    padding-top: 5px;
    line-height: 24px;

    @media #{$breakpoint-below-iphone5} {
      width: 55% !important;
    }

    @media #{$mobile-only} {
      width: 62% !important;
      height: auto;
      padding-top: 0px;
      line-height: 1.5;
    }
  }

  .setLocation-button {
    margin-left: 23px;
    color: $Brand-Black !important;
    background-color: $white !important;
    @media #{$mobile-only} {
      margin-left: 13px;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .setLocation-button {
        color: $Brand-Black !important;
        background-color: $white !important;
      }
    }
  }
  .button-contained {
    padding-top: 2px;
    font-family: $National2Medium;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #202124;
    @media #{$breakpoint-iphone-se-2020} {
      border: 1px solid #202124;
      box-shadow: none;
    }
    &::after {
      box-shadow: none !important;
    }
  }
}
.setLocationdivider {
  margin: 0%;
  border-top: 1px solid rgb(219, 219, 219);
}

.whiteButton.blackBorder {
  border: 1px solid rgb(73, 73, 73) !important;
  @media #{$mobile-only} {
    border: 1px solid rgb(32, 33, 36) !important;
  }
}
