.cart-alert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  // min-width: 273px;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: 50%;
  margin-top: -50px;
  background: #202124;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  & .cart-alert-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    margin-left: 10px;
  }
}

.Toastify__close-button {
  display: none;
}
//  .Toastify__toast-container{
//     top:50%;
//     @media #{$breakpoint-below-tablet}{
//        top: 50%!important;
//     //    width: 75%!important;
//     //    margin: 0 12.5%!important;
//     }
//  }
//  .Toastify__toast-body{
//     margin: 20px;
//     padding: 0;
//  }.Toastify__toast--dark{
//     background: $Brand-Black;
//     color: $white;
//  }
//  .sign-in-toast{
//     width: 298px;
//     top: 451px;
//     & .img-text-div{
//        text-align: $center;
//     }
//     & .text{
//        font-style: normal;
//        font-weight: 500;
//        font-size: 12px;
//        line-height: 22px;
//        font-family: $National2Regular;
//        margin-left: 5px;
//     }
//  }
