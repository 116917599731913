.savedAddress-store-count {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  @media #{$mobile-only} {
    margin-top: 7%;
  }

  & .saved-address-count-title {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;
  }

  & .location-img {
    margin-right: 5px;
    height: 9px;
    width: 9px;
  }

  & .map-link {
    color: $Brand-Black !important;

    & .map-img {
      margin-right: 5px;
      height: 10px;
      width: 11px;
    }
  }
}

.savedAddress-module-card {
  border-radius: '8px';
  background: '#FFFFFF';
  margin-top: 10px;

  & .inside-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 40px 22px 40px;
    background: $white;
    border: 1px solid $Light-Gray;
    box-sizing: border-box;
    border-radius: 8px;

    @media #{$breakpoint-below-tablet} {
      display: block;
      text-align: center;
    }

    & .search-store-card-btn {
      width: 153px;
      height: 44px;
      font-family: $National2Regular;
      font-style: normal;
      font-size: 14px;
      font-weight: 24px;
      margin: 0 1%;
      padding: 11px 0;
    }

    & .address-section {
      //width: 364px;
      width: 60%;
      font-family: $National2Regular;
      font-style: normal;
      margin-right: 10px;

      @media #{$breakpoint-below-tablet} {
        width: 100%;
        // div{
        //     margin-bottom: 5px;
        // }
      }
    }

    & .address-type {
      // width: 153px;
      // height: 44px;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 4px;
      color: $Dark-Gray;
      font-family: $National2Regular;
    }

    & .address {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      color: $Dark-Gray;
      font-family: $National2Regular;
      word-wrap: break-word;

      & span:first-child {
        font-weight: bold;
      }

      & span:nth-child(2) {
        font-weight: bold;
      }

      @media #{$breakpoint-below-tablet} {
        margin: 0 0 10px 0;
      }
    }
  }
}

.search-store-global {
  & .savedAddress-module-card {
    @media #{$breakpoint-below-tablet} {
      text-align: center;
      & .inside-card {
        display: block;
        & .address {
          font-weight: 600;
        }
        & .search-store-card-btn {
          margin-top: 3%;
        }
        & .address-section {
          width: auto;
        }
      }
    }
  }
}
