.chicken-mile-v2 {
  padding-inline: 0 !important;

  .chiken-mile-wrapper {
    margin-inline: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 58px;

    .mileage-loyalty-status {
      height: 336px;
      margin-inline: 0;

      .loyalty-status-container {
        padding-left: 30px;

        .loyalty-status {
          padding: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-inline: 20px !important;

    .my-page-title {
      margin-top: 35px;
    }

    .sub-chiken-mile-view-v2 {
      padding-inline: 0;

      .mileage-loyalty-status {
        margin-inline: 0;
      }
    }
  }

  .sub-chiken-mile-view-v2 {
    flex-direction: column;
  }

  .mileage-loyalty-status {
    padding: 30px;

    .membership-right-section {
      padding: 15px;
      color: $Brand-Black;
      background: $white;

      .skelton-v2 {
        @include height-line(24px);
      }

      .membership-id-container {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 400;

        .membership-id-value {
          @include height-line(24px);
          margin-left: 4px;
          font-family: 'National 2 Condensed';
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0.04em;
        }
      }

      .current-mile {
        @include height-line(24px);
        font-size: 14px;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }

      .loyalty-qr {
        margin-top: 5px;

        .loyalty-qr-wrap {
          padding: 0;

          .qr-placeholder {
            width: 160px;
            height: 160px;
          }

          .placeholder {
            margin-top: 0;
          }

          .pnl-refresh {
            @include height-line(24px);
            margin-top: 5px;
            font-size: initial;
            gap: 10px;

            #qr-timer {
              font-size: 14px;
              font-weight: 700;
            }

            #btn-refresh-qr {
              @include height-line(24px);
              padding: 0;

              img {
                height: 1.5rem;
              }
            }
          }
        }
      }
    }

    .loyalty-status-container {
      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }

      .loyalty-status {
        color: $white;
        width: 100%;
      }
    }
  }

  .global-accordian-container {
    margin-block: 20px;
    border-bottom: 1px;
    background-color: $Mashed-Potato-Gray;

    .global-accordian-header-container {
      padding: 20px;
      align-items: center;

      .global-accordian-header {
        @include height-line(24px);

        h3 {
          font-size: 14px;

          img {
            margin-right: 4px;
          }
        }
      }

      .global-accordian-arrow {
        width: 15px;
        height: 9px;
      }
    }

    .global-accordian-content {
      .has-scroll {
        .loyalty-history-item {
          padding-right: 15px;
        }
      }

      .ranking-tabs {
        padding: 10px 20px 20px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }

        .nav-tabs {
          border-bottom: 0;
          margin-bottom: 10px;

          .nav-item {
            text-align: center;
            flex-basis: 50%;
            border-radius: 30px;
            height: 40px;
            font-size: 13px;
            font-weight: 400;
            color: $Brand-Black;
            background-color: $white;
          }

          .active {
            background-color: $Brand-Black;
            font-weight: 700;
            color: $white;
          }
        }

        .active {
          max-height: 285px;
          overflow-y: auto;

          &::-webkit-scrollbar,
          &::-webkit-scrollbar-thumb {
            width: 5px;
            border-radius: 5px;
            background-color: $Inactive-Gray;
          }

          .no-history-data,
          .loyalty-history-item {
            background-color: $Mashed-Potato-Gray;
            min-height: 57px;
            padding-bottom: 10px;
            color: $Brand-Black;
          }

          .loyalty-history-item {
            display: flex;
            box-sizing: border-box;
            align-items: end;
            justify-content: space-between;
            border-bottom: 1px solid $white;

            .loyalty-history-date {
              font-size: 11px;
              font-weight: 400;

              flex: 1;
              min-width: 0;
              margin-right: 4px;

              label,
              p {
                margin-bottom: 0;
              }

              label {
                @include height-line(16px);
                color: $Gray;
              }

              p {
                font-size: 14px;
                font-weight: 400;
              }
            }

            .loyalty-history-point {
              font-family: 'National 2 Condensed';
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0.04em;

              div {
                @include height-line(24px);

                .lbl-point {
                  @include height-line(16px);
                  color: $Gray;
                  font-family: 'Noto Sans JP';
                  font-size: 11px;
                  font-weight: 400;
                  padding-left: 4px;
                  letter-spacing: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  .loyalty-status {
    .loyalty-status-skeleton {
      width: 50%;
      height: 2rem;

      .skeleton-loading {
        &::after {
          background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.25), transparent);
        }
      }
    }

    .status-miles-value {
      font-family: 'National 2 Condensed';
      font-size: 36px;
      height: 32px;
      line-height: 34px;
      letter-spacing: 0.04em;
      margin-inline: 4px;
    }

    .status-miles-tex {
      @include height-line(21px);
      font-size: 14px;
    }

    .lbl-mileage-expiration {
      @include height-line(16px);
      margin-top: 5px;
      font-size: 11px;
      letter-spacing: normal;
    }

    .meter {
      position: relative;
      padding: 0;
      height: 25px;
      margin-block: 10px;
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: none;

      > span {
        padding-left: 0;
        border-radius: 25px;
      }

      &-miles-conteiner-text {
        @include height-line(16px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $Brand-Black;
        font-size: 11px;
        white-space: nowrap;

        .meter-miles-value {
          margin-inline: 4px;
          font-family: 'National 2 Condensed';
          font-size: 18px;
          height: 18px;
          line-height: 18px;
          letter-spacing: 0.04em;
        }
      }
    }

    .lbl-mileage-link {
      @include height-line(21px);

      .lbl-mileage-link-item {
        color: $white;
        line-height: inherit;
      }
    }
  }

  .loyalty-linkage {
    .linkage-cards {
      .linkage-card {
        gap: 13px;
        height: 56px;
        margin-left: 0;
        padding: 10px 0;
        border-top: 0 !important;
        border-color: $Mashed-Potato-Gray;
        background-color: $Mashed-Potato-Gray;

        img {
          width: 36px;
          height: 36px;
        }

        .linkage-title {
          @include height-line(21px);

          .linkage-title-text {
            color: $Brand-Black;
            font-weight: 400;
          }
        }

        .linkage-btn {
          @include height-line(21px);
          font-size: 14px;
          color: $Brand-Black;
          font-weight: 400;

          img {
            width: 12px;
            height: 12px;
          }

          span {
            text-decoration: underline;
          }

          &:disabled {
            opacity: 1;
            color: $Inactive-Gray;

            img {
              display: none;
            }
          }
        }
      }
    }
  }
}
