@mixin centeredItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin headerItems {
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
}

body {
  margin: 0;

  &.#{$channel-google} {
    div {
      &##{$root-main} {
        [data-id*='#{$header-sign-in}'] {
          display: none;
        }
      }
    }
  }

  &.#{$channel-google},
  &.#{$channel-phone-pe} {
    div {
      &##{$root-main} {
        [data-id*='#{$checkout-sign-in}'] {
          display: none;
        }
      }
    }
  }

  &.#{$channel-debug} {
    &.#{$channel-google},
    &.#{$channel-phone-pe},
    &.#{$channel-pay-tm} {
      div {
        &##{$root-main} {
          [data-id*='#{$header-sign-in}'] {
            display: flex;
          }
        }
      }
    }
  }
}

.header {
  background: #fff;
  height: 100px;
  z-index: $z-index-max;
  top: 0;
  position: sticky;

  &.mobile {
    @media #{$breakpoint-below-tablet} {
      display: flex;
    }
    & .header-item-buttons {
      & img {
        width: 100%;
        min-width: 1rem;
        max-width: 3rem;
        margin: auto;
      }

      @-moz-document url-prefix() {
        & img {
          width: 80px;
        }

        & .header-hamburger-icon {
          width: 100%;
        }
      }
      @-moz-document url-prefix() {
        #BrowserSpecificStyle {
          width: 20px;
        }
      }
    }

    & .hamburger-close-btn {
      & .header-item-buttons {
        & img {
          width: 70%;
          margin: auto;
        }

        @-moz-document url-prefix() {
          & img {
            width: 70%;
          }
        }
      }
    }
  }

  @media #{$breakpoint-below-tablet} {
    height: 45px;
  }

  .payment-header {
    width: 100%;
    height: 100%;
    display: flex;
    .payment-header-content {
      width: 95%;
      margin: auto;
      .secondary-header-element {
        width: 200px;
        .needHelpDesk {
          margin-left: auto;
        }
      }
    }
  }

  & .headerLogo {
    @media #{$breakpoint-above-tablet} {
      margin-top: 38px;
      margin-right: 44.26px;
      margin-left: 130px;
      margin-bottom: 39.48px;
      cursor: pointer;
      &.secondary {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .textualLogo {
      color: #202124;
      font-size: 1.5rem;
      font-style: normal;
      font-family: $National2Condensed;
      font-weight: bold;
      line-height: 1.5rem;
      text-transform: uppercase;
      margin: auto;
    }
  }

  & .paymentHeader {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  & select {
    -moz-appearance: none;
  }

  & .header-language-select {
    border: none;
    font-family: $National2Regular;
    text-transform: capitalize;
  }

  .header-navs {
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      margin: auto;
    }
  }

  & .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;

    @media #{$breakpoint-below-tablet} {
      justify-content: space-between;
    }

    @media #{$mobile-only} {
      margin-top: 0;
    }

    & .nav-item {
      white-space: pre;
      font-family: $National2Medium;
      padding: 0 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: $center;
      color: $Brand-Black;
      text-decoration: none;

      @media #{$breakpoint-below-tablet} {
        padding: 0 2%;
        width: 39%;
      }

      @-moz-document url-prefix() {
        .cartIcon.navRightIcons {
          width: 100px;
        }
      }

      &.cart-image {
        button {
          margin-left: auto;
        }
      }
    }

    & .navItemsLeft {
      display: flex;
      padding-top: 4px;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
    }

    & .navItemsRight {
      display: flex;
      position: absolute;
      right: 0%;
      margin-right: 131.22px;
      width: auto;
      font-weight: 500;
    }

    & .header-item-buttons {
      all: unset;
      cursor: pointer;
      position: relative;
    }

    & .nav-item-left {
      padding: 0;
      margin-right: 43px;
    }
  }

  & .hamburger {
    margin: auto 1%;
  }

  @supports (-moz-appearance: none) {
    .dropdown-img {
      background: url('../../staticAssets/images/dropdown.svg') no-repeat 100% 100%;
      height: 15px;
      width: 12px;
      margin-top: -2px;
    }
  }

  .notcarticon {
    text-transform: capitalize;
    font-weight: bold;
    margin-left: 12px;
  }

  .backIcon {
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .needHelpDesk {
    @include headerItems();
    color: #202124;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
    }

    label.needHelp {
      color: $Brand-Black;
      margin-left: 2.225rem;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: bold;
      font-size: 1rem;
    }

    .roundedQmark {
      @include centeredItems();
      text-decoration: none !important;
      position: absolute;
      font-weight: bold;
      border-radius: 50%;
      border: 2px solid $Brand-Black;
      width: 1.25rem;
      height: 1.25rem;
      color: $Brand-Black;
    }
  }

  .signinLink {
    @include headerItems();
  }

  .secondaryChekoutheader {
    margin: 0 7%;

    @media #{$breakpoint-below-tablet} {
      margin: 0;
      width: 100%;
    }

    @media (min-width: 1650px) {
      margin: 0 8%;
    }

    .secondary-header-element {
      width: 260px;
      @media #{$breakpoint-below-tablet} {
        width: 20.2%;
        max-width: 80px;
      }
    }
  }
}

#headermenu-id {
  z-index: $z-index-max + 1;
}

.sidenav {
  height: 104vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.1s;
  padding-top: 0;

  .row {
    margin: 0 -5px;
  }
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.mobile-modal-header {
  background: $white;
  padding-left: 3% !important;
  height: 45px;
  border-bottom: 1px solid rgb(219, 219, 219);

  & .mobCartIcon {
    width: 100%;
    max-width: 2.5rem;
  }

  @media #{$breakpoint-below-tablet} {
    border-radius: 0;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 22px;
    border-bottom: 1px solid rgb(219, 219, 219);
  }

  @media (max-width: 480px) {
    height: 52px;
    border-bottom: 1px solid rgb(219, 219, 219);
  }

  & .header-title {
    font-family: $National2Condensed;
    margin: auto;
  }

  & .stripes {
    margin: 0 auto;
    height: 6vh;
  }

  & .header-item-buttons {
    font-family: $National2Regular;
    font-weight: normal;
    font-style: normal;
    background: $white;
    border: none;
  }

  & .custom-modal-close {
    background-color: transparent;
    position: absolute;
    top: 2%;
    left: 4%;
    border: none;
    cursor: pointer;
  }

  & .headerLogoMobile {
    top: 0;
    position: relative;
    margin-top: 0;
    cursor: pointer;
  }

  & .iconActive {
    transform: scale(1.2);
  }

  & .mobileIconCountActive {
    transform: scale(1.2);
    top: 23%;
    position: absolute;
    right: 45%;
  }
}

.mobile-modal-body {
  overflow-y: auto;
  position: relative;
  background: $white;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 5px 5px;
  height: calc(100% - 53px);

  & .content {
    margin-left: 5vw;
    margin-right: 5vw;

    & select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    & .dropdown-img-ham {
      background: url('../../staticAssets/images/HamburgerDownArrow.svg') no-repeat 100% 100%;
      height: 15px;
      width: 12px;
      position: relative;
      margin-top: 34.5px;
      right: 45px;

      @media #{$medium-only} {
        margin-top: 30.5px;
        right: 30px;
      }
    }

    & .footer-links {
      background: #f8f7f5;
      width: auto;
      margin-bottom: 60px;

      //margin-top: 1%;
      & .ul-title {
        border-radius: 3px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        border-bottom: 1px solid $Light-Gray;
        padding: 5%;

        @media (max-width: 480px) {
          height: 60px;
        }
      }

      li {
        padding: 4%;
        margin-bottom: 0;
        font-family: $National2Regular;
        padding-left: 5%;
        padding-right: 5%;

        a {
          color: $Brand-Black;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    & .title-container {
      display: flex;
      //margin-top: 4vh;
      justify-content: space-between;

      & .title-text {
        height: 72px;
        left: 20px;
        top: 75px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        //text-transform: uppercase;
        color: $Brand-Black;
        margin: 0;
        padding-right: 9vw;
        margin-top: 30px;
        width: 35%;

        @media (max-width: 480px) {
          width: 55%;
          font-family: $National2Condensed;
          color: rgba(0, 0, 0, 1);
          //margin-left: 22px;
        }
      }

      & .lang-div {
        @media #{$mobile-only} {
          width: 30%;
        }

        width: 16%;

        & button {
          background: white;
          border: none;
        }
      }
    }

    & .signup-container {
      display: flex;
      height: 2vh;
      align-items: center;
      font-family: $National2Regular;
      margin-top: 20px;

      @media (max-width: 480px) {
        //margin-left: 22px;
        font-family: $National2Regular;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }

      & div {
        width: 100%;
        display: flex;
        align-items: center;
      }

      & .arrow {
        //padding-bottom: 1%;
        height: 20px;
      }

      & img,
      span {
        margin-right: 3%;
        font-weight: 500;
        color: $Brand-Black;
      }
    }

    .navItemsLeft {
      margin-top: 40px;
    }
  }
}

/* Style the category cards */
.header-card {
  padding-left: 5%;
  background: $Mashed-Potato-Gray;
  border-radius: 3px;
  margin: 3% 1%;
  height: 27vw;
  display: flex !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 10px;
    //width: 335px;
    height: 115px;
  }

  & .header-card-name {
    font-family: $National2Condensed;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 38vw;
    margin: auto 0;

    @media (max-width: 480px) {
      //margin-left: 20px;
      font-family: $National2Condensed;
      color: rgba(32, 33, 36, 1);
    }
  }

  & .header-card-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 40vw;
    height: 26vw;

    // @media (max-width: 480px) {
    // 	width: 116px;
    // 	height: 100px;
    // 	margin-right: 18px;
    // 	transform: translateY(10%);
    // }
  }
}

.navigation-link {
  text-decoration-line: none;
}

.language-select {
  border-radius: 34px;
  padding: 2% 5% 3% 33%;
  background-color: $white;
  font-family: $National2Regular;
  font-style: normal;
  color: $Brand-Black;
  border: 1px solid $Dark-Gray;
  box-sizing: border-box;
  width: 78px;
  height: 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  pointer-events: none;

  @media #{$medium-only} {
    padding: 3% 5% 3% 21%;
    width: 81px;
  }

  @media #{$breakpoint-below-tablet} {
    text-transform: uppercase;
    margin-top: 30px;
  }

  @media (max-width: 480px) {
    border-radius: 34px;
    padding: 4% 5% 3% 20%;
    background-color: #fff;
    color: $Brand-Black;
    border: 1px solid $Dark-Gray;
    box-sizing: border-box;
    width: 85px;
    height: 36px;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }
}

.headerItems {
  display: flex;
  justify-content: space-between;
  @media #{$breakpoint-below-tablet} {
    width: 95%;
    margin: auto;
  }
}

.TrackOrder {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #202124;
  margin-top: 4px;
  @media #{$breakpoint-below-iphone5} {
    margin-left: 84px;
    font-size: 19px;
  }
}

.roundedQuesmark {
  //margin-left: 20px;

  @include centeredItems();

  position: absolute;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid $Brand-Black;
  width: 1.25rem;
  height: 1.25rem;
}

.closeIcon {
  margin-left: 137px;
}

.nav-right-items-line {
  border-right: 1px solid $Light-Gray;
  margin: 5% 0;
}

$section: 'root-header';

.support-links-menu {
  padding-right: 40%;
}

// ad-banner for mobile mode
.kfc-banner-image {
  & .banner-image {
    position: relative;
    width: 100%;
  }
  & .close-button {
    display: block;
    position: absolute !important;
    margin-top: 6%;
    left: 3%;
    z-index: 99;
  }
  @media (min-width: 650px) {
    display: none;
  }
}
.headerPrice {
  margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
  line-height: 47px;
}

.language-select-cont {
  padding: 2% 5% 3% 33%;
  background-color: $white;
  font-family: $National2Regular;
  font-style: normal;
  color: $Brand-Black;
  box-sizing: border-box;
  border: 0;
  // width: 78px;
  // height: 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  // pointer-events: none;

  @media #{$medium-only} {
    padding: 3% 5% 3% 21%;
    width: 81px;
  }
  @media #{$breakpoint-below-tablet} {
    // text-transform: uppercase;
    margin-top: 30px;
  }

  @media (max-width: 480px) {
    padding: 4% 5% 3% 20%;
    background-color: #fff;
    color: $Brand-Black;
    box-sizing: border-box;
    // width: 85px;
    // height: 36px;
    cursor: pointer;
    margin-right: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }
}
@media #{$breakpoint-above-tablet} {
  .root-header.INDIA {
    width: 92%;
    padding-left: 8%;
  }
}
