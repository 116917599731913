.skeleton-loading {
  position: relative;
  width: inherit;
  height: inherit;
  background-color: inherit;
  overflow: hidden;

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    animation: 2s loading linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #ededed, transparent);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
