.brand-page-banner {
  background-color: $Mashed-Potato-Gray;
  height: 600px;
  margin-bottom: 120px;

  @media #{$breakpoint-below-tablet} {
    height: 472px;
    margin-bottom: 76px;
  }

  .strip-icon {
    margin-bottom: 100px;
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 124px;
    }
  }
  .banner-container {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $Brand-Black;

    .banner-heading-sm {
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 12px;

      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 6.00001px;
      }
    }

    .banner-heading-lg {
      font-size: 160px;
      line-height: 140px;
      letter-spacing: 8px;

      @media #{$breakpoint-below-tablet} {
        font-weight: 700;
        font-size: 80.0001px;
        line-height: 72px;
        letter-spacing: 4px;
        margin-top: 4px;
      }
    }

    .banner-short-desc {
      font-family: $National2Regular;
      font-weight: 400;
      font-size: 22px;
      line-height: 38px;
      text-transform: none;
      margin-top: 24px;

      @media #{$breakpoint-below-tablet} {
        margin-top: 20px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.5px;
        width: 185px;
      }
    }
  }

  .banner-ribbon-icon {
    margin-top: 86px;
    @media #{$breakpoint-below-tablet} {
      margin-top: 74px;
    }

    img {
      @media #{$breakpoint-below-tablet} {
        width: 70.01px;
        min-height: 122px;
      }
    }
  }
}

.para-container {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.1px;
  color: $Brand-Black;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 40px 0px;
  padding: 0 330px;
  min-height: 80px;
  align-items: center;

  @media #{$breakpoint-ipad-12-pro-portrait} {
    padding: unset;
  }
  @media #{$breakpoint-below-tablet} {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 20px;
    min-height: 150px;
    letter-spacing: 0.7px;
  }

  .para-heading {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-bottom: 40px;

    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 32px;
    }

    @media #{$breakpoint-tablet-only} {
      margin-left: -40px;
    }
  }

  .p1 {
    width: 700px;
    @media #{$breakpoint-below-tablet} {
      width: 300px;
    }
  }

  .p3 {
    margin-top: 20px;
  }
}

.block-container {
  padding: 0 232.19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0;
  @media #{$breakpoint-below-tablet} {
    margin: 0;
    padding: 0 20px;
  }

  @media #{$breakpoint-tablet-only} {
    padding: 0 240px;
  }

  .sub-container {
    display: flex;
    justify-content: center;
  }

  .two-column-section {
    display: flex;
    width: 1060px;
    justify-content: center;
    @media #{$breakpoint-below-tablet} {
      min-width: 300px;
      flex-direction: column;
    }

    .image-section {
      position: relative;
      @media #{$breakpoint-below-tablet} {
        order: 1;
      }

      .image-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 120px;
        line-height: 120px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $white;

        @media #{$breakpoint-below-tablet} {
          font-size: 72px;
          line-height: 72px;
          letter-spacing: 1px;
        }
      }

      img {
        @media #{$breakpoint-below-tablet} {
          width: 350px;
          height: 446.67px;
        }
      }
    }

    .img-order {
      @media #{$breakpoint-below-tablet} {
        order: 0 !important;
        margin-bottom: 40px;
      }
    }

    .desc-section {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media #{$breakpoint-below-tablet} {
        padding: 0 !important;
      }

      .heading-section {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 76px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        width: 380px;

        @media #{$breakpoint-below-tablet} {
          font-size: 36px;
          line-height: 36px;
          width: 335px;
        }
      }

      .short-desc-section {
        padding-right: 84px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;

        .p1 .p2 .p3 {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
        }

        @media #{$breakpoint-below-tablet} {
          padding: 0;
          margin: 32px 0 40px 0;
        }
      }

      .learn-more-container {
        display: flex;
        flex-direction: column;
        .learn-more-text {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
          margin-bottom: 25px;
        }
      }

      .mtop {
        margin-top: 30px;
      }
    }

    .position-left {
      order: 2;

      @media #{$breakpoint-below-tablet} {
        margin-left: 25px;
      }
      img {
        @media #{$breakpoint-below-tablet} {
          height: 394.94px;
          width: 296.02px;
        }
      }

      .pr {
        padding-right: 120.62px;
      }
    }

    .position-right {
      order: 2;

      @media #{$breakpoint-below-tablet} {
        margin-left: 25px;
      }
      img {
        @media #{$breakpoint-below-tablet} {
          height: 394.74px;
          width: 294.06px;
        }
      }

      .pl {
        padding-left: 120.62px;
      }
    }

    .image-right {
      order: 2;
    }

    .right-desc {
      padding-right: 120.62px;
    }

    .left-desc {
      padding-left: 120.62px;
    }
  }
}

.quotes-section {
  padding: 0 390px;
  margin: 80px 0;

  @media #{$breakpoint-below-tablet} {
    padding: 0 30px;
    flex-direction: column;
    align-items: center;
  }

  div {
    img {
      width: 8.79px;
      min-height: 24px;
    }
  }

  .quote-desc {
    margin: 0 20px;
    font-family: $National2Regular;
    font-style: italic;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: $Brand-Black;
    max-width: 610px;
    min-width: 610px;

    @media #{$breakpoint-below-tablet} {
      font-size: 18px;
      line-height: 30px;
      margin: 40px 20px;
      min-width: 295px;
      max-width: 295px;
    }
  }
}

.kfc-timeline-banner {
  background-color: $Mashed-Potato-Gray;
  min-height: 800px;

  @media #{$breakpoint-ipad-12-pro-portrait, $breakpoint-below-tablet} {
    min-height: 650px;
  }

  .banner-heading {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: 700;
    font-size: 160px;
    line-height: 140px;
    letter-spacing: 8px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-bottom: 32px;

    @media #{$breakpoint-below-tablet} {
      font-size: 80px;
      line-height: 72px;
      letter-spacing: 4px;
      margin-bottom: 24px;
    }

    @media #{$breakpoint-ipad-12-pro-portrait} {
      font-size: 107px;
      line-height: 100px;
    }
  }

  .learn-more-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    color: $Brand-Black;
    margin-bottom: 32px;

    @media #{$breakpoint-below-tablet} {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1px;
      margin-bottom: 24px;
    }
  }

  img {
    @media #{$breakpoint-ipad-12-pro-portrait} {
      width: 85%;
    }

    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }
  }
}

.card-banner {
  background: $Brand-Black;
  opacity: 0.95;
  min-height: 450px;
  padding: 80px 130px;

  @media #{$breakpoint-below-tablet} {
    min-height: 680px;
    padding: 64px 20px 40px 20px;
  }

  @media #{$breakpoint-ipad-12-pro-portrait} {
    padding: 80px 50px;
  }

  .card-row {
    display: flex;
    justify-content: center;

    @media #{$breakpoint-below-tablet} {
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-between;
    }

    @media #{$breakpoint-between-tablet-desktop} {
      justify-content: space-between;
    }

    .container-margin {
      margin-right: 100px;
    }

    .card-container {
      background: $white;
      border-radius: 4px;
      width: 540px;
      min-height: 290px;
      padding: 0 40px 40px 40px;
      display: flex;
      flex-direction: column;

      @media #{$breakpoint-below-tablet} {
        padding: 0 20px 20px 20px;
        width: 335px;
        min-height: 268px;
        margin-right: unset;
        margin-bottom: 40px;
      }

      @media #{$breakpoint-between-tablet-desktop} {
        width: 490px;
      }

      .strip-icon {
        margin-left: -14px;

        @media #{$breakpoint-below-tablet} {
          margin-left: -6px;
        }
        img {
          @media #{$breakpoint-below-tablet} {
            min-height: 20px;
            width: 48px;
            margin-top: -4px;
          }
        }
      }

      .card-heading {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 58px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin: 24px 0;

        @media #{$breakpoint-below-tablet} {
          margin: 24px 0 16px 0;
          font-size: 24px;
          line-height: 32px;
        }

        @media #{$breakpoint-ipad-12-pro-portrait} {
          font-size: 40px;
        }
      }

      .card-short-desc {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $Brand-Black;

        @media #{$breakpoint-below-tablet} {
          letter-spacing: 0.4px;
        }
      }
      .find-more-btn {
        margin-top: 2rem;
      }
    }
  }
}

.social-impact-banner {
  min-height: 700px;
  background-color: $Brand-Red;

  @media #{$breakpoint-below-tablet} {
    min-height: 560px;
  }

  .banner-container {
    padding: 93px 0px 137px 0px;

    @media #{$breakpoint-below-tablet} {
      padding: 0px 72px 56px 72px;
      margin-top: 36px;
    }

    .column-one {
      width: 437px;
      @media #{$breakpoint-below-tablet} {
        display: flex;
        margin-bottom: 34px;
        margin-right: unset;
        width: 219px;
      }

      @media #{$breakpoint-ipad-12-pro-portrait} {
        margin-right: 53px;
      }
      .banner-heading {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 160px;
        line-height: 140px;
        letter-spacing: 8px;
        text-transform: uppercase;
        text-align: center;
        color: $white;
        margin-bottom: 32px;
        @media #{$breakpoint-below-tablet} {
          font-size: 80px;
          line-height: 72px;
          letter-spacing: 4px;
        }
      }
    }

    .column-two {
      img {
        @media #{$breakpoint-below-tablet} {
          width: 211.83px;
          min-height: 234.89px;
        }
      }
    }
  }
}

.three-column-container {
  padding: 0 130px;
  margin-bottom: 80px;
  justify-content: space-evenly;
  @media #{$breakpoint-ipad-12-pro-portrait} {
    padding: unset;
    width: 100%;
    align-items: flex-start;
  }

  @media #{$breakpoint-below-tablet} {
    padding: 0 20px;
    margin-bottom: 45px;
  }

  @media #{$breakpoint-tablet-only} {
    align-items: center;
    width: 94%;
  }

  .coulm-container {
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 24px;
    }
  }

  .column-desc {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    width: 300px;
  }

  .column-heading {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 43px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-bottom: 24px;
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.local-theme-banner {
  padding: 0 130px;
  margin: 80px 0;
  display: flex;
  justify-content: center;
  @media #{$breakpoint-below-tablet} {
    margin-top: 0;
    margin-bottom: 64;
    padding: 0 20px;
  }

  .banner-container {
    background-color: $Mashed-Potato-Gray;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 1180px;
    min-height: 644px;

    @media #{$breakpoint-below-tablet} {
      width: 350px;
      min-height: 760px;
      align-items: flex-start;
    }

    .column-container {
      display: flex;
      margin-top: 80px;
      padding: 0 100px 0 115px;

      @media #{$breakpoint-below-tablet} {
        padding: 0;
        margin-top: 24px;
      }

      .line {
        margin: 40px 0;
        border: 1px solid $Brand-Black;
        opacity: 0.2;

        @media #{$breakpoint-below-tablet} {
          margin: 24px 0;
        }
      }

      .column-one {
        align-content: center;
        min-width: 400px;

        @media #{$breakpoint-ipad-pro-tablet} {
          min-width: 430px;
        }

        @media #{$breakpoint-below-tablet} {
          padding: 0 20px;
          width: 100%;
          min-width: 300px;
        }

        .find-more-btn {
          margin-bottom: 24px;
        }

        .banner-heading {
          cursor: pointer;
          color: $Brand-Black;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 64px;
          line-height: 76px;
          text-align: center;
          letter-spacing: 1px;
          text-transform: uppercase;

          @media #{$breakpoint-below-tablet} {
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 24px;
            text-align: left;
          }
        }

        .inactive {
          opacity: 0.8;
          color: $Gray;
          @media #{$breakpoint-below-tablet} {
            margin: 0;
          }
        }
      }

      .column-one,
      .column-two {
        display: flex;
        flex-direction: column;
      }

      .column-two {
        width: 480px;
        margin-left: 120px;
      }

      .banner-desc {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.4px;
        color: $Brand-Black;
        margin-bottom: 30px;
      }

      .local-theme-btn {
        padding: 11px 55px;
      }
    }
  }
}

.column-two img,
.two-column-section img {
  width: 100%;
}
