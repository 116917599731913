.deals-offers-container {
  width: 68%;
  margin: auto;

  @media #{$breakpoint-below-tablet} {
    width: 82.6%;
  }
}

.deals-header-image {
  width: 100%;
  height: 280px;
  background-color: $Brand-Black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  @media #{$breakpoint-below-tablet} {
    height: 205px;
  }

  h2.deals-image-text {
    font-family: $National2Condensed;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 74px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: auto;

    @media #{$breakpoint-below-tablet} {
      font-size: 42px;
      line-height: 46px;
      letter-spacing: 1px;
    }
  }
}
