.meal-list-item {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -8px;
  margin-bottom: 8px;
  img {
    height: 24px;
  }
}
input[type='checkbox']:checked {
  background-color: #4d4d50 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='checkbox']:after {
  content: '✓';
  color: #ffff;
  font-weight: 900;
  font-size: 0.6em;
  display: none;
  border-radius: 0 !important;
}

input[type='checkbox']:checked:after {
  display: block;
}

input[type='checkbox'] {
  cursor: pointer;
  outline: none;
  border: 1px solid #4d4d50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background-color: #f8f7f5;
}
.meal-item-checkbox .checked {
  background-color: $Brand-Black;
  border-radius: 200px;
  color: #4d4d50;
}
.meal-item-text {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $Brand-Black;
  letter-spacing: 0.5px;
  height: 15px;
  cursor: pointer;
}
.meal-item-checkbox:checked + .meal-item-text {
  font-family: $National2Medium;
  font-weight: 500;
}

.meal-description {
  margin-bottom: 24px;
  padding-top: 8px;
  // padding-left:18px;
  color: $gray-lightbg;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
}

@media #{$breakpoint-below-tablet} {
  .meal-list-card {
    margin-top: 15px;
    background: $Mashed-Potato-Gray;
    margin-left: 120px;
    margin-right: 120px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 32px 16px 40px 16px;
    .preferences-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-left: 104px;
    }
    .preferences-card-right-side {
      padding: 24px 87px;
    }
  }
}

@media #{$mobile-tablet-only
} {
  .meal-description {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
  }
  .meal-list-card {
    margin-top: 8px;
    background: $Mashed-Potato-Gray;
    margin-left: 0px;
    margin-right: 0px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 24px;
    .preferences-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-left: 0px;
    }
    .preferences-card-right-side {
      padding: 24px 0px;
    }
  }
}
