@mixin quantityHoverStates {
  color: $white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
}
.plp-container {
  //padding: 0 7vw;
  position: sticky;

  @media #{$breakpoint-below-tablet} {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
}

.plp-au-container {
  position: relative;
  &.grid-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.plp-stripes-section {
  width: 100%;
  //position: fixed;
  top: 0;
  background-color: transparent;
  z-index: 111;
  margin-bottom: 6.5%;
  &.plp-stripes-section-in {
    .stripes-img {
      height: 2vw;
      width: 4vw;
      margin-left: 1vw;
    }
  }
}

.plp-menu-row {
  margin-right: 0 !important;
  flex-wrap: nowrap !important;
  & .plp-left-nav {
    height: fit-content;
    top: 170px;

    & .kfc-menu-title {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 1px;
      text-transform: $uppercase;
      margin-bottom: 37px;
      color: $black;
      &.kfc-menu-title-in {
        margin-bottom: 0;
        padding-bottom: 1vw;
      }
    }
    & .outer-ul {
      padding-left: 0;
      list-style: none;
      justify-content: 'space-around';

      & li:first-child {
        padding: 0.225rem 0 1.19rem 0;
      }

      & li {
        padding: 0 0 1.19rem 0;
        outline: none !important;

        & a {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        & a.active {
          font-family: $National2Medium;
          font-weight: 500;
          letter-spacing: -0.1px;
          background-color: transparent;
          color: $light-black-color;
          text-decoration: none;
          // font-size: 1.125rem;
        }

        & a.active::after {
          display: none;
        }
      }

      & .inner-ul {
        list-style: none !important;
        justify-content: 'space-around';
        padding-left: 0;

        &.nested &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          li:last-child {
            padding: 0;
          }
        }

        &li {
          // display: none;
          padding-bottom: 0;

          & a {
            margin-left: 26px;
          }
        }

        // .nested {
        // 	display: none;
        // }
        // .caret::before {
        //   content: '\25B6';
        //   color: black;
        //   display: inline-block;
        //   margin-right: 6px;
        // }

        .caret-down::before {
          content: url('../../staticAssets/images/tree-minus.svg');
          display: inline-block;
          margin-right: 6px;
        }

        &.active-tree {
          display: block;
        }

        .active-tree {
          display: block;
        }

        & li {
          & a {
            font-size: 14px;

            &:hover {
              text-decoration: none;
            }
          }

          a.caret {
            &:hover {
              text-decoration: none;
            }
          }

          & a.active {
            font-weight: bold;
            margin-left: 0;
          }

          & a.active::after {
            display: none;
          }

          & a.active::before {
            content: '\2212';
            color: red;
            font-size: 25px;
            padding-right: 10px;
            vertical-align: middle;
          }
        }
      }
    }

    .clearAllFilterButton {
      width: fit-content;
      border-radius: 200px;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8%;
      background: $Brand-Black;
      color: $white;
      outline: none;
      border: 1px solid $Light-Gray;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }

    .selectedFilterButtons {
      width: max-content;
      margin: 10px 0;
      border-radius: 200px;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;
      outline: none;
      border: 1px solid $Light-Gray;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }

    .textFiltered {
      text-transform: capitalize;
      width: fit-content;
      max-height: 22px;
    }

    .mlcross {
      margin-inline-start: 10px;
      cursor: pointer;
    }
  }
  & .promo-section-container {
    //height: 510px;
    margin-bottom: 0;
  }

  & .plp-promo-section {
    padding-left: 0;
    padding-right: 0;
  }
  & .margin-top-catering-plp {
    margin-top: 15px;
  }
  & .plp-right-nav {
    margin-left: 0;
    &.plp-right-nav-in {
      margin-top: 6.3%;
      .plp-sections {
        margin-top: 7%;
      }
    }
    //   overflow-x: hidden;
    //padding-left: 33px;
    @media #{$breakpoint-between-tablet-desktop} {
      //padding-left: 15px;
    }
    @media #{$breakpoint-below-and-above-medium-desktop} {
      padding-right: 0px;
      //padding-left: 15px;
    }
    & .plp-filters {
      //position: fixed;
      background-color: $white;
      //z-index: 111;
      display: flex;
      justify-content: space-between;
      //width: 65.3%;
      width: 96%;
      border-bottom: 2px solid $Brand-Black;
      padding-bottom: 15px;
      padding-left: 0;
      padding-right: 0;

      @media #{$breakpoint-below-and-above-medium-desktop} {
        width: 880px;
      }
      .inputAutoCompletePLP {
        position: absolute;
        margin-top: 16px;
        width: 280px;
        height: max-content;
        background: $Brand-Black;
        color: $white;
        z-index: 999;
      }

      .autoCompleteListPLP {
        list-style: none;
        color: $white;
        padding: 0;
        margin: 0;
        cursor: pointer;
        padding-top: 4%;
        // width: 46px;
      }

      .wrapperAutoCompletePLP {
        width: 100%;
        position: relative;
        padding: 2% 0 2% 0;
        // background-color: black;
        display: flex;
        align-items: center;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding-inline-start: 20px;
        padding-inline-end: 10px;
      }

      & .search-menu {
        & ::placeholder {
          /* Firefox */
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          color: $Brand-Black;
          text-align: left;
          font-size: 12px;
          line-height: 20px;
        }

        & :-ms-input-placeholder {
          /* Internet Explorer 10-11 & Edge */
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          color: $Brand-Black;
        }

        & .search-menu-input {
          border-radius: 34px;
          border: 1px solid $Light-Gray;
          height: 36px;
          width: 201px;
          text-align: left;
          margin-bottom: 1%;
          padding: 9px 65px 9px 40px;
          line-height: 20px;
          font-weight: 500;
          font-size: 12px;
          color: $Brand-Black;
          font-family: $National2Medium;
          vertical-align: middle;
        }

        & .search-icon {
          position: absolute;
          left: 13px;
          top: 5px;
          //right: 14vw;
        }

        & .searchgrey-icon-img {
          position: relative;
          bottom: 2px;
          padding: 2px 10px;
          padding-left: 0;
          opacity: 0.4;
        }

        & .input-dark-cross {
          position: relative;
          right: 28px;
          bottom: 2px;
          cursor: pointer;
        }
      }

      & .right-filters {
        .right-filters-container {
          display: flex;
          justify-content: space-between;
        }

        .filter-buttons {
          color: $Brand-Black;
          width: 7.5em;
          border-radius: 200px;
          height: 3em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 4%;
          // outline: none;
          border: 1px solid $Light-Gray;
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          cursor: pointer;
          outline: none;
        }

        .textFiltered {
          width: fit-content;
          max-height: 22px;
          text-transform: capitalize;
        }

        .mlcross {
          margin-inline-start: 10px;
          cursor: pointer;
        }

        .caretTextFilter {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          width: 18px;
          height: 18px;
          background: $white;
          color: $Brand-Black;
          border-radius: 100%;
        }

        .caretTextFilter-dark {
          border: 1px solid $Brand-Black;
        }

        .filter-buttons-dark {
          background: $Brand-Black;
          color: $white;
          width: 8.2em;
        }

        .m10 {
          margin-left: 10px;
        }

        .filterPlate {
          position: absolute;
          margin-top: 20px;
          margin-left: -30px;
          width: 328px;
          height: max-content;
          background: $Brand-Black;
          color: $white;
          padding: 20px 0;
          z-index: 111;
        }

        .filterUList {
          list-style: none;
          color: $white;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }

        .wrapper {
          width: 100%;
          position: relative;
          padding: 2% 0 2% 0;
          background-color: $Brand-Black;
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          border: 0;
          outline: 0;

          label {
            cursor: pointer;
            margin-bottom: 0;
          }
        }

        input[type='checkbox'] {
          margin: 0 4% 0 5%;
          appearance: none;
          height: 16px;
          width: 16px;
          background-color: transparent;
          border-radius: none;
          cursor: pointer;
          outline: none;
          border: 1px solid $Light-Gray;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input[type='checkbox']::after {
          content: '✓';
          color: black;
          font-weight: 900;
          font-size: 1em;
          display: none;
          margin-top: 2px;
        }

        input[type='checkbox']:checked {
          background-color: $white;
        }

        input[type='checkbox']:checked::after {
          display: block;
        }

        input[type='radio'] {
          margin: 0 4% 0 5%;
          appearance: none;
          height: 16px;
          width: 16px;
          background-color: transparent;
          border-radius: 100%;
          cursor: pointer;
          outline: none;
          border: 2px solid $Light-Gray;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input[type='radio']::after {
          content: '•';
          color: $white;
          font-weight: 900;
          font-size: 1.5em;
          display: none;
          margin: 0.5px 0.5px 0 0;
        }

        input[type='radio']:checked {
          background-color: inherit;
        }

        input[type='radio']:checked::after {
          display: block;
        }
      }
    }

    & .plp-sections {
      margin-top: 0;
    }

    & .found-text {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
      position: absolute;
      margin-left: 15px;
    }
    .info-content {
      // height: 140px;
      margin-top: 21px;
      margin-bottom: 80px;
      width: 66%;
      left: 430px;
      // top: 2712px;
      font-family: $National2Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      text-align: left;
      color: #494949;
    }
    .info-content > div {
      margin-bottom: 10px;
      color: #494949;
    }
    .percent-daily-text {
      font-size: 12px;
      line-height: 22px;
      font-family: $National2Regular;
    }
  }
}

//mobile plp scss
.mob-nav-row {
  flex-wrap: nowrap !important;
  margin-left: 0 !important;
  padding-left: 10px;
  padding-top: 5px;
  border-bottom: 1px solid #dbdbdb;
  overflow-y: hidden;
  z-index: 9998;
  width: 100%;
  background: #fff;
  margin-top: 0;
  margin-bottom: 5px;

  @media #{$mobile-only} {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  & .mob-search-input {
    width: 44px;
    height: 34px;
    border-radius: 34px;
    outline: none;
    border: 1px solid $Light-Gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 25px;
  }

  & .mob-search-img {
    width: 24px;
    height: 18px;
  }

  .mob-outer-ul {
    padding-left: 0;
    display: flex;
    margin-top: 6px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0;
    margin-bottom: 0;
    //padding-bottom: 15px;
    //height: 50px;

    & li {
      display: flex;
      margin-left: 20px;
      height: 33px;

      & a {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        opacity: 0.5;
        display: inline-flex;
        width: max-content;
      }

      & a.active {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: normal;
        background-color: transparent;
        color: $Brand-Black;
        text-decoration: none;
        border-bottom: 2px solid $Brand-Black;
        opacity: 1;
      }

      & a.active::after {
        display: none;
      }
    }
  }
  .mob-outer-ul::-webkit-scrollbar {
    display: none;
  }
}

.mob-nav-row::-webkit-scrollbar {
  height: 0 !important;
}

.sidenav-filter {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 104vh;
  width: 0;
  position: fixed;
  z-index: $z-index-max + 1;
  background: $Brand-Black;
  color: $white;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.1s;

  .refineItemsPartMobPLP {
    width: 100%;
    padding: 8px 0;
    margin-bottom: 20px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $Brand-Black;
  }

  .refineItemsText {
    //margin-right: 27%;
    text-align: center;
    width: 100%;
    margin-left: 7%;
  }

  .crossCloseIconFilterMobPlp {
    margin-right: 5%;
  }

  .searchInputMobMenuPlp {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    .autoCompleteListPLP {
      list-style: none;
      color: $white;
      padding-top: 12px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin: 0;
      cursor: pointer;
      width: auto;
    }

    .wrapperAutoCompletePLP {
      width: 100%;
      position: relative;
      padding: 2% 0 0 0;
      display: flex;
      align-items: center;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding-inline-start: 1%;
      background-color: unset;
    }

    .searchgrey-icon-img {
      position: relative;
      bottom: 2px;
      padding: 2px 10px;
      opacity: 0.4;
    }

    .plp-search-container {
      position: relative;
      height: 42px;

      .search-menu-inputMob {
        border-radius: 34px;
        border: 1px solid $Dark-Gray;
        // padding-left: 48px; Sonar Issue
        padding: 8px 38px;
        width: 90vw;
        margin: auto;
        background-color: $Dark-Gray;
        color: $white;
      }

      .search-iconMob {
        position: relative;
        bottom: 33px;
        left: 13px;
        width: 0;
      }

      .input-dark-cross-mob {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  .filterHeadingTextMob {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16.1485px;
    letter-spacing: 2.58074px;
    color: #fff;
  }

  .horizontalLineBorder {
    height: 1px;
    border-bottom: 1px solid $Inactive-Gray;
    margin: 20px 0;
    opacity: 0.5;
  }

  .filterByPartMob {
    margin-top: 47px;
    width: 90vw;
    // height: 40vh;
  }

  .applyFilterButtonMob {
    margin: 30px 0;
    width: 90vw;
    padding: 16px 0;
    background: $white;
    color: $Brand-Black;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 66.2px;
  }

  .filterPlate {
    width: 90vw;
    height: max-content;
    color: $white;
  }

  .filterUList {
    list-style: none;
    color: $white;
    padding: 0;
    margin: 0;
  }

  .wrapper {
    width: 90vw;
    position: relative;
    padding: 2% 0 2% 0;
    // background-color: black;
    display: flex;
    align-items: center;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    outline: none;

    label {
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  input[type='checkbox'] {
    margin: 0 4% 0 0%;
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: none;
    cursor: pointer;
    outline: none;
    border: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='checkbox']::after {
    content: '✓';
    color: black;
    font-weight: 900;
    font-size: 1em;
    display: none;
    margin-top: 2px;
  }

  input[type='checkbox']:checked {
    background-color: $white;
  }

  input[type='checkbox']:checked::after {
    display: block;
  }

  input[type='radio'] {
    margin: 0 4% 0 0%;
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='radio']::after {
    content: '•';
    color: $white;
    font-weight: 900;
    font-size: 1em;
    display: none;
    margin-top: 2px;
  }

  input[type='radio']:checked::after {
    display: block;
  }
}

.sidenav-filter .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.totalFilterAppliedCount {
  position: absolute;
  // top: 1.5%;
  left: 11%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background: $Dark-Gray;
  color: $white;
  border: 1.5px solid #fff;
  border-radius: 100%;

  @media (orientation: landscape) {
    left: 5%;
  }
}

.clearAllFilterButtonMob {
  margin-bottom: 5px;
  border-radius: 200px;
  height: 34px;
  padding: 8px 15px;
  background: $Brand-Black;
  color: $white;
  outline: none;
  border: 1px solid $Light-Gray;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

  @media #{$mobile-only} {
    width: max-content;
  }
}
.search-box {
  padding-top: 20%;
  padding-left: 10%;
}
.found-text {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #202124;
  position: absolute;
}

.filterListRowMobView {
  margin-left: 10px;
  display: flex;
  flex-direction: row;

  .selectedFilterButtonsMob {
    width: max-content;
    margin-left: 10px;
    // margin: 10px 0px;
    border-radius: 200px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 0 5%;
    outline: none;
    border: 1px solid $Light-Gray;
  }

  .textFilteredMob {
    width: fit-content;
    margin-left: 10px;
    font-family: $National2Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }

  .mlcrossMob {
    margin-inline-start: 6px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.horizontalLineBorder-noData {
  @media #{$breakpoint-above-tablet} {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $Inactive-Gray;
    margin: 20px 0;
  }
}
.horizontalLineBorder-noData-forMobile {
  @media #{$breakpoint-below-tablet} {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $Inactive-Gray;
    margin: 20px 0;
  }
  // Commented out incoming changes from release-oct
  // width: 100%;
  // height: 1px;
  // border-bottom: 1px solid $Inactive-Gray;
  // margin: 20px 0;
}

.no-mob-search-found-plp {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  color: black;
  margin-left: 21px;
  .no-search-found-text-highlighted {
    color: $Red-Orange;
  }

  .back-to-menu-plpsearch {
    width: fit-content;
    border-radius: 200px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%;
    background: $Brand-Black;
    color: $white;
    outline: none;
    border: 1px solid $Light-Gray;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 50px;
  }
}

.no-search-found-plp {
  width: 40vw;
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 58px;
  text-transform: uppercase;
  color: black;

  .no-search-found-text-highlighted {
    color: $Red-Orange;
  }

  .back-to-menu-plpsearch {
    width: fit-content;
    border-radius: 200px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%;
    background: $Brand-Black;
    color: $white;
    outline: none;
    border: 1px solid $Light-Gray;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 50px;
  }
}

.recommended-menu-container {
  .product-container::-webkit-scrollbar {
    height: 4px;
  }

  .product-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgb(0, 0, 0);
  }

  .product-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    background: rgb(255, 255, 255);
    margin-left: 15px;
  }
}

.recommended-menu-container-sopac {
  .medium-menu-product-card {
    height: auto !important;
    .medium-menu-product-image {
      height: auto !important;
    }
  }
}

.no-data-found-plp {
  height: 50vh;
}

.favorite-items-container {
  .quantityAddtocart {
    @media #{$mobile-only} {
      position: absolute;
      bottom: 0;
      margin-left: 0;
    }

    & .cart-food-quantity-change {
      bottom: 26px;

      @media #{$mobile-only} {
        margin-left: 10px;
      }
    }

    & .cardButton {
      bottom: 0;
    }
  }
}

.quantityAddtocart {
  /* position: absolute;
	  bottom: 3vh; */

  /* top: 13vh; */
  height: 75px;
  align-items: center;
  display: flex;
  margin: 0 13px;
  justify-content: space-between;
  // @media screen and (max-width: 534px) {
  // 	margin: 0px 6px;
  // }

  & .cart-food-quantity-change {
    margin-top: 10px;
    //position: absolute;
    //right: 66%;
    display: inline-flex;
    align-items: center;
    position: absolute;
    bottom: 22px;

    & .quantity-decrease {
      width: 30px;
      height: 30px;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      border: 1px solid $Brand-Red;
      background-color: $Brand-Red;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      background-image: url('../../staticAssets/images/cart-minus-active-white.svg');

      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .quantity-decrease,
        .quantity-increase {
          line-height: 26px;
        }
      }

      @-moz-document url-prefix() {
        span {
          right: 0.5px;
          position: relative;
        }
      }
      @media #{$breakpoint-above-tablet} {
        &:hover {
          @include quantityHoverStates();
          background-color: $black;
          border-color: $Brand-Black;
          background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
        }
      }
      &:active {
        @include quantityHoverStates();
        background-color: $Brand-Red;
        border-color: $Brand-Red;
        background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
      }
      @media #{$breakpoint-below-tablet} {
        &:active {
          opacity: 0.25;
          transition: opacity 1.5 ease;
        }
      }

      &:disabled {
        background-color: transparent;
        color: $Inactive-Gray;
        border-color: $Inactive-Gray;
        cursor: not-allowed;
        background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    & .quantity-increase {
      width: 30px;
      height: 30px;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      border: 1px solid $Brand-Red;
      background-color: $Brand-Red;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      background-image: url('../../../styles/staticAssets/images/cart-plus-white.svg');

      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .quantity-decrease,
        .quantity-increase {
          line-height: 26px;
        }
      }

      @-moz-document url-prefix() {
        span {
          right: 0.5px;
          position: relative;
        }
      }
      @media #{$breakpoint-above-tablet} {
        &:hover {
          @include quantityHoverStates();
          background-color: $black;
          border-color: $Brand-Black;
          background-image: url('../../../styles/staticAssets/images/cart-plus-white.svg');
        }
      }
      &:active {
        @include quantityHoverStates();
        background-color: $Brand-Red;
        border-color: $Brand-Red;
        background-image: url('../../../styles/staticAssets/images/cart-plus-white.svg');
      }
      @media #{$breakpoint-below-tablet} {
        &:active {
          opacity: 0.25;
          transition: opacity 1.5 ease;
        }
      }

      &:disabled {
        background-color: transparent;
        color: $Inactive-Gray;
        cursor: not-allowed;
        background-image: url('../../staticAssets/images/cart-plus-disabled.svg');
        border: 1px solid $Inactive-Gray;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .cateringInputChangedZero {
      border: 1px solid #202124;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      background-image: url('../../staticAssets/images/cart-plus.svg');
      color: #dbdbdb;
    }
    & .cart-food-quantity {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $Brand-Black;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  & .cart-food-price {
    margin-left: 55px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: $Brand-Black;
    display: flex;
    flex-direction: column;

    & .modified-price {
      color: $Red-Orange;
    }

    & .old-price {
      text-decoration: line-through;
    }
  }

  & .cardButton {
    position: absolute;
    padding: 0;
    right: 10px;
    bottom: 15px;

    button {
      padding: 11px 20px;
      width: 100% !important;
    }

    .btn-black {
      padding: 11px 20px;
      width: 100% !important;
      background: #202124;
      color: #fff;
      border: none;
    }
  }

  & .cardButtonOnly {
    position: absolute;
    bottom: 15px;
    padding: 0;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 342px) and (max-width: 1022px) {
    & .cardButtonOnly.loggedIN {
      width: 90%;
    }

    & .cardButton.loggedIN {
      //right: 24%;
      width: 40%;
    }
  }

  @media screen and (max-width: 342px) {
    & .cardButtonOnly.loggedIN {
      width: 83%;
      left: -2%;
    }

    & .cardButton.loggedIN {
      // right: 23%;
      width: 40%;
    }
  }
}

.cardwithQuantity {
  width: 98%;
}

.cardwithNoquantity {
  width: 100%;
}

.data-found-plp {
  // height: 17vh;

  @media #{$breakpoint-below-tablet} {
    height: 5vh;
  }
}

.all-data-found-plp {
  //height: 17vh;

  @media #{$breakpoint-below-tablet} {
    height: 5vh;
  }
}

.copied-toast-position {
  max-width: 350px;
}

.success-copied-toast {
  width: max-content;
  min-width: 278px;
  max-width: 350px;
  margin: auto 0 !important;
  padding: 6px !important;

  & .img-text-div {
    text-align: $center;

    @media #{$mobile-only} {
      margin-right: 4%;
    }
  }

  & .text {
    font-family: $National2Medium !important;
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
    color: #fff !important;
    font-style: normal !important;
    margin-left: 0 !important;
  }
}

.SuggestionHeader {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  padding-top: 24px;
  padding-left: 20px;
}
.CustomizedModal {
  & .modal-content {
    position: absolute;
    width: 40%;
    left: 30%;
    //   overflow-y: scroll;
    //   overflow-x: hidden;
    top: 15%;
    height: auto;
    max-height: 80%;
    border-radius: 0.6rem;
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 64%;
      left: 0;
      top: 37%;
      border-radius: 0.8rem;
    }
    & .modal-header {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 100%;
      padding-top: 60px;
      padding-bottom: 30px;
      color: #202124;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 22px;
        padding-top: 46px;
        padding-bottom: 30px;
      }
      .custom-modal-close {
        top: 4%;
      }
    }

    & .header-title {
      padding-left: 20px;
      font-size: 24px;
      font-family: $National2Condensed;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 22px;
      }
    }

    & .modal-body {
      padding-right: 0px;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: #fff;
      @media #{$breakpoint-below-tablet} {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
}
.LineSeparator {
  background: #dbdbdb;
  position: absolute;
  height: 1px;
  left: 0%;
  right: -0.13%;
}
.ButtonAndPopupLink {
  display: flex;
  justify-content: center;
  padding-bottom: 53px;
  @media #{$breakpoint-below-tablet} {
    padding-bottom: 60px;
  }
}
.AddNew {
  display: flex;
  justify-content: center;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  // text-decoration-thickness: 2.9px;
  text-decoration-color: #494949;
  color: #202124;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.addNewItem {
  cursor: pointer;
}
.EditSelection {
  text-decoration-line: underline;
  color: #202124;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.FinalDivButtons {
  position: sticky;
  bottom: -1px;
  background: $white;
  margin-top: 3px;
}
.FinalButton {
  position: sticky;
  bottom: -1px;
  background: $white;
  margin-top: 10px;
  @media #{$breakpoint-below-tablet} {
    margin-top: 57px;
  }
}

.editSelectionsItem {
  position: sticky;
  background: #fff;
  bottom: -1px;
  height: 112px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
}
.add-new-product {
  margin-top: 30px;
}
.CustomizeOptionModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$breakpoint-below-tablet} {
    padding-top: 22px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .DisplayNameFont {
    font-family: $National2Condensed;
    font-style: normal;
    //   font-weight: bold;
    font-size: 26px;
    // line-height: 130%;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 7px;
    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      margin-bottom: 11px;
    }
  }
  .customizableProducts {
    width: 220px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    letter-spacing: -0.1px;
    color: #494949;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
  .customizableProducts-Default {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    letter-spacing: -0.1px;
    color: #494949;
    @media #{$breakpoint-below-tablet} {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }

  & .quantity-decrease {
    width: 30px;
    height: 30px;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    border: 1px solid $Brand-Black;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-image: url('../../staticAssets/images/cart-minus-active.svg');

    &:hover,
    &:active {
      background-color: $black;
      color: $white;
      border-color: $Brand-Black;
      cursor: pointer;
      outline: none;
      background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:disabled {
      background-color: transparent;
      color: $Inactive-Gray;
      border-color: $Inactive-Gray;
      cursor: not-allowed;
      background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .PlusAndMinus {
    margin-top: 10px;
    & .cart-food-quantity-change {
      //margin-top: 10px;
      //position: absolute;
      display: inline-flex;
      align-items: center;

      & .cart-food-quantity {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $Brand-Black;
        margin-left: 12px;
        margin-right: 12px;
      }

      & .quantity-decrease {
        width: 30px;
        height: 30px;
        justify-content: center;
        padding: 0;
        border-radius: 50%;
        border: 1px solid $Brand-Black;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        background-image: url('../../staticAssets/images/cart-minus-active.svg');

        &:hover,
        &:active {
          background-color: $black;
          color: $white;
          border-color: $Brand-Black;
          cursor: pointer;
          outline: none;
          background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        &:disabled {
          background-color: transparent;
          color: $Inactive-Gray;
          border-color: $Inactive-Gray;
          cursor: not-allowed;
          background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      & .quantity-increase {
        width: 30px;
        height: 30px;
        justify-content: center;
        padding: 0;
        border-radius: 50%;
        border: 1px solid $Brand-Black;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        background-image: url('../../staticAssets/images/cart-plus.svg');

        &:hover,
        &:active {
          background-color: $black;
          color: $white;
          border-color: $Brand-Black;
          cursor: pointer;
          outline: none;
          background-image: url('../../staticAssets/images/cart-plus-white.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        &:disabled {
          background-color: transparent;
          color: $Inactive-Gray;
          border-color: $Inactive-Gray;
          cursor: not-allowed;
        }
      }
    }
  }
}
.EditItemsCard {
  cursor: pointer;
}
.TextInButton {
  margin-right: 10px;
}
.time-slot-header-content {
  text-align: center;
  align-content: center;
  margin-bottom: 15px;
}
.time-slot-header-text {
  font-family: $National2Condensed;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.address-content {
  padding: 24px 16px 24px 16px;
  background-color: #f8f7f5;
  border-radius: 8px;
}
.time-slot-unavailable-modal {
  .modal-content {
    width: 50%;
    height: 80%;
    left: 25%;
  }
  .modal-body {
    background: #ffff !important;
  }
}
.find-kfc-date-section {
  display: flex;
  justify-content: center;
  text-align: center !important;
  margin-top: 30px;
}
.address-content-header {
  font-family: $National2Medium;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
}
.address-content-text {
  font-family: $National2Regular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  width: 50%;
}
.address-content-link {
  margin-left: 3px;
  text-decoration: underline;
  color: #000000;
  &:hover {
    text-decoration: underline;
    color: #000000;
  }
}
