.communication-preferences-item {
  margin-bottom: 16px;
  .preferences-item-container {
    position: relative;
  }
  .switch-button-container {
    position: absolute;
    right: 0;
    top: 0px;
    font-family: $National2Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.communication-preferences-heading-alignment {
  margin-left: 16px;
  margin-bottom: 40px;
}
.divider:last-child {
  display: none;
}
.divider {
  margin-top: 0px;
  color: $gray-lighter;
}
.preferences-item-category {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.1px;
}
.preferences-item-title {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
  margin-right: 104px;
}
.preferences-sub-title {
  color: $gray-lightbg;
  font-family: $National2Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.1px;
  width: 279px;
}
.preferences-sub-title label {
  margin-bottom: 0px;
}
.preferences-strips-icon {
  display: none;
}

@media #{$breakpoint-below-tablet} {
  .preferences-strips-icon {
    display: block;
    width: 39px;
  }
  .communication-preferences-heading-alignment {
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 0px 16px 24px 16px;
  }
  .preferences-card {
    margin-top: 5px;
    background: $Mashed-Potato-Gray;
    margin-left: 120px;
    margin-right: 120px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    .preferences-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 24px;
      margin-left: 120px;
    }
  }
  .preferences-card-right-side {
    padding: 24px 120px;
    .preferences-container {
      margin-top: 5px;
    }
  }

  .preferences-sub-title {
    width: 200px;
  }
}

@media #{$mobile-tablet-only
} {
  .preferences-strips-icon {
    display: block;
    width: 39px;
  }
  .communication-preferences-heading-alignment {
    margin-left: 0px;
    margin-bottom: 0px;
    p {
      margin-bottom: 0px;
    }
  }
  .preferences-card {
    background: $Mashed-Potato-Gray;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;

    .preferences-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 32px;
      margin-left: 16px;
    }
  }
  .preferences-card-right-side {
    padding: 24px 16px 40px 16px;
  }
}
