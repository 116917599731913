.app-update {
  .rows {
    min-height: 50vh;
  }

  .update-banner {
    height: 50vh;

    img {
      width: 125px;
    }
  }

  .update-action {
    img {
      width: 44px;
    }
  }

  .update-txt {
    font-family: $National2Condensed;
    font-weight: $font-weight-bold;
    font-size: 24px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;

    span:nth-child(odd) {
      color: $Brand-Red;
    }

    span:nth-child(even) {
      color: $black;
    }
  }

  .blackButton {
    width: 80vw;
    max-width: 90vw;
  }
}
