.cart-image {
  cursor: pointer;
  margin-top: -1px;
  margin-left: 10px;

  @media #{$breakpoint-below-tablet} {
    height: 45px;
    width: 60px;
    margin: -1px 10px 4px 0;
  }

  @media #{$mobile-only} {
    margin: -1px 12px 0 4px;
  }

  .header-item-buttons {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;

    @media #{$breakpoint-below-tablet} {
      margin: 0 13px 0 3px;
    }

    @media #{$mobile-only} {
      margin: 6px 0 0 2px;
    }

    img {
      height: 42px;
      width: 42px;
      vertical-align: middle;
      margin-left: 9px;

      @media #{$breakpoint-below-tablet} {
        width: 40px;
        height: 40px;
      }

      @media #{$mobile-only} {
        width: 38px;
        height: 38px;
      }
    }

    .cart-icon-count {
      position: relative;
      top: -28px;
      display: flex;
      font-family: $National2Condensed;
      font-style: $font-weight-normal;
      font-weight: $font-weight-bold;
      font-size: 12px;
      line-height: 16px;
      color: $Brand-Black;
      left: 4px;

      &.cart-icon-plus {
        top: -33px;

        .cart-plus {
          vertical-align: super;
        }

        @media #{$breakpoint-above-desktop} {
          left: 5px;
        }
      }

      @media #{$mobile-only} {
        top: -26px !important;
        left: 18px !important;
      }

      @media #{$breakpoint-below-tablet} {
        top: -27px;
        left: 19px;
        width: 20px;
        font-size: 10px;

        &.cart-icon-plus {
          top: -30px;
          left: 20px;
        }
      }
    }

    &.icon-active {
      img {
        transform: scale(1.4);
      }

      .cart-icon-count-active {
        position: relative;
        margin-left: -28px;
        font-family: $National2Condensed;
        font-style: $font-weight-normal;
        font-weight: $font-weight-bold;
        font-size: 14px;
        color: $Brand-Black;

        @media #{$breakpoint-below-tablet} {
          margin-left: -24px;
          top: 3px;
          font-size: 12px;
        }

        &.cart-icon-plus {
          margin-left: -28px;
          bottom: -4px;

          @media #{$breakpoint-below-tablet} {
            top: -31px;
            margin-left: 15px;
            font-size: 12px;
          }

          @media #{$breakpoint-above-desktop} {
            left: 1px;
          }
        }

        .cart-plus {
          vertical-align: super;
        }

        @media #{$breakpoint-above-desktop} {
          top: 5px;
          right: 5px;
        }
      }
    }
  }
}

.shopping-cart {
  display: flex;
  justify-content: flex-end;

  .basket {
    width: 50px;
    height: 50px;

    //background-size: 285%;
    display: block;
  }

  a {
    text-align: center;
    line-height: 55px;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #202124;
    text-decoration: none;
  }

  .basket.blank {
    //background-position: -3px -1px;
  }

  .lines {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    width: 34px;

    span {
      height: 2px;
      width: 0;
      background: #000;
      -ms-transform: rotate(-120deg);
      transform: rotate(-120deg);
      -ms-transform-origin: top left;
      transform-origin: top left;
    }

    span.second {
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    span.third {
      -ms-transform: rotate(-60deg);
      transform: rotate(-60deg);
    }
  }

  &.active {
    .basket {
      animation-name: added;
      animation-duration: 0.6s;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      color: #fff;
    }

    .lines {
      width: 44px;

      span {
        -webkit-animation: line-grow 0.6s linear forwards;
        animation: line-grow 0.6s linear forwards;
      }
    }

    @keyframes line-grow {
      10% {
        width: 10px;
      }

      50% {
        width: 30px;
      }

      80% {
        width: 20px;
      }

      to {
        width: 10px;
        transform-origin: -6px 1px;
        margin-left: 0.57142857rem;
      }
    }

    @keyframes added {
      0% {
        transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
      }

      25% {
        transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
      }

      50% {
        transform: scale(1.2) rotate(3deg);
        -moz-transform: scale(1.2) rotate(3deg);
        -ms-transform: scale(1.2) rotate(3deg);
        -o-transform: scale(1.2) rotate(3deg);
      }

      75% {
        transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
      }

      to {
        transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
      }
    }
  }
}

@media (max-width: 992px) {
  .shopping-cart {
    .basket {
      line-height: 45px;
      width: 40px;
      height: 40px;
    }
  }
}
