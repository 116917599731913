.switch-button {
  display: inline-flex;
  border-radius: 200px;
  background-color: $Light-Gray;
  align-items: center;
}

.switch-button .switch-button {
  padding-right: 12px;
  padding-left: 12px;
}

.switch-button .switch-button:hover {
  cursor: pointer;
}

.switch-button .active {
  border: 1px solid $Brand-Black;
  background-color: $Brand-Black;
  border-radius: 200px;
  color: white;
}
