.uber-eats-alert-modal-container-info {
  .modal-body {
    justify-content: center;
    justify-items: center;
    // @media #{$mobile-only} {
    //   grid-template-rows: 2fr 1fr 1fr auto 2fr;
    // }
  }
  .uber-eats-alert-main-image {
    width: 30%;
    align-self: center;
    min-width: 206px;
  }

  .uber-eats-alert-titles {
    justify-content: center;
    justify-items: center;
    .uber-eats-alert-first-headline {
      font-size: 26px;
      font-weight: 700;
      @media #{$breakpoint-tablet-only} {
        font-size: 24px;
      }
      @media #{$mobile-only} {
        font-size: 22px;
      }
    }
    .uber-eats-alert-second-headline {
      font-size: 48px;
      font-weight: 700;
      color: $Brand-Red;
      @media #{$breakpoint-tablet-only} {
        font-size: 46px;
      }
      @media #{$mobile-only} {
        font-size: 44px;
      }
    }
    .uber-eats-alert-third-headline {
      font-size: 34px;
      font-weight: 700;
      color: $Brand-Red;
      @media #{$breakpoint-tablet-only} {
        font-size: 32px;
      }
      @media #{$mobile-only} {
        font-size: 30px;
      }
    }
  }
  .uber-eats-alert-description {
    font-size: 20px;
    font-weight: 500;
    width: 90%;
    @media #{$breakpoint-tablet-only} {
      font-size: 18px;
    }
    @media #{$mobile-only} {
      font-size: 18px;
    }
  }
  .uber-eats-alert-more-info-link {
    font-size: 20px;
    text-decoration: underline;
    font-weight: normal;
    color: black;
    @media #{$breakpoint-tablet-only} {
      font-size: 18px;
    }
    @media #{$mobile-only} {
      font-size: 18px;
    }
  }
  .uber-eats-alert-submit-button {
    @media #{$breakpoint-below-tablet} {
      height: 3.75rem;
      padding: 0px 5.5rem;
      border: 0;
      border-radius: 2.4rem;
      font-size: 28px;
    }
    @media #{$mobile-only} {
      padding: 0px 5.5rem;
      margin-top: 24px;
    }
  }
}

.uber-eats-alert-modal-container-expanded {
  .modal-body {
    justify-content: center;
    justify-items: center;
    grid-template-rows: 1fr 1fr 1fr 1.5fr 1fr;
    @media #{$mobile-only} {
      grid-template-rows: 1fr 1.5fr 0.75fr 4fr 2fr;
    }
  }

  .uber-eats-alert-second-main-headline {
    // font-size: 28px;
    font-size: 38px;
    font-weight: 900;
    align-self: center;
    @media #{$breakpoint-tablet-only} {
      width: 50%;
      font-size: 34px;
      text-align: center;
    }
    @media #{$mobile-only} {
      font-size: 32px;
      // width: 70%;
    }
  }

  .uber-eats-alert-delivery-logo {
    align-self: center;
    width: 45%;
    min-width: 206px;
    @media #{$mobile-only} {
      width: 70%;
    }
  }

  .uber-eats-alert-third-title {
    width: 80%;
    // text-align: left;
    align-self: center;
    font-size: 28px;
    font-weight: 700;
    @media #{$breakpoint-tablet-only} {
      width: 70%;
    }
    @media #{$mobile-only} {
      font-size: 20px;
    }
  }

  .uber-eats-alert-subtitle {
    width: 90%;
    font-size: 20px;
    @media #{$mobile-only} {
      font-size: 18px;
    }
  }

  .uber-eats-alert-submit-button {
    @media #{$breakpoint-below-tablet} {
      height: 3.75rem;
      padding: 0px 5.5rem;
      border: 0;
      border-radius: 2.4rem;
      font-size: 24px;
    }
    @media #{$mobile-only} {
      padding: 0px 5.5rem;
      margin-top: 24px;
      font-size: 18px;
      // align-self: end;
    }
  }
}
