.mobileOtp {
  margin: 0 10%;
  @media #{$breakpoint-above-medium-tablet} {
    margin-left: 230px;
  }
  .kfcStripes {
    margin: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'National2CondensedWeb-Medium';
    font-weight: 700;
    font-size: 44px;
    line-height: 58px;
    color: #202124;
    .stripes {
      margin-bottom: 11px;
    }
    h1 {
      font-family: 'National2CondensedWeb-Medium';
      font-size: 48px;
      font-weight: 700;
      width: 38%;
      @media #{$breakpoint-below-tablet} {
        width: 50%;
        font-size: 36px;
      }
      @media #{$breakpoint-iphone-plus} {
        width: 85%;
      }
      @media #{$breakpoint-below-include-iphoneX} {
        width: 96%;
      }
      @media #{$breakpoint-iphone12-size} {
        width: 85%;
      }
    }
  }
  .error-div-container {
    display: block;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #fff4f2;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    font-family: 'National 2 Regular';
    padding: 15px 0px 15px 20px;

    .error-div-msg {
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: #202124;
      font-family: 'National 2 Regular';
    }
  }
  .otp-screen {
    height: 477px;
    left: 0px;
    top: 0px;
    border-radius: 3px;
    background-color: #f8f7f5;
    display: flex;
    flex-direction: row;
    @media #{$breakpoint-below-tablet} {
      display: unset;
    }
    @media #{$breakpoint-ipad-12-pro-portrait} {
      margin: unset;
    }
    .left-section {
      width: 180px;
      margin: 40px 20px;
      font-family: $National2Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-align: left;
      color: $Brand-Black;
      @media #{$breakpoint-below-tablet} {
        width: unset;
      }
    }
    .right-section {
      width: 75%;
      margin: 40px 0px;
      font-family: $National2Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.10000000149011612px;
      text-align: center;
      margin-bottom: 10px;
      @media #{$breakpoint-below-tablet} {
        width: unset;
      }
      .phone-number {
        font-family: 'National2-Medium';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #202124;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }
      .sendto {
        font-family: $National2Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: $Brand-Black;
      }
      .otpDiv {
        text-align: center;
        display: flex;
        width: 60%;
        margin: 1% auto 1%;
        position: relative;
        img {
          position: absolute;
          right: 0px;
        }
      }
      .otp-invalid-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }
      .otpNumber {
        width: 14%;
        margin: 0 auto;
        border: none;
        border-bottom: 2px solid #494949;
        outline: none;
        text-align: center;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 44px;
        text-transform: uppercase;
        color: #202124;
        // color: #494949;
        background: transparent;
      }
      .otpNum-invalid {
        border-bottom: 2px solid #ff2e00;
      }
      .otp-invalid {
        color: #f8f7f5 !important;
      }
      .otp-expired-text {
        margin-top: 22px;
        margin-bottom: 22px;
        color: #626365;
      }
      .otp-link-button {
        margin: 10px 0px !important;
        border: none;
        background: transparent;
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline !important;
        color: #494949 !important;
        cursor: pointer;
        width: max-content;
      }
      .disableOtpLinkCls {
        color: #dbdbdb !important;
      }
      .otp-link-button:focus {
        outline: none;
      }
      .buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0px;
      }
      .buttons-group {
        border: 1.5px solid #202124;
        box-sizing: border-box;
        border-radius: 200px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
      }
      .save {
        color: white;
        background: $Brand-Black;

        padding: 10px 40px;

        font-family: $National2Regular;
      }
      .save-disabled {
        color: #202124;
        background: #dbdbdb;

        padding: 10px 40px;
        font-family: $National2Regular;
        border: 1.5px solid #dbdbdb;
      }
    }
  }
}
.otp-err-toast {
  background: #ffeeee;
}
.page-otp-main-container {
  @media #{$breakpoint-below-tablet} {
    background-color: #f8f7f5;
    display: flex;
    flex-direction: column;
  }
  margin: 0;
  .left-section {
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 5px;
    }
  }
  .right-section {
    .otp-title-text-th {
      font-family: $National2Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.10000000149011612px;
      text-align: center;
      margin-bottom: 10px;
      color: #626365 !important;
    }
    .otpDiv {
      @media #{$mobile-tablet-only} {
        width: 88%;
        column-gap: 5%;
      }
    }
    .otpNum {
      width: 14%;
      font-size: 44px;
      border-radius: 0px !important;
    }
    .otpNum-invalid {
      border-color: #ff2e00 !important;
      color: #202124 !important;
    }
    .otp-invalid {
      color: #626365 !important;
    }
    .infoPayment {
      line-height: 11px;
      margin-top: 2%;
      color: #626365 !important;
      margin-bottom: 0px;
    }
  }
}
