.pastorder-background {
  background: #f8f7f5;
}
.pastorder-container {
  background: $Mashed-Potato-Gray;
  display: flex;
  // height: 508px;

  @media #{$breakpoint-below-tablet} {
    flex-direction: column;
    // height: 644px;
  }
}

.pastorder-left-container {
  width: 30%;
  @media #{$breakpoint-below-tablet} {
    margin-left: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
}

.pastorder-container-in {
  .pastorder-left-container {
    margin-left: 13%;
  }
}

.pastorder-stripes {
  width: 40px;
  height: 20px;
  background: repeating-linear-gradient(to right, #e4002b, #e4002b 8px, #fff 8px, #fff 16px);
}

.pastorder-greeting-heading {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 58px;
  text-transform: uppercase;
  color: $Brand-Black;
  margin-top: 29.71px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  margin-left: -6px;

  .user-name-greeting {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 93px;
    @media (min-width: 1024px) {
      vertical-align: bottom;
    }
    @media #{$breakpoint-below-tablet} {
      line-height: 18px;
    }
    @media #{$breakpoint-above-destop-large-desktop} {
      max-width: 140px;
    }
  }

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 1px;
    display: block;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.user-name-greeting-container-text {
  overflow: hidden;
  display: inline-block;
  @media #{$breakpoint-below-desktop-above-tablet} {
    margin-left: -16px;
  }
}
.user-name-greeting-container {
  @media #{$breakpoint-below-tablet} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
  }
  @media #{$breakpoint-below-medium-only} {
    width: 100%;
    .user-name-greeting-container-text {
      margin-left: 20px;
      margin-right: 20px;
      &.bigNames {
        padding-left: 28px;
        white-space: pre-wrap;
      }
    }
  }
}

.pastorder-my-account {
  @media #{$breakpoint-below-tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    position: relative;
    width: 50%;
  }
}

.pastorder-my-account-text {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: $Brand-Black;
  cursor: pointer;
  padding-bottom: 10%;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}

.pastorder-my-account-arrow {
  margin-left: 10px;
  display: inline-block;
}

.pastorder-right-container {
  width: 62%;
  padding-left: 4%;
  position: relative;

  &.pastorder-right-container-in {
    width: 68%;
    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }
  }

  @media #{$breakpoint-below-tablet} {
    margin-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.pastorder-right-header {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 64px 0px 30px 20px;

  @media #{$breakpoint-below-tablet} {
    width: 89.4%;
    // margin: 15% 0 10% 0;
    margin: 0 0 10% 0;
  }
}

.text-au {
  @media #{$breakpoint-ipad-pro-tablet} {
    width: 26% !important;
  }
}
.pastorder-right-header-text {
  width: 18%;
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $Dark-Gray;

  @media #{$mobile-only} {
    width: 55%;
  }
}

.pastorder-right-header-line {
  margin-left: 10px;
  width: 83%;
  height: 2px;
  background: $Light-Gray;

  @media #{$breakpoint-below-tablet} {
    width: 60%;
  }
}

.pastorder-card {
  align-items: center;
  height: 280px;
  width: 214px;

  .pastorder-card-main-text {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;

    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 1px;
    }
  }

  .pastorder-card-button {
    padding: 10px 40px;
  }
}

.pastorder-card-au {
  align-items: flex-start;
  min-height: 272px;
  height: auto;
  width: 218px;
  padding: 20px;
  outline: none;
  .pastorder-card-main-text {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Dark-Gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 138px;
  }

  .pastorder-card-date-text {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 95%;

    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }

  .pastorder-card-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }

  .order-tracking-number {
    font-family: $National2Regular;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
  }

  .store-name {
    font-family: $National2Medium;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pastorder-text {
    text-transform: capitalize;
    flex: 1;
  }
  .pastorder-total {
    font-family: $National2Medium;
    font-weight: 500;
  }
  .pastorder-card-button {
    padding: 10px 40.4px;
  }

  .pastorder-card-details {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
  }

  .pastorder-card-item-info {
    width: 174px;
  }

  .past-order-store-info {
    margin-top: 10px;
  }
}

.pastorder-card,
.pastorder-card-au {
  background: $white;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-left: 5.33%;
  display: flex;
  flex-direction: column;

  @media #{$breakpoint-below-tablet} {
    display: flex;
    flex: 0 0 auto;
    // justify-content: center;
    width: 220px;
    margin-left: 5.33%;
  }

  .pastorder-card-decomposition-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: $Dark-Gray;
    margin-bottom: 0;
    @media #{$breakpoint-below-tablet} {
      padding-top: 3px;
    }
  }

  .pastorder-pastorders-images {
    width: 75%;
    height: 35%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .item-desc {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    .item-quantity {
      font-family: $National2Medium;
      font-weight: 500;
      background: $Light-Gray;
      mix-blend-mode: multiply;
      border-radius: 4px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 22px;
    }

    .item-name {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      white-space: nowrap;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pastorder-pastorders-count {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: $Dark-Gray;
  }

  .pastorder-view-details {
    padding-top: 10px;
    font-family: $National2Regular;
    margin-top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    align-items: $center;
    text-align: $center;
    text-decoration-line: underline;
    color: $Brand-Black;
    cursor: pointer;
    margin-bottom: 0;
  }

  .pastorder-card-button-conatiner {
    text-align: center;
    margin-top: 10px;
  }

  .pastorder-card-button {
    border: none;
    border-radius: 38px;
    display: flex;
    align-items: center;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    background: $Brand-Red;
    color: $white;
    white-space: nowrap;
  }
}

.pastorder-card:first-child {
  @media #{$breakpoint-below-tablet} {
    margin-left: 18.33% !important;
  }
}

.pastorderCard-list {
  display: flex;
  padding: '5px 0';
  flex-direction: column;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }

  & .slick-prev {
    left: -10%;
    top: 40%;

    @media #{$breakpoint-below-tablet} {
      display: none;
    }
  }

  & .slick-prev::before {
    font-family: unset;
    font-size: 36px;
    content: '\2039'; // \203A HTML Entry for ‹
    color: #202124;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }

  & .slick-list {
    width: 68%;
    padding-left: 1rem;
    height: 310px;

    @media #{$breakpoint-below-tablet} {
      padding-left: 0;
      width: 100%;
    }

    @media #{$breakpoint-below-desktop-above-tablet} {
      width: 100%;
    }

    @media #{$breakpoint-between-tablet-desktop} {
      width: 82%;
    }

    & .slick-track {
      margin-left: 0;

      & .slick-slide {
        margin-left: 32px;
        width: 192px; //214px !important;

        @media #{$breakpoint-below-tablet} {
          margin-left: 20px !important;
          opacity: 1;
          width: 214px !important;
        }
        @media #{$large-only} {
          width: 175px;
        }

        & .small-menu-product-image {
          width: 300px;
          height: 229px;
        }

        & .recommendedCard-fade {
          opacity: 0.25;
        }
      }
    }
  }

  & .slick-next {
    right: 18%;
    top: 20%;

    @media #{$breakpoint-below-tablet} {
      display: none;
    }

    @media #{$breakpoint-below-desktop-above-tablet} {
      right: 5%;
    }

    @media #{$breakpoint-between-tablet-desktop} {
      right: 10%;
    }
  }

  & .slick-next::before {
    font-family: unset;
    font-size: 36px;
    content: '\203A'; // \203A HTML Entry for ›
    color: #202124;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }

  & .medium-menu-product-card {
    position: relative;
    height: 475px;
    font-family: $National2Regular;
    background: $white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 20px;
    margin-bottom: 50px;

    .medium-menu-product-image {
      width: 100%;
      height: 210px;
      margin-bottom: 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .specials-product-ribbon {
      position: absolute;
      top: 3%;
      left: 6%;
      transform: scaleX(1.5);
    }

    .specials-ribbon-text {
      position: absolute;
      top: 4%;
      left: 0%;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $white;
    }

    .discount-product-ribbon {
      position: absolute;
      top: 3%;
      left: -12%;
      transform: scaleX(1.5);
    }

    .discount-ribbon-text {
      position: absolute;
      left: 0;
      top: 3%;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Red;
    }

    .exclusive-product-ribbon {
      position: absolute;
      top: 3%;
      left: -3%;
      transform: scaleX(2.5);
    }

    .menu-product-save {
      position: absolute;
      left: 87.86%;
      right: 3.57%;
      top: 2.11%;
      bottom: 92.84%;
      cursor: pointer;
    }

    .menu-card-content {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;

      .medium-menu-product-header {
        margin-bottom: 5px;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: $National2Regular;
        color: $Brand-Black;
      }

      .medium-menu-vegOrNonVeg {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 5px;
        color: $Dark-Gray;
        margin-left: 7px;
      }

      .medium-menu-product-price {
        font-family: $National2Regular;
        color: $Brand-Black;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;
      }

      .medium-menu-product-description {
        font-style: normal;
        font-weight: normal;
        font-family: $National2Regular;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .medium-menu-cart-button {
        display: flex;
        justify-content: center;
        width: 80%;
        height: 44px;
        margin-top: 10px;
        position: absolute;
        left: 10%;
        right: 10%;
        bottom: 1rem;
      }
    }
  }

  & .pastorder-slideContainer {
    display: none;

    @media #{$breakpoint-below-tablet} {
      width: 95%;
      margin-bottom: 8.3%;
      margin-left: 2%;
      display: block;
    }

    & .pastorder-slider {
      @media #{$breakpoint-below-tablet} {
        width: 100%;
        height: 3px;
        -webkit-appearance: none;
        background: $Light-Gray;
        outline: none;
        border-radius: 5%;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
      }
    }

    & .pastorder-slider::-webkit-slider-thumb {
      @media #{$breakpoint-below-tablet} {
        -webkit-appearance: none;
        appearance: none;
        width: 80px;
        height: 3px;
        border-radius: 5%;
        background: $Brand-Black;
        cursor: pointer; /* Cursor on hover */
      }
    }

    & .pastorder-slider::-moz-range-thumb {
      @media #{$breakpoint-below-tablet} {
        -webkit-appearance: none;
        appearance: none;
        width: 10%;
        height: 2px;
        border-radius: 5%;
        background: $Brand-Black;
        cursor: pointer; /* Cursor on hover */
        border: 0;
      }
    }
  }

  &.pastorderCard-list-in {
    .slick-list {
      width: 100% !important;
      @media #{$breakpoint-below-tablet} {
        width: 100%;
      }
    }

    .slick-next {
      top: 40%;
      right: 18%;
    }
  }
}

.pastorder-card-list {
  display: flex;
  justify-content: flex-start;
  width: 84%;
  margin-bottom: 4.5%;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 40vh;

    /* width */
    &.pastorder-card-list::-webkit-scrollbar {
      height: 3px;
    }

    /* Track */
    &.pastorder-card-list::-webkit-scrollbar-track {
      background: $Light-Gray;
      margin-left: 5vw;
      margin-right: 5vw;
    }

    /* Handle */
    &.pastorder-card-list::-webkit-scrollbar-thumb {
      background: $Brand-Black;
    }
  }

  @media (orientation: landscape) {
    height: max-content;
  }
}

.pastorder-full-history {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: $Dark-Gray;
  margin: 0px 0px 60px 20px;
  cursor: pointer;
  @media #{$breakpoint-below-tablet} {
    margin-bottom: 15%;
  }
}

.pastorder-pastorder-istimage {
  margin: 0;
  padding: 0;
  width: 33%;
  height: 100%;
  border-radius: 100%;
  z-index: 3;
}

.pastorder-pastorder-secimage {
  margin: 0;
  padding: 0;
  width: 33%;
  height: 100%;
  border-radius: 100%;
  margin-right: -10%;
  z-index: 2;
}

.pastorder-pastorder-thirdimage {
  margin: 0;
  padding: 0;
  width: 33%;
  height: 100%;
  border-radius: 100%;
  margin-left: -8%;
  z-index: 1;
}

.QuickReorder-modal,
.mobile-QuickReorder-modal {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  overflow-y: scroll;

  .modal-content {
    .modal-body {
      background: $white;
      padding: 0;

      .modal-footer {
        justify-content: center;
        flex-wrap: nowrap;
      }
    }
  }

  .reorder-order-detail {
    margin-top: 30px;
  }

  .ReorderModal {
    padding: 31.5px 50px;
  }

  .global-accordian-content {
    background: $white;
    box-shadow: none;
  }

  .global-accordian-header-container {
    padding: 0;
  }

  .reorder-header {
    display: flex;
    justify-content: center;
  }

  .change-setting-btn {
    font-weight: bold;
    cursor: pointer;
  }

  .your-order {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;
  }

  .reorder-number,
  .reorder-sub-heading,
  .payment-total,
  .payment-total-amount {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;
  }

  .reorder-details-heading {
    display: flex;
    margin-top: 30px;
  }

  .reorder-sub-heading {
    flex-basis: 150px;
  }

  .reorder-sub-detail {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $Dark-Gray;
    text-transform: capitalize;
  }

  .order-from-section {
    display: flex;
    flex-direction: column;
  }

  .contact-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .phoneNumber {
      margin-right: 45px;
    }
  }

  .reorder-modification-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $Mashed-Potato-Gray;
    border-radius: 4px;

    .reorder-subheading {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
    }
  }

  .cancel-btn {
    width: 40%;
  }

  .horizontal-bar {
    border-top: 2px solid $Brand-Black;
  }

  .ml {
    margin-left: 10px;
  }

  .mr {
    margin-right: 10px;
  }

  .mt {
    margin-top: 19.8px !important;
  }

  .reorder-payment-details {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }

  .payment-type {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Dark-Gray;
    font-family: $National2Regular;
    text-transform: capitalize;
  }

  .payment-amount,
  .item-amount {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: $Brand-Black;
  }

  .width-style {
    width: 30%;
  }

  .width-style-lg {
    width: 65%;
  }

  .reorder-accordian {
    border-top: 2px solid $Brand-Black;
    border-bottom: 2px solid $Brand-Black;
    margin-top: 32px;
    padding: 5px;
    .global-accordian-header,
    .global-accordian-arrow {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
      font-weight: bold;
      font-family: $National2Condensed;
    }

    .reorder-order-item {
      margin-bottom: 8px;
    }

    .reorder-order-item,
    .reorder-item-details {
      display: flex;
      justify-content: space-between;
    }

    .item-count-box {
      height: 24px;
      width: 24px;
      left: 0px;
      top: 0px;
      background: $Light-Gray;
      mix-blend-mode: multiply;
      border-radius: 4px;
      .item-count {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: $Brand-Black;
      }
    }

    .item-desc-section {
      display: flex;
      flex-direction: column;
      font-family: $National2Regular;
      font-style: normal;
    }

    .item-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $Brand-Black;
    }

    .item-desc-quantity {
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
    }
  }
}

.reorder-modal,
.mobile-reorder-modal {
  .modal-content {
    height: 334px;

    .modal-body {
      background: $white;
      box-shadow: none;
    }

    .modal-header {
      border-bottom: none;

      .custom-modal-close {
        top: 5%;
        z-index: 1;
      }
    }
  }

  .confirmationModal {
    padding: 35px 80px 52px 80px;
  }

  .reorder-modal-container {
    display: flex;
    justify-content: center;
  }

  .reorder-modal-btn {
    position: absolute;
    bottom: 22%;
  }

  .reorder-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-icon {
    width: 60px;
    height: 61.01px;
  }

  .error-msg {
    font-family: $National2Condensed;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;
    width: 377px;
  }
}

.mobile-reorder-modal {
  .modal-content {
    height: 301px;
    top: 25%;
    .modal-body {
      padding: 20px;
    }
  }

  .confirmationModal {
    padding: 0;
  }

  .error-msg {
    width: 335px;
    font-size: 20px;
  }

  .reorder-error-container {
    .reorder-modal-btn {
      bottom: 15%;
    }
    .closebtn-cls {
      margin-right: 5px;
    }
  }
}

.mobile-QuickReorder-modal {
  .modal-content {
    height: auto;
  }
  .ReorderModal {
    padding: 30px 20px;
  }
  .modal-footer {
    border: none;
    button {
      padding: 11px 20px;
    }
  }

  .reorder-modal-btn {
    display: flex;
    button {
      padding: 11px 20px;
    }
  }
}

.brand-black {
  color: $Brand-Black !important;
}

.dark-gray {
  color: $Dark-Gray !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-camelcase {
  text-transform: capitalize !important;
}

.pastorder-line {
  height: 1px;
  width: 174px;
  background: $Light-Gray;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }
}

.pastorder-item-info {
  display: flex;
}

.store-name {
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-list {
  & .slick-list {
    width: 76%;
    & .slick-track {
      & .slick-slide {
        // width: 185px;
        // margin-left: 40px !important;
        & .pastorder-card {
          width: 200px;
        }
      }
    }
    @media #{$breakpoint-below-tablet} {
      // margin-left: 25px !important;
      width: 100%;
    }
  }
}

.order-list-au {
  & .slick-list {
    width: 700px;
    & .slick-track {
      & .slick-slide {
        width: 198px;
        margin-left: 36px;
      }
    }
    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }

    @media #{$breakpoint-between-tablet-desktop} {
      width: 700px;
    }
  }

  & .slick-next {
    right: -14%;
    top: 40%;
    @media #{$breakpoint-below-tablet} {
      right: 5%;
    }

    @media #{$breakpoint-between-tablet-desktop} {
      right: -11%;
    }
  }

  .pastorder-card-au:first-child {
    @media #{$breakpoint-below-tablet} {
      margin-left: 8.33% !important;
    }
  }
}

.past-order-au-card {
  & .slick-list {
    width: 700px;
    & .slick-track {
      margin-left: -32px;
    }
    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }
  }

  .pastorder-card-au:first-child {
    @media #{$breakpoint-below-tablet} {
      margin-left: 23.33% !important;
    }
  }
}

.pastorder-global-container {
  .pastorder-right-header-line {
    @media #{$breakpoint-below-tablet} {
      width: 80%;
    }
  }
  .pastorder-left-container {
    width: 21%;
    @media #{$breakpoint-between-tablet-desktop} {
      margin-left: 1.5%;
    }

    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
      width: 25%;
      margin-left: 4.5%;
    }
  }
  .pastorder-my-account-text {
    width: 60%;
    @media #{$breakpoint-iphone-11-pro-portrait} {
      width: 65%;
    }
  }

  .pastorder-right-header {
    @media #{$breakpoint-below-tablet} {
      margin: 0 0 30px 0;
    }
  }
}
