#map {
  height: 500px;
  width: 100%;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    height: 607px;
  }
}

.map-modal {
  & .modal-content {
    position: absolute;
    width: 60%;
    height: auto;
    left: 24%;
    top: 12%;
    bottom: 12%;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-body {
      padding: 1%;
    }

    & .modal-header {
      border-bottom: 0;

      @media #{$breakpoint-below-tablet} {
        padding: 3% 1%;
      }

      & .custom-modal-close {
        width: 31px;
        height: 8px;
        right: 13px;

        @media #{$breakpoint-below-tablet} {
          width: 37px;
          height: 16px;
          right: 3px;
        }
      }
    }
  }
}

.arrows {
  position: absolute;
  right: 3%;
  display: flex;
  z-index: 10;

  @media #{$breakpoint-below-tablet} {
    @media (orientation: landscape) {
      display: none;
    }
  }

  & .map-arrow-right {
    /* position: absolute;
		left: 89%; */
    bottom: 0;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 66px;
    transform: rotate(-180deg);
    z-index: 1;
    height: 32.00000000000012px;
    width: 32.00000000000012px;

    & img {
      height: 18px;
      width: 24.5px;
      left: 0;
      top: 6px;
      //position: absolute;
      border-radius: 0;
    }
  }

  & .map-arrow-left {
    /* position: absolute;
		left: 78%; */
    bottom: 0;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 66px;
    z-index: 1;
    height: 32.00000000000012px;
    width: 32.00000000000012px;
    margin-right: 7px;

    & img {
      height: 18px;
      width: 24.5px;
      left: 2px;
      top: 6px;
      //position: absolute;
      border-radius: 0;
    }
  }

  & .disableArrow {
    cursor: not-allowed;
    pointer-events: none;
    background: transparent;
    box-shadow: 0 0 2px 0 #202124;
    opacity: 0.7;
  }
}
