.backToTop {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 8;

  &:hover,
  &:active {
    cursor: pointer;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
