.coupon-top-box {
  width: 80%;
  margin: auto;
  border: 1px solid #e3e3e3;

  @media #{$breakpoint-below-tablet} {
    width: 88.6% !important;
  }

  & .coupon-top-content {
    width: 90%;
    margin: auto;
    display: $d-flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $Brand-Black;
    text-align: $left;
    font-style: normal;

    @media #{$breakpoint-below-tablet} {
      width: 88% !important;
    }

    & .coupon-discount-col-1 {
      width: 45%;
      margin: 4.5% 0;

      @media #{$breakpoint-below-tablet} {
        width: 100%;
        margin: 30px 0 10px;
      }

      .coupon-content-header {
        font-family: $National2Condensed;
        font-size: 18px;
        font-weight: $font-weight-bold;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: $uppercase;

        @media #{$breakpoint-below-tablet} {
          font-size: 16px;
          text-align: $center;
          letter-spacing: 1px;
          margin-bottom: 10px !important;
        }
      }

      .coupon-content-desc {
        font-family: $National2Regular;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: -0.1px;
        margin-bottom: 0;

        @media #{$breakpoint-below-tablet} {
          font-size: 14px;
          text-align: $center;
        }
      }
    }

    & .coupon-discount-col-2 {
      width: 34%;
      margin: 4.5% 0;
      font-family: $National2Regular;
      color: $Dark-Gray;

      @media #{$breakpoint-below-tablet} {
        margin-top: 0 !important;
      }

      & .coupon-text-box {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        padding-right: 25px;
        line-height: 24px;
        letter-spacing: -0.1px;
        border: none;
        border-bottom: 1px solid $Brand-Black;
      }

      & .error-msg {
        color: #ff2e00;
      }

      .flex-input-section {
        display: flex;
        position: relative;
      }

      .discount-button-img {
        background: white;
        width: 30px;
        height: 22px;
        position: absolute;
        right: 2px;
        bottom: 2px;
        border: none;
      }

      .discount-button-img::before {
        content: url('../../../styles/staticAssets/images/Darkclosebutton.svg');
        background-size: 12px;
        height: 13px;
        border: none;
        background-repeat: no-repeat;
        background-color: white;
        position: absolute;
        right: 6px;
        top: 2px;
      }

      & .error-container-coupon {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        left: 5px;
        position: relative;
      }

      & .coupon-input-label {
        font-style: normal;
        padding-bottom: 0;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
      }

      @media #{$breakpoint-below-tablet} {
        width: 100% !important;
      }
    }

    .input-dark-cross {
      top: 40%;
      cursor: pointer;
      position: relative;
      right: 20px;
    }

    & .coupon-discount-col-3 {
      width: 18%;
      margin: 4.5% 0 4.5% auto;

      @media #{$breakpoint-below-tablet} {
        margin: 4.5% auto 30px;
      }

      & .coupon-button {
        border: 1.5px solid $Brand-Black;
        margin: auto;
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: $d-flex;
      }
      & .coupon-button:disabled {
        color: $Brand-Black;
      }

      @media #{$breakpoint-below-tablet} {
        width: 90%;
      }
    }
  }
}

.coupon-mid-box {
  width: 80%;
  color: $Brand-Black;
  text-align: $center;
  margin: 4.25% auto 2.1% auto;

  @media #{$breakpoint-below-tablet} {
    margin: 40px auto 30px auto;
  }

  & .modal-mid-header {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: $uppercase;
    margin-bottom: 10px;
  }

  & .modal-mid-desc {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: $underline;
    cursor: pointer;
  }
}

.coupons-module-modal {
  z-index: $z-index-max + 1;
  background-color: #202124a6;
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;

  @media #{$breakpoint-below-tablet} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max + 1;
    width: 100%;
    height: 100%;
  }

  & .modal-content {
    background-color: $white !important;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.15);

    @media #{$breakpoint-above-tablet} {
      height: 80vh !important;
      position: relative;
      width: 66vw !important;
      left: 16vw !important;
      top: 10vh !important;
    }

    @media #{$breakpoint-below-tablet} {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100vh;
    }

    & .modal-header {
      padding: 2% 1%;
      background: #fff;
      border-radius: 10px 10px 0 0;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: $center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
      border-bottom: 1px solid #dbdbdb;
      max-height: 80px;
      height: 20%;

      @media #{$breakpoint-below-tablet} {
        border-radius: 0;
        border-radius: 10px 10px 0 0;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 22px;
      }

      @media (max-width: 480px) {
        border-radius: 10px 10px 0 0;
        max-height: 45px;
      }

      & .custom-modal-close {
        right: 3.5%;
        background-color: transparent;
        border: none;
        cursor: pointer;

        & img {
          width: 19px;
          height: auto;
        }
      }

      & .custom-modal-back {
        background-color: transparent;
        left: 4%;
        border: none;
        cursor: pointer;

        & img {
          width: 14px;
        }
      }

      & .header-title {
        font-family: $National2Condensed !important;
        width: 80vw;
        left: 10vw;
        margin: auto !important;
        font-size: 24px !important;
        line-height: 24px !important;
        font-weight: 700 !important;
        text-align: $center;
        letter-spacing: 0.5px;

        @media #{$breakpoint-below-tablet} {
          font-size: 20px !important;
          line-height: 22px !important;
        }
      }
    }

    & .modal-body {
      background-color: $white;
      overflow: auto !important;
      padding: 3% 0;
      position: relative;
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
      border-radius: 3px;

      @media #{$breakpoint-below-tablet} {
        height: 100%;
        padding: 5% 1.34%;
        overflow-y: auto;
      }
    }
  }
}

.coupon-toast-position {
  max-width: 350px;
}

.success-coupon-toast {
  width: max-content;
  min-width: 278px;
  max-width: 350px;
  margin: auto 0 !important;
  padding: 6px !important;

  & .img-text-div {
    text-align: $center;

    @media #{$mobile-only} {
      margin-right: 4%;
    }
  }

  & .text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    font-family: $National2Regular;
    margin-left: 5px;
  }
}
