.pdp-carousal-container {
  width: 100%;
  height: 746px;
  overflow: hidden;
  background-color: $Mashed-Potato-Gray;
  padding-top: 66px;

  .pdp-carousal-header {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 38px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    display: flex;
    flex-direction: column;

    .sub-header {
      font-family: $National2Regular;
      display: block;
      font-size: 1rem;
      text-transform: initial;
      font-weight: lighter;
    }
  }

  .pdp-carousal-product-list {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 7.5%;

    .slick-slider {
      width: 100vw !important;

      .slick-prev {
        top: 40%;
        left: 6px;
      }

      .slick-prev::before {
        font-size: 40px;
      }

      .slick-track {
        // width: auto !important;
        left: 20px !important;

        .slick-slide {
          width: 280px;
          margin-right: 20px;
          height: 488px;
          opacity: 0.25;

          &.slick-active {
            opacity: 1;
          }

          .pdp-product-card-container {
            position: relative;
            background: $white;
            box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            width: 100%;
            height: 475px;
            margin-right: 20px;

            &.card-active {
              cursor: pointer;
            }

            .pdp-product-card-image {
              height: 210px;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              width: 100%;
              margin-bottom: 10px;
              object-fit: cover;
            }

            .pdp-product-save {
              position: absolute;
              left: 87.86%;
              right: 3.57%;
              top: 2.11%;
              bottom: 92.84%;
              cursor: pointer;
            }

            .pdp-specials-product-ribbon {
              position: absolute;
              top: 9px;
              left: 15px;
              transform: scaleX(1.5);
            }

            .pdp-exclusive-product-ribbon {
              position: absolute;
              top: 9px;
              left: -26px;
              transform: scaleX(1.7);
            }

            .pdp-discount-product-ribbon {
              position: absolute;
              top: 9px;
              left: -32px;
              transform: scaleX(1.5);
            }

            .pdp-product-specials {
              position: absolute;
              top: 13px;
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 20px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $white;
            }

            .pdp-product-discount {
              position: absolute;
              top: 9px;
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 20px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $Brand-Red;
            }

            .pdp-product-card-content {
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 20px;

              .pdp-product-card-header {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 5px 0;
              }

              .pdp-product-card-veg-icon {
                display: inline;
              }

              .pdp-product-card-veg {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                color: $Dark-Gray;
                display: inline;
                margin-left: 7px;
              }

              .pdp-product-card-price {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
                flex: none;
                order: 2;
                flex-grow: 0;
                margin: 5px 0;
              }

              .pdp-product-card-description {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.1px;
                color: $Dark-Gray;
                flex: none;
                order: 3;
                flex-grow: 0;
                margin: 5px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
              }

              .pdp-product-card-button {
                width: 86%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                margin-top: 10px;
                left: 20px;
                top: 411px;
              }
            }
          }
        }
      }

      .slick-next {
        right: 15%;
        top: 40%;
      }

      .slick-next::before {
        font-size: 40px;
      }
    }
  }

  .pdp-slideContainer {
    width: 90%;
    padding-left: 116px;

    .pdp-slider {
      width: 100%;
      height: 3px;
      -webkit-appearance: none;
      background: $Light-Gray;
      outline: none;
      border-radius: 5%;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    .pdp-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20%;
      height: 3px;
      border-radius: 5%;
      background: $Dark-Gray;
      cursor: pointer; /* Cursor on hover */
    }

    .pdp-slider::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20%;
      height: 2px;
      border-radius: 5%;
      background: $Dark-Gray;
      cursor: pointer; /* Cursor on hover */
      border: 0;
    }
  }
}
