.alet-modal.user-session-expiry-modal,
.alet-modal.cart-update-error-modal {
  z-index: $z-index-max + 2;
  & .modal-content {
    height: 341px;
    & .modal-header {
      display: none;
    }
    & .modal-body {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @media #{$breakpoint-below-tablet} {
        padding: 60px 5%;
      }
      & .alert-model-view-root {
        height: 100%;
        & img.alert-confirm-image {
          width: 65px;
          height: 80.71px;
          margin-bottom: 21px;
          @media #{$breakpoint-below-tablet} {
            width: 48.32px;
            height: 60px;
          }
        }
        & .alert-title {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          @media #{$breakpoint-below-tablet} {
            font-size: 20px;
            line-height: 22px;
            letter-spacing: 1px;
          }
          & .alert-red-text {
            color: $Brand-Red;
            display: block;
          }
        }
        & .alert-action-container {
          & button.alert-confirm {
            min-width: 138px;
            height: 44px;
          }
          & button.confirm-button-classname {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.alet-modal.cart-update-error-modal {
  z-index: $z-index-max + 1;
}
