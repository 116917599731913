.giftcard-purchase-success {
  .shared-modal-container .modal-content .modal-header .custom-modal-close img {
    width: 65% !important;
  }

  & .modal-content {
    position: absolute;
    width: 536px;
    height: 350px;
    top: 30%;
    left: 32.5%;
    overflow-y: scroll;
    overflow-x: hidden;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 365px;
      top: 28%;
      left: 0;
    }

    & .modal-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 100%;
      border-bottom: none;
      padding-top: 5%;

      @media #{$breakpoint-below-tablet} {
        padding-top: 10%;
        width: 100%;
        box-shadow: 0 0 black;
      }
    }

    & .modal-body {
      background-color: #fff;
      padding-right: 20px;
      padding-left: 20px;

      @media #{$breakpoint-below-tablet} {
        overflow-y: hidden;
        margin-top: 5px;
        padding-right: 11px;
        padding-left: 11px;
      }
    }
  }
}

.giftCardSuccessContent {
  .imageComp {
    text-align: center;
    height: 88px;
  }

  .congratsMsg {
    margin-top: 20px;
    text-transform: uppercase;
    font-family: $National2Condensed;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .feedbackMsg {
    margin-top: 10px;
    font-family: $National2Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .buttonTobuy {
    text-align: center;
    margin-top: 30px;

    .whiteButton.blackBorder {
      border: 2px solid #202124;
      width: 250px;
      height: 44px;
    }

    .button {
      padding: unset;
      border-radius: 200px;
      text-align: center;
      align-items: center;
      flex-direction: row;
      font-family: $National2Medium;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
