.large-menu-container {
  background-color: $Mashed-Potato-Gray;
  padding-left: 2vw;
  left: 430px;
  top: 348px;
  margin-top: 7%;
  border-radius: 8px;
  width: 100%;

  .cardButtonOnly {
    justify-content: flex-start !important;
    margin-top: 2rem !important;
    &.cardButtonOnly-in {
      .button {
        padding: 11px 40px;
      }
    }
  }

  @media #{$breakpoint-below-tablet} {
    background-color: $white;
  }

  .specials-product-ribbon {
    position: absolute;
    top: 1.5%;
    left: 4%;
    transform: scaleX(1.5);

    @media #{$breakpoint-below-tablet} {
      position: absolute;
      left: 5%;
      transform: scaleX(1.5);
    }
  }

  .specials-ribbon-text {
    position: absolute;
    top: 1.56%;
    left: 3%;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $white;

    @media #{$breakpoint-below-tablet} {
      position: absolute;
      top: 4%;
      left: 0%;
    }
  }

  .discount-product-ribbon {
    position: absolute;
    top: 3%;
    left: -8%;
    transform: scaleX(1.5);
    -webkit-transform: scaleX(1.9) !important;
  }

  .discount-ribbon-text {
    position: absolute;
    left: -6px !important;
    top: 3%;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Red;
  }

  .exclusive-product-ribbon {
    position: absolute;
    top: 3%;
    left: -2%;
    transform: scaleX(2.5);
  }

  .large-category-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 58px;
    text-transform: uppercase;
    //padding-top: 8%;
    padding-top: 2.25rem;
  }

  .large-subcategory-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 35px;

    @media #{$breakpoint-below-tablet} {
      line-height: 32px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: 24px;
      margin-left: 20px;
    }
  }

  .carousal-header {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 100px;
    margin-left: 20px;
  }

  .product-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 43px;

    @media #{$breakpoint-below-tablet} {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      justify-content: space-between;
    }

    .menu-product-card,
    .medium-menu-product-card {
      position: relative;
      width: 46.5%;
      // height: auto;
      height: 537px;
      font-family: $National2Regular;
      background-color: $Mashed-Potato-Gray;
      margin-right: 3.4%;
      margin-bottom: 50px;

      @media #{$breakpoint-below-tablet} {
        position: relative;
        margin-left: 20px;
        background-color: $white;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 300px;
        height: 485px;
      }

      a {
        text-decoration: none;
      }

      .menu-product-image {
        width: 100%;
        // height: auto;
        height: 285px;
        border-radius: 3.61px;
        margin-bottom: 17px;

        @media #{$breakpoint-below-tablet} {
          height: 225px;
          width: auto;
          border-radius: 3.61px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          margin-bottom: auto;
          object-fit: contain;
        }
      }

      .specials-product-ribbon {
        position: absolute;
        top: 3%;
        left: 4%;
        transform: scaleX(1.5);

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          top: 3%;
          left: -4%;
        }
      }

      .specials-ribbon-text {
        position: absolute;
        top: 4%;
        left: 0%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          top: 3%;
        }
      }

      .discount-product-ribbon {
        position: absolute;
        top: 3%;
        left: -8%;
        transform: scaleX(1.5);
        -webkit-transform: scaleX(1.9) !important;
      }

      .discount-ribbon-text {
        position: absolute;
        left: 0;
        top: 3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
      }

      .exclusive-product-ribbon {
        position: absolute;
        top: 3%;
        left: -2%;
        transform: scaleX(2.5);
      }

      .menu-product-save {
        position: absolute;
        left: 91.84%;
        right: 1.84%;
        top: 1.86%;
        bottom: 93.67%;
        cursor: pointer;
        z-index: 1;

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          left: 88.67%;
          right: 3.33%;
          top: 2.05%;
          bottom: 93.03%;
        }
      }

      .menu-product-header,
      .medium-menu-product-header {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $Brand-Black;
        margin-bottom: 5px;

        @media #{$breakpoint-below-tablet} {
          margin-left: 15px;
          margin-right: 15px;
          font-family: $National2Regular;
          color: $Brand-Black;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-top: 10px;
        }
      }

      .menu-vegOrNonVeg-icon {
        display: inline-block;
        margin-right: 7px;
        z-index: 100;

        @media #{$breakpoint-below-tablet} {
          margin-left: 15px;
        }
      }

      .menu-vegOrNonVeg {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: $Dark-Gray;
        margin-bottom: 5px;

        @include Dot();
      }

      .menu-product-price,
      .medium-menu-product-price {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $Brand-Black;
        margin-bottom: 5px;

        @media #{$breakpoint-below-tablet} {
          margin-left: 15px;
        }
      }

      .menu-product-description,
      .small-menu-product-description {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;

        @media #{$breakpoint-below-tablet} {
          margin-left: 15px;
          margin-right: 15px;
        }
      }

      .menu-cart-button {
        width: 184px;
        height: 44px;
        // position: absolute;
        // bottom: 0;
        padding: 10px 40px;
        @media #{$breakpoint-below-tablet} {
          margin-left: 15px;
          margin-right: 15px;
          margin-top: 34px;
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }

    .product-card-active {
      cursor: pointer;
      z-index: 50;

      @media #{$breakpoint-below-tablet} {
        text-decoration: none !important;
      }
    }
  }
}

.unavailable-container {
  color: #989898;

  .unavailable-icon {
    margin-right: 0.2rem;
  }
}

.medium-menu-container {
  background-color: $white;
  // border-top: 1px solid $Inactive-Gray;
  //margin-top: 8%;

  .medium-category-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 58px;
    text-transform: uppercase;

    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      color: $Brand-Black;
      margin-left: 20px;
      width: 274px;
    }

    //padding-top: 8%;
    padding-top: 2.25rem;

    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      color: $Brand-Black;
      margin-left: 20px;
      width: 274px;
    }
  }

  .medium-subcategory-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 35px;

    @media #{$breakpoint-below-tablet} {
      display: flex;
      align-items: flex-end;
      letter-spacing: 1px;
      color: $Brand-Black;
      margin-left: 20px;
      margin-top: 24px;
    }
  }

  .product-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    @media #{$breakpoint-below-tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
    }

    .medium-menu-product-card {
      position: relative;
      width: 32%;
      height: 475px;
      font-family: $National2Regular;
      background: $white;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-right: 1%;
      margin-bottom: 3rem;

      a {
        text-decoration: none;
      }

      @media #{$breakpoint-below-tablet} {
        position: relative;
        background: $white;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        width: 94%;
        height: 290px;
        padding: 15px 12px;
        margin-bottom: 15px;
        margin-right: 17px;
        margin-left: 18px;

        a {
          text-decoration: none;
        }
      }

      .medium-menu-product-image {
        width: 100%;
        height: 210px;
        margin-bottom: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          //   left: 65.59%;
          right: 3.82%;
          top: 4.91%;
          bottom: 65.28%;
          width: 100px;
          height: 75px;
          border-radius: 4px;
        }
      }

      .specials-product-ribbon {
        position: absolute;
        top: 3%;
        left: 6%;
        transform: scaleX(1.5);

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          left: 5%;
          transform: scaleX(1.5);
        }
      }

      .specials-ribbon-text {
        position: absolute;
        top: 4%;
        left: 0%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          top: 4%;
          left: 0%;
        }
      }

      .discount-product-ribbon {
        position: absolute;
        top: 3%;
        left: -8%;
        transform: scaleX(1.9);
        -webkit-transform: scaleX(1.9) !important;
      }

      .discount-ribbon-text {
        position: absolute;
        left: 0;
        top: 3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
      }

      .exclusive-product-ribbon {
        position: absolute;
        top: 3%;
        left: -3%;
        transform: scaleX(2.5);
      }

      .menu-product-save {
        position: absolute;
        left: 87.86%;
        right: 3.57%;
        top: 2.11%;
        bottom: 92.84%;
        cursor: pointer;
        z-index: 1;

        @media #{$breakpoint-below-tablet} {
          position: absolute;
          left: 78.88%;
          right: 12.06%;
          top: 82.51%;
          bottom: 9.43%;
          cursor: pointer;
          display: none;
        }
      }

      .menu-card-content {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;

        @media #{$breakpoint-below-tablet} {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: auto;
        }

        .medium-menu-product-header {
          margin-bottom: 5px;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          font-family: $National2Regular;
          color: $Brand-Black;

          @media #{$breakpoint-below-tablet} {
            width: 199px;
            font-family: $National2Regular;
            color: $Brand-Black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }

        .medium-menu-vegOrNonVeg {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          margin-bottom: 5px;
          color: $Dark-Gray;
          margin-left: 7px;

          @include Dot();
        }

        .medium-menu-product-price {
          font-family: $National2Regular;
          color: $Brand-Black;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
        }

        .medium-menu-product-description {
          font-style: normal;
          font-weight: normal;
          font-family: $National2Regular;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        &.menu-card-content-fav {
          .medium-menu-product-header {
            @media #{$breakpoint-below-tablet} {
              width: 58%;
            }
          }

          .medium-menu-product-price {
            .price-difference-container {
              .prev-price {
                text-decoration: line-through;
              }

              .new-current-price {
                color: #ff2e00;
              }
            }
          }
          .product-details-mobile-view {
            .medium-menu-product-description {
              min-height: 62px;
              width: 57%;
            }
            .price-veg-nonveg {
              margin-top: 15px;
              display: flex;
            }
            .heart-icon {
              padding-top: 3px;
            }
            .medium-menu-vegOrNonVeg {
              margin-left: 10px;
              margin-top: 1px;
            }
            .mobile-fav-menu-style,
            .mobile-fav-menu-style-unavailable {
              position: absolute;
              left: 87%;
              @media (min-width: 380px) {
                left: 90%;
              }
            }
          }
        }
      }

      .medium-menu-cart-button {
        display: flex;
        justify-content: center;
        //width: 80%;
        height: 44px;
        margin-top: 10px;
        //position: absolute;
        //left: 10%;
        //right: 10%;
        //bottom: 1rem;

        @media #{$breakpoint-below-tablet} {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 4.41%;
          right: 25%;
          bottom: 5.66%;
          width: 68%;
        }
      }
    }

    .product-card-active {
      cursor: pointer;
      z-index: 50;

      @media #{$breakpoint-below-tablet} {
        text-decoration: none !important;
      }
    }
  }

  &.medium-menu-container-fav-menu {
    .product-container {
      margin-top: 20px;
      margin-right: 20px;

      @media #{$breakpoint-below-tablet} {
        margin-right: 40px;
      }

      .medium-menu-product-card {
        width: 280px;

        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }

        .medium-menu-cart-button {
          margin-bottom: 20px;
        }

        .cart-btn-container {
          width: 100%;

          .edit-selection {
            text-decoration-line: underline;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            bottom: 28px;
            position: relative;
            float: right;
          }
          .cardButtonOnly {
            bottom: 0;
          }

          .mobile-fav-menu-style,
          .mobile-fav-menu-style-unavailable {
            display: none;
          }

          @media #{$breakpoint-below-tablet} {
            display: flex;

            .cardButtonOnly {
              position: static;
            }

            .mobile-fav-menu-style {
              display: flex;
              position: absolute;
              right: 10%;
              bottom: 17%;
            }

            .mobile-fav-menu-style-unavailable {
              display: flex;
              position: absolute;
              bottom: 50px;
              right: 11.5%;
            }

            // img {
            // 	margin-left: -32px;
            // 	margin-top: 40px;
            // }
          }
        }

        @media #{$medium-only} {
          .cardwithQuantity {
            .mobile-fav-menu-style {
              bottom: 12%;
            }
          }
        }

        @media #{$mobile-only} {
          .quantityAddtocart {
            justify-content: flex-start;
            margin-left: -10px;

            .cart-btn-container {
              display: inline-flex;
              justify-content: center;
              //position: absolute;
              //bottom: 0;

              .cardButtonOnly {
                position: revert;
                width: 90%;

                .medium-menu-cart-button {
                  position: relative;
                  width: 90%;
                  left: 0;
                  bottom: 0;
                }
              }

              .mobile-fav-menu-style {
                margin-left: 0;
                //position: relative;
                right: 0;
              }

              .mobile-fav-menu-style-unavailable {
                position: relative;
                display: flex;
                top: 5px;
                right: 12px;
              }

              // img {
              // 	display: inline-flex;
              // 	margin-left: 20px;
              // 	margin-bottom: 20px;
              // 	margin-top: 0;
              // }
            }
          }

          .cardwithNoquantity {
            .cart-btn-container {
              .mobile-fav-menu-style {
                right: 3.82%;
              }
            }
          }
        }
        .medium-menu-product-image-fav {
          @media #{$breakpoint-below-tablet} {
            width: 123px;
            height: 93px;
          }
        }
      }
    }
  }

  &.medium-menu-container-fav-menu-disabled {
    .medium-menu-product-image-fav {
      opacity: 0.4;
    }

    .medium-menu-cart-button-disabled {
      background-color: #dbdbdb;
      border: none;
      //color: #494949;
      font-weight: bold;
    }
  }
}

.small-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;

  .product-view-section {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-right: 20px;
    margin-top: 2vw;
    margin-bottom: 10px;

    .product-view {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-align: right;
      color: $Dark-Gray;
      opacity: 0.5;
    }

    .product-view-up-icon {
      margin-left: 10px;
    }

    .product-view-carousal-icon {
      margin-left: 10px;
    }
  }

  .product-view-divider {
    border-top: 1px solid #e3e3e3 !important;
    width: 100%;
    margin-bottom: 30px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
  }

  .small-category-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-left: 20px;
    width: 274px;

    @media #{$breakpoint-between-mob-tablet} {
      width: auto;
    }
  }

  .small-subcategory-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-left: 20px;
    margin-top: 24px;
  }

  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    // z-index: 1;;

    .small-menu-product-card {
      position: relative;
      background: $white;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      width: 95%;
      height: 290px;
      padding: 15px 15px;
      margin-bottom: 15px;
      margin-right: 17px;
      margin-left: 18px;

      @media #{$breakpoint-below-tablet} {
        a {
          text-decoration: none !important;
        }
        .cardButtonOnly {
          justify-content: normal !important;
        }
        .quantityAddtocart {
          margin: 0px;
        }
      }

      .cardwithQuantity {
        margin-left: 0;
        width: 70%;
        //column-gap: 4%;
        @media #{$medium-only} {
          //column-gap: 30%;
        }
      }

      @media screen and (max-width: 1022px) {
        .cardwithQuantity.loggedIN {
          width: 28%;
        }
      }

      .small-menu-product-image {
        position: absolute;
        left: 57.59%;
        right: 3.82%;
        top: 4.91%;
        bottom: 65.28%;
        width: 123px;
        height: 93px;
        border-radius: 4px;

        @media #{$breakpoint-between-mob-tablet} {
          position: absolute;
          left: 68.59%;
          width: 28%;
          height: auto;
        }
      }

      .specials-product-ribbon {
        position: absolute;
        top: 2%;
        left: 11%;
        transform: scaleX(1.8);

        @media #{$breakpoint-between-mob-tablet} {
          left: 4%;
        }
      }

      .specials-ribbon-text {
        position: absolute;
        top: 3%;
        left: -3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;
      }

      .discount-product-ribbon {
        position: absolute;
        top: 3%;
        left: -8%;
        transform: scaleX(1.8);
        -webkit-transform: scaleX(1.9) !important;

        @media #{$breakpoint-between-mob-tablet} {
          left: -5%;
        }
      }

      .discount-ribbon-text {
        position: absolute;
        left: 0;
        top: 3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
      }

      .exclusive-product-ribbon {
        position: absolute;
        left: -2%;
        top: 3%;
        transform: scaleX(2.5);
      }

      .menu-product-save {
        position: absolute;
        left: 87.88%;
        //top: 37%;
        // right: 12.06%;
        // top: 83.51%;
        // bottom: 9.43%;
        cursor: pointer;
        z-index: 1;
        @media #{$breakpoint-below-tablet} {
          padding-top: 4px;
        }

        @media (min-width: 411px) and (max-width: 465px) {
          left: 84%;
        }
      }

      .menu-card-content {
        .menu-card-content-wrapper {
          @media #{$mobile-only} {
            width: 56% !important;
          }
        }
        .small-menu-product-header {
          // width: 199px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          font-family: $National2Regular;
          color: $Brand-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
          margin-bottom: 5px;

          @media #{$breakpoint-between-mob-tablet} {
            width: auto;
            margin-top: 5%;
          }
        }

        .small-menu-product-header-shift {
          // width: 199px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          font-family: $National2Regular;
          color: $Brand-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
          margin-top: 31px;

          @media #{$breakpoint-between-mob-tablet} {
            width: auto;
          }
        }

        .small-menu-vegOrNonVeg {
          display: inline-block;
          margin-left: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: $Dark-Gray;
          margin-bottom: 5px;

          @media #{$breakpoint-between-mob-tablet} {
            margin-bottom: 15px;
          }

          @include Dot();
        }

        .small-menu-product-price {
          font-style: normal;
          font-family: $National2Regular;
          color: $Brand-Black;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          @media #{$breakpoint-between-mob-tablet} {
            margin-bottom: 20px;
            margin-top: 25px;
          }
        }

        .small-menu-product-description {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
          margin-top: 5px;
          margin-bottom: 14px;
          width: 56%;
        }

        .PriceVegNonveg {
          display: flex;

          @media #{$breakpoint-between-mob-tablet} {
            margin-top: 25px;
          }
        }
        .hearticonDiv {
          padding-top: 4px;
        }
        .description-and-image {
          min-height: 70px;
        }
      }

      .small-menu-cart-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 44px;
        // position: absolute;
        // left: 4.41%;
        // right: 25%;
        // bottom: 5.66%;

        @media (min-width: 769px) and (max-width: 1022px) {
          width: 62%;
        }

        @media #{$breakpoint-between-mob-tablet} {
          width: 62%;
        }
      }
    }

    .small-menu-product-card-long {
      height: 330px;
    }

    .product-card-active {
      cursor: pointer;
      z-index: 50;

      @media #{$breakpoint-below-tablet} {
        text-decoration: none !important;
        z-index: 0;
      }
    }

    & .quantityAddtocart {
      .cardButtonOnly.loggedIN {
        @media (min-width: 342px) and (max-width: 1022px) {
          width: 89%;
          // margin-left: -42px;
        }

        @media (max-width: 340px) {
          width: 86%;
          left: -3%;
        }
      }
    }
  }

  .card-counter {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #848282;
  }
}

.Toastify__toast-container {
  bottom: 20% !important;
  text-align: center;
  width: 500px;
  @media #{$breakpoint-medium-desktop} {
    bottom: 27% !important;
  }
  @media #{$breakpoint-above-large-desktop} {
    bottom: 34% !important;
  }
  @media #{$breakpoint-below-tablet} {
    top: auto;
    width: 80%;
    padding: 11px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 12% !important;
    padding-left: 0px;
  }
  @media #{$breakpoint-samsung-galaxy-tab-s7} {
    top: 83% !important;
    left: 50% !important;
    width: 26.5% !important;
    height: 62px !important;
    padding: 0px !important;
  }
}

.mob-plp-sections {
  margin-right: auto !important;
  margin-left: auto !important;
  .percent-daily-text-mobile {
    font-size: 12px;
    line-height: 22px;
    font-family: $National2Regular;
  }
  .mob-plp-col {
    padding-left: auto !important;
    padding-right: auto !important;
    overflow-x: hidden;
    flex-basis: 100%;

    .carousal-menu-container {
      background-color: $white;

      .product-view-section {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        margin-right: 20px;
        margin-top: 4vw;
        margin-bottom: 10px;

        .product-view {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          text-align: right;
          color: $Dark-Gray;
          opacity: 0.5;
        }

        .product-view-up-icon {
          margin-left: 10px;
        }

        .product-view-carousal-icon {
          margin-left: 10px;
        }
      }

      .product-view-divider {
        border-top: 1px solid #e3e3e3 !important;
        width: 100%;
        margin-bottom: 30px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: auto !important;
      }

      .small-category-name {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-top: 8%;
        margin-left: 20px;
        width: 274px;
      }

      .small-subcategory-name {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-left: 20px;
        margin-top: 24px;
      }

      .carousal-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        //margin-top: 100px;
        margin-left: 20px;
      }

      .card-counter {
        display: none;
        position: absolute;
        right: 6%;
        bottom: 0;
        color: #848282;
      }

      .carousal-subcategory-name {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 24px;
        margin-left: 20px;
      }

      .product-container {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;

        .slick-list {
          width: 100vw !important;
          padding: 0 20% 0 0 !important;
          overflow: unset;

          .slick-slide {
            .carousal-menu-product-card {
              position: relative;
              margin-left: 0.625rem;
              background: $white;
              box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              height: 469px;
              width: 300px;
              max-width: 90%;

              @media #{$breakpoint-tablet-only} {
                min-width: 90%;
              }

              a {
                text-decoration: none !important;
              }
              .PriceVegNonveg {
                display: flex;
                margin-top: 12px;
                .carousal-menu-vegOrNonVeg {
                  margin-top: 6px;
                  @media #{$breakpoint-below-tablet} {
                    margin-left: 13px !important;
                  }
                }
              }
              .hearticonDiv {
                padding-top: 7px;
              }
              .carousal-menu-product-image {
                height: 225px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                max-width: 100%;

                @media #{$breakpoint-tablet-only} {
                  min-width: 100%;
                }
              }

              .specials-product-ribbon {
                position: absolute;
                top: 2%;
                left: 14%;
                transform: scaleX(2);
              }

              .specials-ribbon-text {
                position: absolute;
                top: 3%;
                left: 0;
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: $white;
              }

              .discount-product-ribbon {
                position: absolute;
                top: 3%;
                left: -10%;
                transform: scaleX(1.5);
                -webkit-transform: scaleX(1.9) !important;
                z-index: 2;
              }

              .discount-ribbon-text {
                position: absolute;
                left: 0;
                top: 3%;
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $Brand-Red;
                z-index: 3;
              }

              .exclusive-product-ribbon {
                position: absolute;
                left: -3%;
                top: 3%;
                transform: scaleX(2.5);
                z-index: 1;
              }

              .menu-product-save {
                position: absolute;
                left: 88.67%;
                cursor: pointer;
                z-index: 1;
                @media #{$breakpoint-below-tablet} {
                  margin-top: -20px;
                }
                @media #{$breakpoint-above-tablet} {
                  right: 3.33%;
                  top: 2.05%;
                  bottom: 93.03%;
                }
              }

              .menu-card-content {
                //margin-left: 15px;
                //margin-right: 15px;
                .menu-card-content-wrapper {
                  padding: 15px;
                }

                .carousal-menu-product-header {
                  font-family: $National2Regular;
                  color: $Brand-Black;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  margin-top: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1; /* number of lines to show */
                  -webkit-box-orient: vertical;
                }

                .menu-vegOrNonVeg-icon {
                  display: inline-block;
                }

                .carousal-menu-vegOrNonVeg {
                  display: inline-block;
                  font-family: $National2Regular;
                  color: $Dark-Gray;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 22px;
                  margin-left: 7px;

                  @include Dot();
                }

                .carousal-menu-product-price,
                .medium-menu-product-price {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: $Brand-Black;
                  margin-top: 5px;
                }

                .carousal-menu-product-description,
                .small-menu-product-description {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: $Dark-Gray;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3; /* number of lines to show */
                  -webkit-box-orient: vertical;
                  margin-top: 5px;
                }
              }

              .carousal-menu-cart-button {
                //margin-top: 34px;
                display: flex;
                justify-content: center;
                width: 100%;
                // position: absolute;
                // left: 5%;
                // right: 5%;
                // bottom: 1rem;
                @media screen and (max-width: 363px) {
                  padding: 5%;
                }

                @media #{$breakpoint-below-tablet} {
                  width: 80%;
                }
              }

              .quantityAddtocart.loggedIN {
                & .cardButton.loggedIN {
                  right: 8%;
                  width: 48%;
                }
              }
            }
            .small-menu-product-card {
              height: 469px;
            }
            .product-card-active {
              cursor: pointer;
              z-index: 50;

              @media #{$breakpoint-below-tablet} {
                text-decoration: none !important;
              }
            }

            @media #{$breakpoint-below-tablet} {
              .cardButtonOnly {
                justify-content: normal !important;
              }
            }
          }
        }
      }
    }

    .recommended-menu-container {
      border-top: none;

      .carousal-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 6px;
      }

      .product-container {
        display: flex;
        flex-wrap: initial;
        padding: 0 20px 0 10px;
        overflow: scroll;
        overflow-y: hidden;
        margin-top: 50px;

        @media #{$breakpoint-below-tablet} {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 30px;
        }

        .medium-menu-product-card {
          position: relative;
          height: 475px;
          font-family: $National2Regular;
          background: $white;
          box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          margin-right: 20px;
          margin-bottom: 50px;

          @media #{$breakpoint-below-tablet} {
            position: relative;
            background: $white;
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            width: 94%;
            height: 290px;
            padding: 15px 15px;
            margin-bottom: 15px;
            margin-right: 17px;
            margin-left: 18px;
          }

          .medium-menu-product-image {
            width: 100%;
            height: 210px;
            margin-bottom: 10px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            @media #{$breakpoint-below-tablet} {
              position: absolute;
              left: 65.59%;
              right: 3.82%;
              top: 4.91%;
              bottom: 65.28%;
              width: 100px;
              height: 75px;
              border-radius: 4px;
            }
          }

          .specials-product-ribbon {
            position: absolute;
            top: 3%;
            left: 6%;
            transform: scaleX(1.5);

            @media #{$breakpoint-below-tablet} {
              position: absolute;
              left: 5%;
              transform: scaleX(1.5);
            }
          }

          .specials-ribbon-text {
            position: absolute;
            top: 4%;
            left: 0%;
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $white;

            @media #{$breakpoint-below-tablet} {
              position: absolute;
              top: 4%;
              left: 0%;
            }
          }

          .discount-product-ribbon {
            position: absolute;
            top: 3%;
            left: -12%;
            transform: scaleX(1.5);
            -webkit-transform: scaleX(1.9) !important;
          }

          .discount-ribbon-text {
            position: absolute;
            left: 0;
            top: 3%;
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $Brand-Red;
          }

          .exclusive-product-ribbon {
            position: absolute;
            top: 3%;
            left: -3%;
            transform: scaleX(2.5);
          }

          .menu-product-save {
            position: absolute;
            left: 87.86%;
            right: 3.57%;
            top: 2.11%;
            bottom: 92.84%;
            cursor: pointer;
            z-index: 10;

            @media #{$breakpoint-below-tablet} {
              position: absolute;
              left: 78.88%;
              right: 12.06%;
              top: 82.51%;
              bottom: 9.43%;
              cursor: pointer;
            }
          }

          .menu-card-content {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 220px;

            @media #{$breakpoint-below-tablet} {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: auto;
            }

            .medium-menu-product-header {
              font-family: $National2Regular;
              font-style: normal;
              font-size: 16px;
              line-height: 24px;
              color: $Brand-Black;
              margin-bottom: 5px;

              @media #{$breakpoint-below-tablet} {
                width: 199px;
                font-family: $National2Regular;
                color: $Brand-Black;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
            }

            .medium-menu-vegOrNonVeg {
              display: inline-block;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              margin-bottom: 5px;
              color: $Dark-Gray;
              margin-left: 7px;

              @include Dot();
            }

            .medium-menu-product-price {
              font-family: $National2Regular;
              color: $Brand-Black;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 5px;

              @include Dot();
            }

            .medium-menu-product-description {
              font-style: normal;
              font-weight: normal;
              font-family: $National2Regular;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Dark-Gray;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              -webkit-box-orient: vertical;
            }

            .medium-menu-cart-button {
              display: flex;
              justify-content: center;
              width: 80%;
              height: 44px;
              margin-top: 10px;
              position: absolute;
              left: 10%;
              right: 10%;
              bottom: 1rem;

              @media #{$breakpoint-below-tablet} {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 4.41%;
                right: 25%;
                bottom: 5.66%;
                width: 68%;
              }
            }
          }
        }

        .product-card-active {
          cursor: pointer;
          z-index: 50;

          @media #{$breakpoint-below-tablet} {
            text-decoration: none !important;
          }
        }
      }
    }
    .small-menu-container {
      .product-container {
        .product-columnview {
          display: flex;
          .description-and-image {
            width: 44%;
            order: 2;
            padding: 0;
            .small-menu-product-image {
              width: 100%;
              margin: 0 !important;
              padding-top: 5px;
            }
          }
          .small-menu-product-header {
            padding: 0;
          }
          .small-menu-product-description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            width: 100%;
          }
          .medium-menu-product-price {
            overflow: visible;
            display: flex;
            width: 130%;
          }
        }
      }
    }
    .dynamic-menu-time-mob {
      margin-left: 20px;
    }
  }

  .mob-plp-promo-section {
    padding: 0;
  }
  .info-content-mobile {
    height: auto;
    left: 20px;
    font-family: $National2Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.10000000149011612px;
    text-align: left;
    color: #494949;
    border-bottom: solid 1px #e1e1e1;
    padding-bottom: 20px;
    padding-top: 20px;
    div {
      border-bottom: solid 1px #e1e1e1;
      padding-bottom: 20px;
      padding-top: 20px;
      line-height: 24px;
      color: #494949;
      p {
        margin: 20px 0;
      }
    }
  }
  .mob-disclimer-content {
    margin: 0px 20px 60px 20px;
  }
  #lastElement-disclimer {
    border-bottom: none;
  }

  &.margin-top-mob-plp-sections {
    margin-top: 70px;
  }
}

.plp-promo-section {
  margin-bottom: 50px;
  @media #{$breakpoint-below-tablet} {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

.promo-section-container {
  margin-top: 47px !important;
  @media #{$breakpoint-below-tablet} {
    margin-top: 55px !important;
  }
}

.plp-promo-non-localized {
  margin-top: 47px !important;
}

.toast-addToCart {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;

  @media #{$breakpoint-below-tablet} {
    width: 223px !important;
    margin: 0 11.5% !important;
  }
}

.add-to-toast {
  & .toast-Container {
    text-align: center;
    padding: 8px 2px 8px 2px !important;
    display: flex;
    justify-content: center;
    vertical-align: middle;

    @media #{$breakpoint-below-tablet} {
      display: block;
      padding: 16px 0px !important;
      p {
        padding-top: 0px !important;
        margin-bottom: 2px !important;
      }
      .toast-button {
        margin-left: 0px !important;
        margin-top: 25px !important;
      }
    }

    p {
      padding-top: 10px;
    }

    & .toast-button {
      padding: 5px !important;
      margin-left: 18px;
      margin-top: 7px;
      width: 128px;
      height: 34px;
    }

    & .text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #202124;
      font-family: $National2Regular;
      padding-right: 7px;
    }
  }
}

.edit-selection {
  cursor: pointer;
  text-decoration-line: underline;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  bottom: 10px;
  position: relative;
  float: right;
}

.add-to-cart-Icon {
  padding-left: 10px;
  display: inline-block !important;
}
.indulgent-card-button {
  img {
    display: inline-block !important;
  }
}
/*
.alert-action-container {
	button {
		width: 138px;
	}
}
*/
.no-store-selected,
.store-closed {
  background-color: $Inactive-Gray;
  color: $Brand-Black;
  border: none;

  &:hover {
    background-color: $Light-Gray;
  }

  &:focus {
    outline: none;
  }
}

.individual-card-counter {
  position: relative;
  top: -56px;
  left: 88%;
  width: 0;
  height: 0;
}

.base-price-menu {
  text-decoration: line-through;
  margin-right: 0.4rem;
}

.price-display {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.availableprice-withbaseprice {
  color: $Red-Orange;
}

.plp-warning-msg {
  .modal-content {
    height: 375px !important;
    .modal-body .alert-title {
      width: 85%;
    }
    @media #{$breakpoint-below-tablet} {
      height: 410px !important;
    }
  }
}

.plp-page-model {
  .alert-content {
    min-width: 275px !important;
  }
}
.product-slider {
  .medium-menu-product-card {
    width: 93% !important;
  }
  .slick-next {
    right: 62px;
    height: 120px;
  }
  .slick-next::before {
    font-family: unset;
    font-size: 36px;
    content: '›';
    color: #202124;
    background-color: white;
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 66px;
  }
  .slick-prev {
    top: 33%;
    left: 17px !important;
    z-index: 1;
  }
  .slick-prev::before {
    font-family: unset;
    font-size: 36px;
    content: '‹';
    color: #202124;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
}
.product-slider-container {
  display: flex;
  flex-direction: column;
}
