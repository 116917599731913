.cart-module-modal {
  & .modal-content {
    width: 45%;
    height: 70%;
    left: 30%;
    top: 15%;
    background: $white;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    @media #{$breakpoint-below-tablet} {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    & .modal-header {
      padding: 4% 1%;
      border-bottom: none;

      @media #{$breakpoint-below-tablet} {
        padding: 7% 1%;
        border-radius: 0;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 22px;
        border-bottom: 1px solid #dbdbdb;
      }
    }

    & .modal-body {
      box-shadow: none;
      background: $white;
    }
  }
}

.cart-module-form {
  margin: 0 9% 0 9%;
  text-align: $center;

  @media #{$breakpoint-below-tablet} {
    margin: 4% 3%;
  }

  & .content-text {
    padding-left: 6%;
    padding-right: 6%;
    text-align: $center;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;

    & .red-text {
      color: $Red-Orange;
    }

    @media #{$breakpoint-below-tablet} {
      padding: 0;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      //width: 66%;
      margin: auto;
    }
  }

  & .info-text {
    margin-top: 5%;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;

    @media #{$breakpoint-below-tablet} {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }

  & .order-button-section {
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;

    @media #{$breakpoint-below-tablet} {
      display: block;
      max-width: 296px;
      margin: 11% auto;
    }
  }

  & .order-type-button {
    margin-top: 5%;
    width: 45%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: $National2Regular;
    background-color: transparent;
    border: 1.5px solid $Brand-Black;

    @media #{$breakpoint-below-tablet} {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$breakpoint-iphone-ios15} {
      padding: 11px 0;
    }
  }

  .cart-card-row {
    .cart-card {
      border-radius: 3px;
      margin: 5% 0%;
      //height: 16vh;
      display: flex !important;
      flex-direction: row;
      width: 100%;
      padding: 0 12%;

      & .cart-card-name {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        padding-left: 3%;
        margin: 2%;

        & .cart-item-dname {
          display: flex;
          text-align: left;
          margin-bottom: 2%;
        }

        & .cart-item-price {
          font-family: $National2Regular;
          display: flex;
          column-gap: 5%;

          & .modified-price {
            color: $Red-Orange;
          }

          & .old-price {
            text-decoration: line-through;
          }
        }
      }

      & .cart-card-image {
        width: 37%;
        //height: 75%;
        border-radius: 5px;
      }
    }
  }
}
