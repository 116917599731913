.loyalty-crm {
  position: relative;
  width: 100%;
  background-color: $Mashed-Potato-Gray;

  @media #{$breakpoint-below-desktop} {
    height: 511px;
  }

  @media #{$breakpoint-below-tablet} {
    background-color: $Mashed-Potato-Gray;
  }

  & .loyalty-img {
    width: 100%;
    height: 520px;
    object-fit: contain;

    @media #{$breakpoint-below-desktop} {
      object-fit: cover;
    }

    @media #{$breakpoint-below-tablet} {
      height: 511px;
      object-fit: contain;
    }
  }

  & .loyalty-content {
    position: absolute;
    left: 15.97%;
    right: 50.69%;
    top: 21.15%;

    @media #{$breakpoint-below-tablet} {
      align-items: center;
      right: 5.33%;
      top: 12.92%;
      left: 5.33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    & .loyalty-headline {
      width: 114%;
      min-width: 480px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 64px;
      line-height: 76px;
      color: $Brand-Black;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media #{$breakpoint-below-tablet} {
        width: auto;
        min-width: 335px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 36px;
        line-height: 36px;
      }
    }

    & .loyalty-subheadline {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $Dark-Gray;
      display: flex;
      align-items: center;
      margin-top: 2.5%;
      letter-spacing: -0.1px;
      // color:$Dark-Gray;
      opacity: 0.8;

      @media #{$breakpoint-below-tablet} {
        margin-left: 55px;
        margin-right: 55px;
        text-align: center;
        margin-top: 3%;
      }
    }

    & .loyalty-email {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-top: 7.3%;

      @media #{$breakpoint-below-tablet} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }

      & .loyalty-mailbox {
        margin-right: 2.5%;

        @media #{$breakpoint-below-tablet} {
          margin-right: auto;
        }

        & input {
          width: auto;
          min-width: 322px;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid $Brand-Black;
          background-color: transparent;
          padding-bottom: 4.5%;

          @media #{$breakpoint-below-tablet} {
            width: auto;
            min-width: 265px;
          }
        }

        & input:focus,
        :active {
          outline: none;
        }

        ::placeholder {
          /* Firefox */
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          color: $Brand-Black;
          letter-spacing: -0.1px;
          font-size: 18px;
          line-height: 30px;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 & Edge */
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 30px;
          color: $Brand-Black;
        }
      }

      & .loyalty-button {
        @media #{$breakpoint-below-tablet} {
          width: fit-content;
          margin-top: 20px;
        }

        &:hover {
          background-color: $black;
        }
      }
    }
  }
}
