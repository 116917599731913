.deals-offers-header {
  .deals-offers-header-icon {
    height: 20px;

    @media #{$breakpoint-below-tablet} {
      width: fit-content;
      margin: auto;
    }

    .deals-stripe-icon {
      vertical-align: super !important;
    }
  }

  .deals-header-text-container {
    display: $d-flex;
    justify-content: space-between;

    @media #{$breakpoint-below-tablet} {
      flex-direction: column;
      margin-top: 30px;
    }

    color: $Brand-Black;
    margin-top: 55px;
    margin-bottom: 30px;
    font-style: normal;

    .header-text {
      margin: auto 0;
      font-family: $National2Condensed;
      font-weight: $font-weight-bold;
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 1px;
      text-transform: $uppercase;

      @media #{$breakpoint-below-tablet} {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }

    .login-text {
      margin: auto 0;
      font-family: $National2Regular;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;

      @media #{$breakpoint-below-tablet} {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        margin: 20px auto 0 auto;
      }
    }
  }
}
