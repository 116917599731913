.banner {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-family: $National2Condensed;
  background-color: $Brand-Black;
  border-radius: 8px;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;

  @media #{$breakpoint-above-mobile} {
    flex-direction: row;
  }

  &--carousel {
    position: relative;

    &-indicators {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 16px;
      font-family: $National2Regular;
      font-size: 14px;
      font-weight: $font-weight-500;
      list-style: none;

      @media #{$breakpoint-above-tablet} {
        gap: 8px;
        position: relative;
        margin-top: 24px;
      }
    }

    &-indicator {
      padding: 4px;

      button {
        background-color: transparent;
        border: none;
        border-radius: 30px;
        color: $Brand-Black;
        cursor: pointer;
        height: 24px;
        width: 24px;

        @media #{$breakpoint-above-tablet} {
          height: 40px;
          width: 40px;
        }
      }

      &.active {
        button {
          background-color: $Brand-Black;
          color: $white;
        }
      }
    }
  }

  &--info {
    flex: 1;

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-left: clamp(17px, 5vw, 96px);
      padding-right: clamp(17px, 5vw, 96px);

      @media #{$mobile-only} {
        padding: 30px 16px 8px 16px;
      }
    }
  }

  &--tags {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &--pricing {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 700;
    gap: 8px;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 8px;

    @media #{$breakpoint-above-tablet} {
      font-size: 28px;
      margin-bottom: 16px;
    }
  }

  &--reward {
    width: 70px;

    @media #{$breakpoint-above-tablet} {
      width: 80px;
    }

    img {
      width: 100%;
    }
  }

  &--image-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    @media #{$breakpoint-above-mobile} {
      width: 51vw;
      max-width: 596px;
    }

    @media #{$breakpoint-above-tablet} {
      width: 100%;
    }

    img {
      width: 100%;

      @media #{$breakpoint-below-tablet} {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &--background-image {
    height: 100%;
  }

  &--image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      object-fit: contain;
      height: 100%;
    }
  }

  &--cta {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
  }

  &--enddate {
    font-family: $National2Regular;
    font-weight: $font-weight-normal;
    letter-spacing: -0.1px;
  }

  &--title {
    color: $white;
    font-family: $National2Condensed;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 16px;
    overflow-wrap: break-word;
    text-transform: uppercase;
    word-break: break-word;

    @media #{$breakpoint-above-tablet} {
      font-size: 40px;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }

  &--tag {
    border-radius: 4px;
    font-size: 16px;
    line-height: 1;
    padding: 4px 8px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media #{$breakpoint-above-tablet} {
      margin-bottom: 24px;
    }

    &-product {
      background-color: $Brand-Red;
    }

    &-disposition {
      background-color: transparent;
      border: 1px solid $white;
    }
  }

  &--active-label {
    align-items: center;
    background: $light-green;
    border-radius: 4px;
    color: $Brand-Black;
    display: flex;
    font-family: $National2Regular;
    font-weight: $font-weight-500;
    font-size: 14px;
    line-height: 1.5;
    margin: 8px 0 0;
    padding: 4px 8px;
    width: max-content;

    &_icon {
      display: inline-block;
      height: 16px;
      margin-right: 3px;
      width: 16px;
    }
  }
}
