@mixin viewMenuLink {
  .view_menu_link {
    position: absolute;
    display: flex;
    background: #fff;
    margin: 0.625rem;
    border-radius: 1.25rem;
    padding: 0.25rem 0.625rem;
    box-shadow: 0 3px 6px #544a4a;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    label.view_menu {
      margin: 0.25rem;
      cursor: pointer;
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $National2Condensed;
      font-size: medium;
    }

    .grid-icon {
      margin: 0.25rem;
    }

    .grid-icon:hover > span > span {
      opacity: 0.8;
    }

    .grid-icon > .layer {
      width: 100%;
      position: absolute;
      left: 0;
    }

    // icon designs
    .grid-icon--line2 {
      position: relative;
      width: 1rem;
      height: 1rem;
    }

    .grid-icon--line2 > .layer {
      height: 0.5rem;
    }

    .grid-icon--line2 > .layer--primary {
      top: 0;
    }

    .grid-icon--line2 > .layer--secondary {
      bottom: 0;
    }

    .grid-icon--line2 > .layer > span {
      position: absolute;
      display: block;
      width: 0.5rem;
      height: 100%;
      box-sizing: border-box;
      border: solid 1px #fff;
      background-color: rgb(255, 0, 0);
    }

    .grid-icon--line2 > .layer > span:first-child {
      left: 0;
    }

    .grid-icon--line2 > .layer > span:nth-child(2) {
      right: 0;
    }
  }
}

@mixin floatingMenu($top) {
  .floating {
    position: fixed;
    top: $top;
    z-index: 9;
  }
}

@mixin mainImage() {
  width: 90% !important;
  height: 100%;
  margin: auto auto;
  display: block;
}

@mixin item_details {
  .item_details {
    & > label {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-right: 5px;
    }

    & > span {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
    }
  }
}

@mixin anchorStyle {
  color: $Dark-Gray;
  font-weight: 400;
  &:hover {
    color: $Dark-Gray;
  }
}

.pdp-container {
  background-color: $Mashed-Potato-Gray;
  padding-top: 40px;
  .pdp-navigation {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $Dark-Gray;
    width: 85%;
    margin: auto;
    margin-bottom: 2%;
  }

  .pdp-details-container,
  .pdp-details-container-sopac {
    margin-bottom: 5.5%;

    & > div {
      margin: auto;

      .image-container {
        .pdp-product-image {
          width: 100%;
          object-fit: contain;
        }

        @include viewMenuLink();
      }

      .pdp-receipt-card {
        position: absolute;
        z-index: 9;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        @media (max-width: 1023px) {
          position: relative;
        }
        @media #{$breakpoint-above-destop-below-large-desktop} {
          position: absolute;
        }
        .pdp-receipt-form {
          margin: 40px 40px;

          .pdp-receipt-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
            line-height: 43px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $black;
            margin-bottom: 6px;
          }

          .pdp-receipt-veg {
            display: inline;
            margin-left: 7px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
          }

          .pdp-receipt-list {
            border-top: 1px solid $Light-Gray;
            border-bottom: 1px solid $Light-Gray;
            margin-top: 20px;
            padding-top: 20px;
            padding-bottom: 8px;
            width: 100%;

            .pdp-receipt-modGrp-container {
              margin-bottom: 12px;

              &.activeGroup {
                position: relative;

                .arrowed {
                  position: absolute;
                  width: 1rem;
                  height: 1rem;
                  border-top: 0.5rem solid transparent;
                  border-bottom: 0.5rem solid transparent;
                  border-left: 0.5rem solid $Brand-Red;
                  left: -1.5rem;
                  top: 0.4rem;
                }
              }

              .pdp-receipt-modgrp {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.1px;
                color: $Dark-Gray;
                margin-right: 5px;
              }

              .pdp-receipt-modifiers {
                font-family: $National2Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $Brand-Black;
              }

              .pdp-summary-edit-icon {
                transform: scale(0.7);
                cursor: pointer;
              }
            }

            @include item_details();
          }

          .pdp-cart-button,
          .pdp-combo-button {
            display: flex;
            justify-content: space-between;
            margin-top: 1.25rem;
            width: 100%;
          }
          .pdp-cart-button-sopac {
            padding: 11px 20px !important;
            &.pdp-button-disabled {
              color: $Dark-Gray !important;
              font-weight: bold !important;
              border: none !important;
              background: $Inactive-Gray !important;
              &:active,
              &:hover {
                border: none !important;
                color: $Dark-Gray !important;
                cursor: not-allowed !important;
                background: $Inactive-Gray !important;
              }
            }
          }

          .pdp-cart-button {
            .outofstock {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              font-weight: bold;
              font-size: initial;
            }
          }

          .pdp-combo-button span {
            text-align: center;
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
  .pdp-details-container {
    & > div {
      width: 85%;
      .pdp-receipt-card {
        width: 27.5%;
        right: 7.5%;
      }
      .image-container {
        width: 65%;
        @include floatingMenu($top: 100px);
      }
    }
  }
  .pdp-details-container-sopac {
    & > div {
      //width: 83%;
      .pdp-receipt-card:has(.sopac-upsize > .modifier-size:nth-child(1)) {
        @media #{$breakpoint-above-tablet} {
          position: absolute;
        }
      }
      .pdp-receipt-card {
        margin-left: 764px;
        width: 416px;
        position: absolute;
        @media (max-width: 1023px) {
          margin-left: 1.25rem;
          width: 28.5%;
        }
        @media #{$breakpoint-ipad-12-pro-portrait} {
          right: 30px;
          width: 330px;
        }
        @media #{$breakpoint-above-tablet} {
          position: absolute;
        }
        /*
				width: 28.5%;
				right: 9.8%;
				@media #{$breakpoint-above-medium-desktop}{
					right: 8.2%;
				}
				@media #{$breakpoint-above-destop-wide}{
					right: 8.8%;
				}
				@media #{$breakpoint-below-desktop-above-tablet}{
					right: 7.1%;
				}
				@media #{$breakpoint-below-medium-desktop} {
					right: 8.1%;
				}
				*/
        .button-contained {
          font-weight: 500;
          font-family: $National2Medium;
          line-height: 24px;
          padding-top: 10px;
        }
      }
      .image-container {
        width: 63%;
        min-height: 550px;
        border-radius: 8px;
        @include backgroundImage();
        @include floatingMenu($top: 168px);
        img {
          @include mainImage();
          @include imageAnimation;
          @media #{$mobile-only} {
            width: 100%;
          }
          @media #{$breakpoint-above-tablet} {
            &:hover {
              transform: scale(1);
              transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
            }
          }
        }
      }
    }
  }
  .pdp-legal-info-container {
    margin-top: -2% !important;
    & > div {
      @media #{$breakpoint-above-tablet} {
        //max-width: 83%;
      }
      margin: auto;
    }
  }
  .pdp-mobile-legal-info-container {
    margin-top: -8% !important;
  }
  .legal-info-desc {
    padding-top: 30px;
    padding-bottom: 68px;
    width: 65%;
    border-top: 1px solid $Inactive-Gray;
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      padding-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media #{$breakpoint-above-medium-tablet} {
      width: 63%;
    }
  }
  .pdp-desc-container,
  .pdp-desc-container-sopac {
    background-color: #fff;
    @media #{$breakpoint-below-tablet} {
      padding-bottom: 2%;
    }

    & > div {
      margin: auto;
      @media #{$breakpoint-below-tablet} {
        border-bottom: 1px solid $Light-Gray;
        padding-left: 20px;
        padding-right: 20px;
      }
      padding-bottom: 35px;
      padding-right: 35px;

      & > img {
        display: block;
        margin-bottom: 2rem;
      }
    }
    &.pdp-desc-container-in {
      & > div {
        margin: auto;
        max-width: 85%;
        @media #{$breakpoint-below-tablet} {
          border-bottom: 1px solid $Light-Gray;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }
    .stripes-img {
      margin-left: -1.9% !important;
      &.stripes-img-in {
        margin-left: 0 !important;
      }
      @media #{$mobile-only} {
        margin-left: -2.3% !important;
      }
      @media #{$breakpoint-tablet-only} {
        margin-left: -0.9% !important;
      }
    }

    &.mobile {
      // margin-left: 20px;

      .pdp-desc-header {
        display: block;
        margin-top: 25px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
      }
      .pdp-desc {
        width: 100%;
        // margin-top: 2rem;
        .mobile-desc {
          margin-bottom: 13px;
          margin-right: 7.5%;
        }
        .mobile-desc-text {
          margin-right: 7.5%;
          @media #{$mobile-only} {
            margin-right: 0;
          }
        }
        .desc-box {
          .readMoreLess {
            color: red;
            font-weight: bold;
            padding-left: 0.25rem;
          }
        }
        .sopac-quantity-conatiner {
          display: flex;
          .cardwithQuantity {
            width: 30%;
            margin-left: 0px;
          }
          .cart-food-quantity-change {
            bottom: 0px;
            position: relative;
          }
          .redButton {
            margin: 15px 0px;
            width: 70%;
            height: 56px;
          }
        }
      }
    }

    .pdp-desc {
      width: 65%;
    }

    .pdp-desc-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 58px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .desc-box {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      margin-top: 2px;
    }
    &.desc-in {
      .desc-box {
        margin-top: 20px !important;
      }
    }
  }
  .pdp-desc-container {
    & > div {
      @media #{$breakpoint-above-tablet} {
        max-width: 85%;
        padding-bottom: 2%;
      }
      @media #{$breakpoint-below-tablet} {
        max-width: 85%;
        padding-bottom: 2%;
      }
    }
  }
  .pdp-desc-container-sopac {
    .desc-box {
      margin-top: 15px;
    }
    & > div {
      @media #{$breakpoint-above-tablet} {
        //max-width: 83%;
      }
    }
  }

  .pdp-custm-container,
  .pdp-custm-container-sopac {
    background-color: #fff;
    padding-bottom: 2%;

    &.pdp-custm-container-in {
      & > div {
        max-width: 85%;
        margin: auto;
      }
    }

    & > div {
      margin: auto;
    }

    &.mobile {
      .pdp-custm,
      .pdp-custm-sopac {
        width: 100%;
        padding: unset;
      }
    }

    .pdp-custm,
    .pdp-custm-sopac {
      width: 65%;
      .accordian-container:first-child {
        @media #{$breakpoint-above-tablet} {
          border-top: 1px solid $Light-Gray;
          padding-top: 2%;
        }
      }
    }
  }
  .pdp-custm-container {
    & > div {
      @media #{$breakpoint-above-tablet} {
        //max-width: 85%;
      }
    }
  }
  .pdp-custm-container-sopac {
    & > div {
      @media #{$breakpoint-above-tablet} {
        //max-width: 83%;
      }
      @media #{$breakpoint-above-medium-tablet} {
        padding-right: 35px;
      }
    }
  }

  .pdp-carousal-header-container {
    padding-left: 7.5%;
  }
}

.pdp-receipt-card {
  .pdp-receipt-form {
    .pdp-summary-cart-notification {
      display: flex;
      flex-direction: column;
      @media #{$mobile-only} {
        margin: 0 5% 3% 0;
      }
      .notify-alert {
        background: $Alert-Yellow;
        color: $Brand-Black;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        border-radius: 4px;
        display: flex;
        padding: 15px;
        width: 100%;
        margin-bottom: 1%;

        @media #{$breakpoint-below-tablet} {
          width: 100%;
          padding: 15px;
        }

        & .location-alert-icon {
          width: 15px;
          height: 15px;
          margin: 4px 10px 4px 5px;

          @media #{$breakpoint-below-tablet} {
            margin: 4px 10px 4px 0;
          }
        }
      }
    }
    .price-kj-info {
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      color: $black;

      & .pdp-promos-container {
        margin-right: 10px;

        & .with-price {
          margin-bottom: 10px;
        }
        & .pdp-promo-img {
          vertical-align: baseline;
          width: 20px;
          height: 20px;
          margin-top: -3px;
          margin-right: 6.52px;
          @media #{$mobile-only} {
            margin-top: -8px;
          }
        }
        & .pdp-promo-text {
          margin-right: 10px;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Red;
        }
        .pdp-promo-img-price {
          vertical-align: baseline;
          width: 20px;
          height: 20px;
          margin-top: -3px;
          margin-bottom: -18px;
          @media #{$mobile-only} {
            margin-top: -8px;
          }
        }
      }

      .price-info {
        font-family: $National2Condensed;
        font-weight: bold;
        letter-spacing: 1px;
        margin-right: 10px;
      }

      .kj-css {
        display: block;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        color: #626365;
        margin-left: 0;
        margin-bottom: 6px;
        margin-top: 10px;
        letter-spacing: -1px;
      }
      .pdp-promo-text-price {
        margin-left: 26.52px;
        margin-right: 10px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
      }
      .price-info-price {
        font-family: $National2Condensed;
        font-weight: bold;
        letter-spacing: 1px;
        margin-right: 10px;
      }
    }
    .allergy-info {
      text-decoration: underline;
      text-decoration-skip-ink: none;
      margin-top: 10px;
      margin-bottom: 10px;
      width: fit-content;

      @media #{$mobile-only} {
        margin-top: 5px;
        margin-bottom: 20px;
      }
      a {
        color: $Brand-Black;
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.pdp-mobile-container {
  .pdp-mobile-image-container,
  .pdp-mobile-image-container-sopac {
    background-color: $Mashed-Potato-Gray;
    padding-bottom: 40px;

    .pdp-product-mobile-image {
      width: 100%;
      object-fit: cover;
      margin-bottom: 16px;
    }

    .pdp-mobile-navigation {
      margin-left: 20px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $navigation-grey;
    }

    .pdp-receipt-card {
      margin-top: 17px;
      margin-left: 20px;

      .pdp-receipt-header {
        width: 100%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 11px;
      }

      .pdp-cart-button-sopac {
        padding: 11px 20px !important;
        display: flex;
        justify-content: space-between;
        width: 30%;
        @media #{$mobile-only} {
          width: 60%;
        }
        &.pdp-button-disabled {
          color: $Dark-Gray !important;
          font-weight: bold !important;
          border: none !important;
          background: $Inactive-Gray !important;
          &:active,
          &:hover {
            border: none !important;
            color: $Dark-Gray !important;
            cursor: not-allowed !important;
            background: $Inactive-Gray !important;
          }
        }
      }
      .Add {
        @media #{$mobile-only} {
          width: 55%;
        }
      }

      .pdp-receipt-veg {
        display: inline;
        margin-left: 7px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $navigation-grey;
      }

      .pdp-receipt-list {
        display: none;
      }

      .pdp-receipt-modgrp {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        margin-right: 5px;
      }

      .pdp-receipt-modifiers {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Brand-Black;
      }
      @include item_details();
      .pdp-summary-edit-icon {
        transform: scale(0.7);
        cursor: pointer;
      }
    }

    @include viewMenuLink();
  }
  .pdp-mobile-image-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include floatingMenu($top: 100px);
    @media #{$mobile-only} {
      @include floatingMenu($top: 45px);
    }
  }
  .pdp-anchor-link {
    color: #202124 !important;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .pdp-mobile-image-container-sopac {
    @include floatingMenu($top: 168px);
    & .mobile-background-image-container {
      @include backgroundImage();
      height: 257px;
      margin-bottom: 16px;
      img {
        margin-bottom: 0px !important;
        @include mainImage();
        @include imageAnimation;
      }
    }
    @media #{$mobile-only} {
      @include floatingMenu($top: 145px);
      padding-bottom: 30px;
      .pdp-product-mobile-image {
        margin-bottom: 20px;
      }
      .pdp-mobile-navigation {
        color: $Dark-Gray;
      }
      .pdp-mobile-navigation-sopac {
        a {
          @include anchorStyle();
        }
      }
      .pdp-receipt-card {
        margin-top: 20px;
        .pdp-receipt-header {
          letter-spacing: 1px;
          color: $dark-gray-color;
          margin-bottom: 5px;
        }
        .price-kj-info {
          line-height: 32px;
        }
        .allergy-info {
          margin-bottom: 25px;
          a {
            font-weight: 400;
          }
        }
        .pdp-mobile-quantity-price-sopac {
          height: 96px;
          padding: 20px 20px 20px 20px;
          bottom: -1px;
          box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.05), 0px -2px 6px rgba(0, 0, 0, 0.04),
            0px 0px 1px rgba(0, 0, 0, 0.04);
          .button-contained {
            width: 75% !important;
            padding: 4.5% 1.4rem;
            align-items: center;
          }
        }
        .catering-button {
          .button-contained {
            width: 205px !important;
          }
        }
        .cart-food-quantity-change {
          .cart-food-quantity {
            margin: 0 16px;
          }
        }
        .pdp-cart-button-sopac {
          font-size: 16px;
          height: 56px;
          border-radius: 66.2px;
          padding: 16px 20px !important;
        }
      }
    }
  }
  .pdp-desc-container,
  .pdp-desc-container-sopac {
    margin-left: 20px;

    .pdp-desc-header {
      display: block;
      margin-top: 25px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .desc-box {
      margin-top: 2rem;

      .readMoreLess {
        color: red;
        font-weight: bold;
        padding-left: 0.25rem;
      }
    }
  }

  .pdp-carousal-container {
    width: 100%;
    height: 660px;
    overflow: hidden;
    background-color: #f8f7f5;
    padding-top: 50px;
    padding-left: 0;

    .pdp-carousal-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90vw !important;
      margin-left: 20px;

      .pdp-carousal-header {
        letter-spacing: 1px;
        font-size: 24px;
        line-height: 32px;
        padding-left: 0;

        .sub-header {
          display: block;
          font-size: 1rem;
        }
      }

      .pdp-carousal-count {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: $Dark-Gray;
        opacity: 0.7;
      }
    }

    .pdp-carousal-product-list {
      padding-left: 0;

      .slick-slider {
        .slick-track {
          left: 19px !important;

          .slick-slide {
            width: 300px !important;
            opacity: 0.25;

            &.slick-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.pdp-custm-container-sopac {
  @media #{$mobile-only} {
    .pdp-custm-sopac form {
      margin-top: 14px;
      .accordian-container {
        margin-bottom: 5%;
        padding-bottom: 16px;
        .accordion .badgeContainer {
          width: 80%;
        }
      }
    }
  }
}

.pdp-quantity-block {
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$breakpoint-below-tablet} {
    display: inline-block;
    width: 50%;
  }
  .quantity-input {
    width: 38px;
    height: 38px;
    border: 1px solid $Inactive-Gray;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }

  .qty {
    font-family: $National2Condensed;
    font-size: 1.2rem;
    margin: auto;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .mg-set {
    .cart-food-quantity-change {
      margin-top: unset;
      bottom: unset;
      position: relative;
      @media #{$breakpoint-below-tablet} {
        bottom: 16px;
        left: 8px;
      }
    }
    .cart-food-quantity-change-sopac {
      margin-bottom: 10px;
      @media #{$mobile-only} {
        margin-bottom: 0px;
      }
    }
  }

  .qty-calc {
    margin: auto;
    text-align: right;
    width: auto;
    .circle {
      padding: 1rem;
      border: 1px solid #4c4c4c;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.minus {
        margin-right: 1rem;

        &:disabled {
          cursor: not-allowed;
        }
      }

      &.plus {
        margin-left: 1rem;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .prod-quantity {
      font-weight: bold;
    }
  }
}
.pdp-quantity-block-sopac {
  .mg-set {
    .cart-food-quantity-change {
      position: relative;
      @media #{$breakpoint-below-tablet} {
        bottom: 0px !important;
      }
    }
  }
}

.pdp-mobile-quantity-price,
.pdp-mobile-quantity-price-sopac {
  display: flex;
  position: fixed;
  background: white;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 1;
  align-items: center;
}
.pdp-mobile-quantity-price {
  justify-content: space-evenly;
  padding: 2%;
}
.pdp-mobile-quantity-price-sopac {
  justify-content: space-between;
  padding: 1% 6%;
}

.disable-icon {
  border: 1px solid $Light-Gray;
}

.sopac-upsize {
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: 20px 20px 0px 0px;
  & .modifier-size {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    border-top: 1px solid $Inactive-Gray;
    & .size-info {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;

      & .size-title {
        font-family: $National2Medium;
        font-weight: 500;
        color: $Brand-Black;
        @media #{$mobile-only} {
          font-weight: 400;
        }
      }
      & .size-desc {
        font-family: $National2Regular;
        font-weight: normal;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
      }
      & .size-desc-price {
        font-weight: bold;
      }
      & .size-desc-cal {
        margin-left: 5px;
        &::before {
          content: '•';
        }
      }
    }
    picture {
      cursor: pointer;
    }
  }
  & .modifier-size:last-child {
    border-bottom: 1px solid $Inactive-Gray;
  }
}
.savedCustomizeClearAlert-modal {
  position: fixed;
  bottom: 42%;
  left: 30%;
  z-index: 1000000;
  width: 50%;
  @media #{$breakpoint-above-desktop} {
    left: 41% !important;
  }
  @media #{$breakpoint-below-desktop-above-tablet} {
    @media #{$breakpoint-above-destop-wide-height} {
      bottom: 24%;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
      bottom: 34%;
    }
  }
  @media #{$breakpoint-above-destop-low-height} {
    bottom: 30%;
  }

  @media #{$breakpoint-below-tablet} {
    bottom: 23%;
    left: 15%;
    width: 90%;
  }

  .modal-content {
    width: 60% !important;
    background-color: $Alert-Yellow;
    height: 78px !important;
    @media #{$breakpoint-below-tablet} {
      width: 80% !important;
    }
    .img-text-div {
      display: flex;
      margin: auto;
      margin-left: 0;
      .text {
        margin: 4% 4% 0% 6%;
        color: $Brand-Black;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        .titleImg-container {
          display: flex;
          .error-image {
            height: 15px;
            margin-top: 2px;
            margin-right: 4%;
            @media #{$breakpoint-desktop-small} {
              margin-top: 4px;
            }
            @media #{$mobile-only} {
              margin-top: 5px;
            }
            @media #{$breakpoint-below-tablet} {
              margin-top: 2px;
            }
          }
          p {
            white-space: nowrap;
            margin-bottom: 2px;
          }
        }
        .dismiss {
          text-decoration: underline;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-weight: bold;
          color: $Brand-Black;
          margin-left: 17px;
        }
      }
      .custom-modal-close {
        display: flex;
        background-color: transparent;
        position: absolute;
        right: 2%;
        border: none;
        cursor: pointer;
        @media #{$breakpoint-desktop-small} {
          padding-top: 10px;
          padding-right: 13px;
        }
        @media #{$mobile-only} {
          padding-top: 11px;
          padding-right: 13px;
        }
        @media #{$breakpoint-below-tablet} {
          padding-top: 11px;
          padding-right: 13px;
        }
        img {
          width: 16px;
        }
      }
    }
  }
}

.item-unavailable {
  .modal-content {
    height: 433px !important;
    @media #{$breakpoint-iphone12-size} {
      bottom: 0% !important;
      top: unset !important;
    }
    .alert-content {
      padding-top: 15px !important;
    }
    .alert-title {
      padding: 0% 20% !important;
      @media #{$breakpoint-below-tablet} {
        padding: 0% 10% !important;
      }
    }
    .alert-action-container {
      margin-top: 30px !important;
      margin-bottom: 40px;
    }
  }
}
.no-results-section {
  min-height: 100vh;
}
.pdp-legal-info-container,
.pdp-mobile-legal-info-container {
  background-color: $white;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $Dark-Gray;
}

.pdp-container-sopac {
  padding-top: 0px !important;
  .pdp-navigation {
    //width: 83% !important;
    width: 100% !important;
    @media #{$breakpoint-above-medium-tablet} {
      padding-top: 42px;
      margin-bottom: 27px;
    }
  }
  .pdp-navigation-sopac {
    & a {
      @include anchorStyle();
    }
  }
  .pdp-combo {
    background: #f8f7f5;
  }
  .recommendedCard-container {
    @media #{$breakpoint-below-and-above-medium-desktop} {
      margin-left: 10.3% !important;
      padding-left: 0% !important;
    }
    @media #{$breakpoint-ipad-12-pro-portrait} {
      margin-left: 2% !important;
    }
    @media #{$breakpoint-tablet-only} {
      margin-left: 2.6% !important;
    }
    @media #{$mobile-only} {
      margin-left: 5% !important;
    }
    @media #{$breakpoint-below-medium-desktop} {
      margin-left: 5.4% !important;
    }
  }
}

.quantity-input {
  font-family: $National2Condensed;
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $Brand-Black;
  margin: 2px 10px;
  padding: 1px 2px;
  border-radius: 4px;
  .pdp-promo-toast-container {
    background-color: $Alert-Yellow;
    @media #{$mobile-only} {
      width: 71.47vw;
      max-width: 312px;
      margin: auto;
    }
    @media #{$breakpoint-above-tablet} {
      width: 34.72vw;
      max-width: 500px;
      margin-left: -4.36vw;
    }
    @media #{$breakpoint-below-tablet} {
      top: 58%;
    }
    @media #{$breakpoint-ipad-12-pro-portrait} {
      left: 6%;
    }
    & .pdp-promo-toast {
      & .img-text-container {
        display: flex;
        & .text {
          color: $Brand-Black;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          padding-left: 10px;
        }
      }
    }
  }
}
.pdp-promos-container.with-price {
  display: flex;
  margin-bottom: 10px;
  @media #{$mobile-only} {
    margin-bottom: 9px;
  }
}
