.material-input {
  &.field {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1em;

    /**
		* Add a transition to the label and input.
		*/
    label,
    input {
      transition: all 0.2s;
      touch-action: manipulation;
    }

    input {
      border: 0;
      border-bottom: 1px solid $Dark-Gray;
      -webkit-appearance: none;
      border-radius: 0;
      padding: 0;
      cursor: text;
      padding-bottom: 10px;
      font-family: $National2Regular;
    }

    input:focus {
      outline: 0;
      border-bottom: 1px solid $Brand-Black;
    }

    input.error {
      border-bottom: 2px solid #f00;
    }

    label {
      margin: 0 !important;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
    }

    .text-field-title {
      font-size: 16px;
      line-height: 24px;
    }

    .address-text-field {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      font-weight: bold;
    }

    label.error {
      color: red !important;
      font-size: 14px !important;
    }

    /**
		* Translate down and scale the label up to cover the placeholder,
		* when following an input (with placeholder-shown support).
		* Also make sure the label is only on one row, at max 2/3rds of the
		* field—to make sure it scales properly and doesn't wrap.
		*/
    input:placeholder-shown + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, 2.125rem) scale(1.5);
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
    }

    /**
		* By default, the placeholder should be transparent. Also, it should
		* inherit the transition.
		*/
    ::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Brand-Black;
    }

    // FireFox
    @-moz-document url-prefix() {
      input::-moz-placeholder {
        color: transparent;
      }
    }

    /**
		* Show the placeholder when the input is focused.
		*/
    input:focus::-webkit-input-placeholder {
      opacity: 0;
    }

    /**
		* When the element is focused, remove the label transform.
		* Also, do this when the placeholder is _not_ shown, i.e. when
		* there's something in the input at all.
		*/
    input:not(:placeholder-shown) + label,
    input:focus + label {
      transform: translate(0, 0) scale(1);
      cursor: pointer;
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}

.currency-field {
  position: absolute;
  top: 43%;
  font-size: 17px;
  font-family: $National2Medium;
  font-weight: bold;
  color: #626365;
}

.creditOrDbtDiv {
  position: relative;
}

.creditOrDbtSvg {
  position: absolute;
  right: 2px;
  top: 2rem;
}

.creditOrDbt {
  z-index: 11;
}
