.tippingInfoContent {
  width: 271px;
  height: 21px;
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.1px;
  color: #494949;
}

.donationbutton {
  .labelContent {
    @media #{$mobile-only} {
      margin-left: 15px !important;
    }
  }

  .info-label {
    @media #{$mobile-only} {
      margin-top: 24px !important;
    }
  }
}

.tipping-details {
  margin-top: 40px;
  margin-bottom: 40px;

  @media #{$mobile-only} {
    margin-top: 10px !important;
  }

  .button-contained[data-type='primary'] {
    // background: #202124;
    border-radius: 22px;
    height: 44px;
  }

  .center-element {
    margin: 0 10px !important;
    padding-left: 0;
    padding-right: 0px;

    @media #{$mobile-only} {
      margin: auto !important;
      width: auto !important;
    }
  }

  .alert-warning {
    color: #{$gray-lightbg};
    font-weight: 400;
    background-color: inherit;
    border-color: inherit;
    font-size: 14px !important;
    font-family: #{$National2Regular};
  }
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  cursor: pointer;
  @media #{$mobile-only} {
    justify-content: space-between;
  }
}

.flex-container > div {
  margin: 10px;
  width: 88px;
  height: 61px;
  padding: 8px;
  border: 1px solid #202124;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;

  @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
    margin: 8px;
    width: 80px;
  }

  @media #{$mobile-only} {
    width: 82px;
  }

  @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
    width: 62px;
    height: 50px;
    margin: 6px !important;
  }

  @media #{$breakpoint-tablet-only} {
    width: 46px;
    height: 50px;
    padding: 0px;
    padding-top: 6px;
    margin: 6px !important;
  }

  @media #{$breakpoint-ipad-12-pro-portrait} {
    width: 60px;
    height: 50px;
    padding: 7px;
  }

  @media #{$breakpoint-ipad-pro-11-landscape} {
    width: 62px;
    height: 50px;
  }

  @media #{$breakpoint-ipad-8-landscape} {
    width: 62px;
    height: 50px;
  }
}

.flex-container p {
  margin-bottom: 0px !important;
}

.tippingtext {
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #202124;

  @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
    font-size: 15px;
  }

  @media #{$breakpoint-below-include-iphone5} {
    font-size: 15px;
  }
}

.tip-text {
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.1px;
  color: #494949;
  margin-bottom: 14px;
  margin-left: 10px;
}

.tipping-section:hover {
  background: #202124;
  color: #ffffff;

  .tippingtext {
    color: #ffffff;
  }

  .tippingpercentage {
    color: #ffffff;
  }
}

.selected-tip {
  background: #202124;
  color: #ffffff;

  .tippingtext {
    color: #ffffff;
  }

  .tippingpercentage {
    color: #ffffff;
  }
}

.tippingpercentage {
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #202124;

  @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
    font-size: 14px;
  }

  @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
    font-size: 11px;
  }

  @media #{$breakpoint-tablet-only} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-12-pro-portrait} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-pro-11-landscape} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-8-landscape} {
    font-size: 11px;
  }
}

.enbledtippingpercentage {
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #626365;

  @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
    font-size: 14px;
  }

  @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
    font-size: 11px;
  }

  @media #{$breakpoint-tablet-only} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-12-pro-portrait} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-pro-11-landscape} {
    font-size: 11px;
  }

  @media #{$breakpoint-ipad-8-landscape} {
    font-size: 11px;
  }
}

.deliveryinputfield {
  position: relative;
  margin-top: 14px;
  margin-left: 20px;

  .material-input.field input {
    padding-left: 15px;
    margin-top: 6px;

    @media #{$mobile-only} {
      margin-top: 4px;
    }
  }

  .material-input.field > input {
    font-weight: 500;
    font-family: $National2Medium;
    color: $Brand-Black;
  }

  .material-input.field input:disabled {
    border-width: 0px;
    -webkit-text-fill-color: $Brand-Black;
    opacity: 1;
  }

  .material-input.field input:not(:placeholder-shown) + label,
  .material-input.field input:focus + label {
    font-size: 16px;
  }
}

.cleariconsection {
  position: absolute;
  right: 0;
  top: 45%;
  cursor: pointer;
}

.drivertip-button {
  background-color: #202124 !important;
  color: #fff;
}

.drivertip-button-disabled {
  background-color: #dbdbdb !important;
  color: #494949;
  pointer-events: none;
}

.editsection {
  position: absolute;
  right: 0;
  top: 40%;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.driver-tipsection {
  height: 53px;
}

.donationtext {
  padding-top: 11.5px;
}

.alert-warning {
  margin-left: 20px;
}

.alert-warningtext {
  font-size: 14px;
}

.addhope-amount {
  font-family: $National2Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-left: 5px;
}
.modal-body {
  .add-hope-cart-container {
    margin-top: 0;
    margin-left: 15%;

    @media #{$mobile-only} {
      margin-left: 0;
      margin-right: 0;
    }

    .labelContent-addHope {
      @media #{$mobile-only} {
        font-size: 32px;
        text-align: center;
      }

      @media #{$breakpoint-below-medium-only} {
        margin-left: 0;
      }
    }

    .flex-container > div {
      width: 120px;
      margin: 8px;

      @media #{$breakpoint-above-tablet-and-below-medium-desktop} {
        width: 93px;
      }

      @media #{$breakpoint-below-medium-only} {
        width: 100px;
      }
      @media #{$mobile-only} {
        width: 30%;
        margin: 5px !important;
      }
      @media #{$breakpoint-iphone-plus} {
        width: 110px;
      }
      @media #{$breakpoint-above-small-iphoneX} {
        width: 100px;
      }
      @media #{$breakpoint-below-include-iphoneX} {
        width: 90px;
      }
      @media #{$breakpoint-below-include-iphone5} {
        width: 29%;
      }
    }

    .tip-text-donate {
      margin-top: 0;

      @media #{$breakpoint-below-medium-only} {
        margin-top: 20px;
      }

      @media #{$mobile-only} {
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
    }

    .tipping-details {
      max-width: 90%;

      @media #{$breakpoint-below-medium-only} {
        max-width: 100%;
        margin: auto;
      }
      @media #{$mobile-only} {
        max-width: 100%;
        margin-top: 0 !important;
        margin-left: 0px;
      }
    }

    .add-hope-cart-help-text-container {
      width: 70%;
      margin-left: 5px;

      @media #{$mobile-only} {
        width: 100%;
        margin: 10px;
      }
    }

    .deliveryinputfield .material-input.field input {
      padding-left: 20px;
      margin-top: 6px;
      background-color: #f8f7f5;
    }

    .add-Hope-ext-button {
      padding: 0 !important;
      margin: 24px 0px;

      @media #{$breakpoint-above-medium-tablet} {
        width: 68%;
      }
      @media #{$breakpoint-tablet-only} {
        width: 70%;
      }

      .button-contained[data-type='primary'] {
        background-color: #202124;
        color: #fff;
        border-radius: 22px;
        height: 44px;
      }
      .button-contained[data-type='primary'][data-inactive='true'] {
        background-color: #dbdbdb !important;
        color: #494949;
        height: 44px;
      }
    }

    .deliveryinputfield {
      width: 70%;

      @media #{$mobile-only} {
        width: 90%;
      }
    }
  }
}

.add-hope-coupon-modal {
  .modal-content {
    height: 50% !important;
  }
  .alert-content {
    width: 100% !important;
  }
}

.cart-summary-content {
  #DispositionModulePopup {
    .modal-content {
      .modal-header {
        text-transform: none;
      }
    }
  }
}
