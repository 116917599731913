@mixin language-selector-comp-styles($withBorder, $color) {
  @if $withBorder {
    .language-selector-container {
      width: 89px;
      height: 36px;
      margin-left: auto;
      .language-selector {
        width: 89px;
        height: 36px;
        background-color: transparent;
        outline: none;
        border: none;
        .language-select {
          width: 91px;
          height: 36px;
          margin: 0;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          color: $color;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          padding: 0 37px 0 28px;
          border-radius: 34px;
          text-transform: uppercase;
          background-color: transparent;
          pointer-events: none;
        }
        .dropdown-img {
          position: relative;
          vertical-align: middle;
          top: -33px;
          left: 36px;
        }
        .dropdown-img-desktop {
          display: none;
        }
      }
    }
  } @else {
    .language-selector-container {
      padding-left: 0;
      display: flex;
      border-right: 1px solid $Light-Gray;
      padding-right: 6%;
      margin: auto 0;
      @media #{$breakpoint-above-tablet} {
        position: relative;
        left: 0;
        top: -7%;
        padding-right: 20px;
      }

      .customSelect span {
        font-family: $National2Medium;
      }
      .active-lang {
        font-weight: bold;
      }
      .lang-globe-icon {
        width: 18px;
        height: 18px;
      }
      .global-custom-select-container .dropdown-img {
        content: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
        background: url('../../../staticAssets/images/AccordianUPBlack.svg') no-repeat 100% 100%;
        transform: rotate(180deg);
      }
      .global-custom-select-container .dropdown-img.upArrow {
        margin-top: -3px;
        transform: rotate(0deg);
      }
      .global-custom-select-container {
        border-bottom: 0px;
      }
      .global-custom-select-container .global-custom-select-dropdown {
        min-width: 224px;
        padding: 0;
        min-height: auto;
        max-height: -moz-fit-content;
        max-height: fit-content;
        border-radius: 8px;
        left: -205%;
        top: 160%;
      }
      .global-custom-select-container .customSelectOption {
        min-height: auto;
        margin: 0;
        background-color: #202124;
        // height: 48px;
        float: left;
        padding: 0 23px;
      }
      .customSelectOption span {
        float: left;
        line-height: 48px;
        border-bottom: 1px solid #d2d3d3;
        width: 100%;
        // padding-left: 16px;
        font-family: $National2Medium;
      }
      .customSelectOption span:not(.active-lang) {
        padding-left: 23px;
      }
      .lang-selector {
        color: #fff;
        padding: 14px 24px;
        display: inline-block;
        pointer-events: none;
      }
      .customSelectOption span.active-lang::before {
        content: '';
        width: 12px;
        height: 11px;
        background-image: url('../../../staticAssets/images/cart-notification-success.png');
        margin: 0 8px 0px 0;
        display: inline-block;
      }
      .global-custom-select-container .customSelectOption:last-child span {
        border: 0;
      }
      .global-custom-select-container .customSelectOption:hover {
        background-color: #000;
      }
      .lang-selector-wrapper {
        display: flex;
        width: 90px;
        padding-right: 8px;
        vertical-align: middle;
        height: 20px;
        position: relative;
      }
      .language-selector {
        background-color: transparent;
        padding: 0;
        outline: none;
        border: none;
        .language-select {
          background-color: #fff;
          border: none;
          width: max-content;
          min-width: 25px;
          padding: 0;
          text-transform: capitalize;
          pointer-events: none;
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Brand-Black;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
        }
        .dropdown-img {
          display: none;
        }
        .dropdown-img-desktop {
          width: 5px;
          height: 2.5px;
          vertical-align: middle;
          margin-left: 12px;
        }
      }
      @media #{$breakpoint-below-tablet} {
        display: none;
      }
    }
    .language-selector-container.no-border {
      border: none;
    }
  }
}
#tooltip-lang-selector {
  z-index: 991;
}

.hamburger-container.side-nav-open {
  .langmodal-close-button {
    margin-left: 13px;
    padding-bottom: 5px;
    margin-right: 15px;
    padding-right: 0px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .langmodal-label {
    font-family: $National2Condensed;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #202124;
    margin: 14px auto;
  }
  .langmodal-stripe-icon {
    margin-left: 16px;
    padding: 0;
    display: inline-block;
  }
  .langmodal-stripe-icon svg {
    margin-top: -6px;
    width: 39px;
    height: 20px;
  }
  .langmodal-list-content {
    margin-top: 24px;
  }
  .langmodal-list-txt {
    padding: 24px 0;
    display: inline-block;
    border-bottom: 0.5px solid #202124;
    width: 100%;
  }
  .langmodal-list-txt:not(.active-lang) {
    padding: 24px 0 24px 24px;
  }
  .langmodal-list-txt.active-lang {
    font-weight: 500;
  }
  .langmodal-list-txt.active-lang:before {
    content: '';
    width: 14px;
    height: 11px;
    background-image: url('../../../staticAssets/images/lang-tick-black.svg');
    margin: 0 8px 0px 0;
    display: inline-block;
  }
  .langmodal-list {
    width: 100%;
    padding: 0 44px;
    cursor: pointer;
  }
  .langmodal-list:hover {
    background-color: #f8f7f5;
  }
  .langmodal-list:last-child .langmodal-list-txt {
    border: 0;
  }
  .lang-selector-wrapper {
    &.mob {
      margin: 8px 13px 0 0;
      cursor: pointer;
      .lang-globe-icon {
        margin: -2px 6px 0 0;
      }
    }
  }
}
