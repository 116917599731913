.email-communication-preferences-page .preferences-strips-icon {
  display: block;
  margin-left: 0px;
  margin-bottom: 24px;
}
.email-communication-preferences-page .communication-preferences-container {
  padding-left: 16px;
  padding-right: 16px;
}
.email-communication-preferences-page .account-section-right {
  margin-left: auto;
  margin-right: auto;
}

.email-communication-preferences-page .account-desktop-container {
  display: block;
}

.email-communication-preferences-page .save-preferences-container {
  justify-content: center;
  align-items: center;
}

@media #{$breakpoint-below-tablet} {
  .email-communication-preferences-page .account-section-right {
    width: 100%;
  }
  .email-communication-preferences-page .account-section-right .preferences-card .preferences-card-left-side {
    padding: 0;
    margin-left: 0px;
    width: 100%;
  }
  .email-communication-preferences-page .account-desktop-container .account-section-right .preferences-card {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .email-communication-preferences-page .account-section-right .communication-preferences-text-heading {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .email-communication-preferences-page .preferences-card-left-side .preferences-card-left-side-au {
    padding: 0px;
    margin-left: 0px;
  }
  .email-communication-preferences-page .account-section-right .preferences-card .preferences-card-right-side {
    width: 100%;
    padding: 0;
  }
  .email-communication-preferences-page .account-section-right .meal-list-card {
    margin-left: 0px;
    margin-right: 0px;
  }
  .email-communication-preferences-page .account-section-right .meal-list-card .preferences-card-left-side {
    width: 100%;
    padding: 0;
    // margin-left: 16px;
  }
  .email-communication-preferences-page .secondary-header {
    display: none;
  }
  .email-communication-preferences-page
    .communication-preferences-container
    .meal-list-card
    .preferences-card-right-side {
    width: 100%;
    padding: 24px 0px 0px;
  }
  .email-communication-preferences-page .save-preferences-container {
    margin-top: 16px;
  }
  // .email-communication-preferences-page .preferences-strips-icon{
  //   margin-left: 0px;
  // }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .email-communication-preferences-page .account-desktop-container .account-section-right .preferences-card {
    padding-left: 120px;
    padding-right: 120px;
    margin-left: 0px;
  }

  .email-communication-preferences-page
    .account-desktop-container
    .account-settings-header-mobile
    .backArrow-account-setting {
    display: none;
  }

  .email-communication-preferences-page .account-section-right .communication-preferences-text-heading {
    margin-left: 0px;
  }
  .email-communication-preferences-page
    .account-desktop-container
    .account-section-right
    .meal-list-card
    .preferences-card-right-side {
    width: auto;
    margin-left: 104px;
    padding-left: 0px;
    padding-top: 16px;
    padding-bottom: 0px;
  }

  .email-communication-preferences-page .account-section-right .meal-list-card {
    padding: 24px 16px;
  }

  .email-communication-preferences-page
    .account-desktop-container
    .account-section-right
    .meal-list-card
    .preferences-card-right-side
    .meal-description {
    margin-bottom: 16px;
  }

  .email-communication-preferences-page .communication-preferences-container {
    margin-left: auto;
    margin-right: auto;
    width: 780px;
  }

  .email-communication-preferences-page .communication-preferences-heading-alignment {
    margin-left: auto;
    margin-right: auto;
    width: 780px;
  }
  .email-communication-preferences-page .save-preferences-container {
    margin-top: 16px;
  }
  .email-communication-preferences-page .meal-list-card {
    margin-top: 0px;
  }
}
