.marketing-hero-in {
  .smallTxtHero {
    position: absolute;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: $uppercase;
    color: $Brand-Black;
    left: 229px;
    right: 50.83%;
    top: 40px;
    bottom: 84.85%;
  }

  .largeTextHero {
    position: absolute;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
    letter-spacing: 1px;
    text-transform: $uppercase;
    color: $Brand-Black;
    left: 229px;
    right: 40.82%;
    top: 17.68%;
    bottom: 43.94%;
  }

  .color-white {
    color: $white;
  }

  .color-black {
    color: $Brand-Black;
  }

  .carausel-paging {
    border: 1px solid $Dark-Gray;
    opacity: 0.5;
    box-sizing: border-box;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    text-align: $center;
    //padding-top: 10%;

    @media #{$breakpoint-below-tablet} {
      height: 15px;
      width: 15px;
      background-color: $Dark-Gray;
    }
  }

  .carausel-pagingNo {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: $center;
    color: $Dark-Gray;
    top: -2px;
    position: relative;

    @media #{$breakpoint-below-tablet} {
      display: none;
      border: 2px solid $Dark-Gray;
    }
  }

  .slick-active .carausel-pagingNo {
    color: $Brand-Black;
  }

  .carauselOrderNow {
    flex-direction: row;
    justify-content: $center;
    align-items: $center;
    margin-top: 30px;
    border-radius: 200px;
    position: absolute;
    left: 229px;
    right: 66.65%;
    bottom: 25.25%;
    font-family: $National2Regular;
    font-weight: $font-weight-500;
    padding: 10px 40px;
    // outline: 5px auto -webkit-focus-ring-color;
  }

  .slick-dots {
    left: 229px;
    bottom: 6% !important;
    width: auto !important;

    @media #{$mobile-only} {
      margin: 0 !important;
      margin-left: 3px !important;
    }
  }

  .slick-dots li {
    margin: 0 10px !important;
    margin-left: -8px;

    @media #{$breakpoint-below-tablet} {
      margin: 0 !important;
    }

    @media #{$medium-only} {
      margin: 0 5px !important;
    }

    @media #{$mobile-only} {
      margin-right: 17px !important;
    }
  }

  .carausel-inner-positions {
    position: relative;
    width: 47vw;
    left: 11vw;

    @media #{$breakpoint-below-tablet} {
      left: 8vw;
    }
  }

  .carausel-text-span {
    position: absolute;
    top: 30px;
  }

  .CircularProgressbar {
    height: 28px;
    width: 28px !important;
    margin-top: -5px;

    & .CircularProgressbar-trail {
      stroke-width: 3 !important;
      opacity: 0.7 !important;
    }

    & .CircularProgressbar-path {
      stroke-width: 3 !important;

      @media #{$breakpoint-below-tablet} {
        stroke: $Brand-Black;
      }
    }

    @media #{$medium-only} {
      height: 20px;
      width: 21px !important;
      margin-top: -12px;
    }
  }

  .CircularProgressbar-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 40px !important;
    line-height: 22px;
    text-align: center;
    dominant-baseline: mathematical;
    //fill: $Brand-Black!important;
  }

  .slick-active {
    top: 2px;

    .CircularProgressbar {
      @media #{$mobile-only} {
        width: 11px !important;
        height: 11px !important;
      }
    }

    .carausel-paging {
      border: 1px solid $Brand-Black;
      background-color: transparent;
    }
  }

  @media #{$medium-only} {
    .carausel-text-span {
      top: 5%;
    }

    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      left: 8%;
      right: 46.67%;
      top: 19.6%;
      bottom: 72.4%;
    }

    .largeTextHero {
      font-size: 22px;
      line-height: 24px;
      left: 8%;
      right: 46.67%;
      top: 31.6%;
      bottom: 49.2%;
    }

    .carauselOrderNow {
      margin-top: 5px;
      left: 8.67%;
      right: 51.87%;
      top: 58.8%;
      bottom: 23.6%;
    }

    .carausel-paging {
      height: 20px;
      width: 20px;
    }

    .CircularProgressbar-text {
      font-size: 35px;
    }

    .slick-dots {
      left: 12%;
    }
  }

  @media #{$breakpoint-between-mob-tablet} {
    .carauselOrderNow {
      right: 42%;
    }

    .slick-dots {
      left: 10% !important;
    }

    .largeTextHero {
      font-size: 31px !important;
      right: 46.67%;
    }
  }

  @media #{$large-only} {
    .smallTxtHero {
      top: 8%;
    }

    .largeTextHero {
      font-size: 50px;
      line-height: 60px;
      right: 40%;
    }

    .slick-dots {
      left: 16.5% !important;

      & li {
        margin: 0 7px;
      }
    }

    .carauselOrderNow {
      right: 60%;
    }

    .CircularProgressbar-text {
      font-size: 40px !important;
    }
  }

  @media #{$breakpoint-below-tablet} {
    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      top: 19px;
    }

    .largeTextHero {
      font-size: 35px;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    .carausel-text-span {
      top: 20px;
    }

    .slick-dots {
      bottom: 4% !important;
      left: 9vw;
    }

    .carauselOrderNow {
      margin-top: 10px;
      right: 42%;

      /*  font-size: 12px;
            padding    : 0 30px;
            height     : 35px; */
    }

    .CircularProgressbar {
      height: 15px;
      width: 15px !important;
      margin-top: -17px;
      margin-right: 10px;

      & .CircularProgressbar-text {
        display: none;
      }
    }
  }

  @media #{$mobile-only} {
    .carausel-text-span {
      top: 10px !important;
    }

    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      left: 30px;
      right: 33.67%;
      top: 49px;
      bottom: 72.4%;
    }

    .largeTextHero {
      font-size: 20px;
      line-height: 24px;
      left: 30px;
      right: 32.67%;
      top: 31.6%;
      bottom: 49.2%;
      width: 48%;
    }

    .carauselOrderNow {
      margin-top: 20px;
      align-items: center;
      padding: 10px 40px;
      left: 30px;
      right: 50.87%;
      font-family: $National2Regular;
      font-weight: 500;
    }

    .carausel-paging {
      height: 10px;
      width: 10px;
      margin-top: 7px;
    }

    .CircularProgressbar {
      height: 7px;
      width: 7px !important;
      margin-top: -11px;
    }

    .slick-dots {
      left: 30px;
      bottom: 19px !important;

      & li {
        width: 12px !important;
        margin-right: 12px !important;
      }

      & .carausel-paging {
        height: 10px;
        width: 10px;
      }
    }
  }

  @media #{$breakpoint-below-iphone5} {
    .carauselOrderNow {
      right: 40%;
    }
  }

  .progress-circle {
    -moz-border-radius: 50px/50px;
    -webkit-border-radius: 50px 50px;
    border-radius: 50px/50px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);

    & .format-paging-no {
      transform: rotate(-45deg);
      font-size: 12px;
      position: absolute;
      left: 20%;
      top: 12%;
    }
  }

  .white-color.carausel-paging {
    border: 1px solid $white;
    top: 0;
    position: relative;

    @media #{$breakpoint-below-tablet} {
      background: $Inactive-Gray;
    }

    & .carausel-pagingNo {
      color: $white;
    }
  }

  .black-color.carausel-paging {
    position: relative;
    top: 0;

    & .carausel-pagingNo {
      color: $Brand-Black;
    }
  }

  .marketing-hero {
    position: relative;
    height: 396px;
    min-width: 100%;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$breakpoint-below-tablet} {
      height: 250px;
    }

    @media #{$medium-only} {
      height: 350px;
    }

    @media #{$mobile-only} {
      height: 250px;
    }
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast-container {
    top: 451px;

    @media #{$breakpoint-below-tablet} {
      top: 55% !important;
      width: 75% !important;
      margin: 0 12.5% !important;
    }
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast--dark {
    background: $Brand-Black;
    color: $white;
  }

  .sign-in-toast {
    width: 298px;
    top: 451px;
    padding: 0px !important;

    & .img-text-div {
      text-align: $center;
    }

    & .text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      font-family: $National2Regular;
      margin-left: 5px;
      @media #{$breakpoint-below-iphoneX} {
        font-size: 10px;
      }
    }
  }

  .carausel-image {
    width: 100%;
  }

  .marketing-hero-img-div {
    cursor: pointer;
    position: relative;
    //height: 396px;
    //min-width: 100%;
    // margin-top: 30px;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .removePointer {
    cursor: default;
    position: relative;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
