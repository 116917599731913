.add-newcard-conatiner {
  max-width: 980px;
  margin: auto;
  width: 50%;
  background-color: #f8f7f5;
  padding: 20px 30px;

  @media #{$breakpoint-below-tablet} {
    width: 90%;
  }

  @media #{$mobile-only} {
    width: 100%;
    padding-bottom: 130px;
  }

  .mt-10px {
    margin-top: 10px;
  }
  .globalpayment-add-new-card {
    flex-direction: row-reverse;
    @media #{$mobile-only} {
      flex-direction: column;
    }
  }
}

.add-newcard {
  margin-bottom: 4.5rem;

  @media #{$breakpoint-below-tablet} {
    padding: 0 15px;
    text-align: center;
  }

  @media #{$mobile-only} {
    margin: 40px 15px;

    button {
      width: 100%;
    }
  }
  [data-inactive='true'] {
    box-shadow: none !important;
    background-color: #dbdbdb;
    color: $Brand-Black !important;
    margin-left: 0;
  }
}

.material-input.field input:placeholder-shown + label {
  font-size: 10px;
}

.required-suggestion {
  font-size: 14px;
  font-weight: 400;
  padding: 20px 0;
  font-family: $National2Regular;
  line-height: 21px;
  width: 352px;
  height: 21px;
}

.savebilling-address {
  input[type='text'],
  select {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #202124;
    outline: none;
    z-index: 20;
    font-weight: 500;
    font-family: $National2Regular;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    border-radius: 0;
  }

  input[type='text']:focus ~ label,
  select:focus ~ label,
  select:valid ~ label,
  input[type='text']:valid ~ label {
    top: -55px;
    font-size: 12px;
    line-height: 22px;
    color: #494949;
  }

  .address-label {
    display: inline-block;
    transition: all 0.2s ease;
    z-index: 10;
    position: relative;
    top: -30px;
    font-weight: 400;
    font-size: 16px;
    font-family: $National2Regular;
    line-height: 24px;
  }

  .input-container {
    position: relative;
    margin: 2.5em 0;
    margin-bottom: 0;
    .address-list {
      top: 40px;
      border-radius: 4px;
      position: absolute;
      z-index: 99;
      height: auto;
      list-style: none;
      overflow-y: auto;
      width: 100%;
      padding: 0;
      background-color: $Mashed-Potato-Gray;

      .address-suggestion-text {
        font-size: 12px;
        background-color: $Brand-Black;
        color: white;
        font-family: $National2Regular;
        padding: 1% 1% 1% 1%;
        cursor: pointer;

        .suggetion-pin {
          margin-right: 10px;
        }
      }
    }
  }

  .form-checkbox-payonarrival {
    margin: 1.5rem 0;
  }
  .inputError {
    input[type='text'] {
      border-color: #ff2e00;
    }
  }
}

.save-card {
  @extend .savebilling-address;
  .inputError {
    input[type='text'] {
      border-color: #ff2e00;
    }
    .cvvErrorColour {
      position: absolute;
      top: 40px;
    }
  }
  .input-container {
    margin-bottom: 0;
  }
}

.billingaddress-container {
  padding-top: 40px;
  @media #{$breakpoint-below-tablet} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.Savebutton {
  border: 1px solid red;
  height: 40px;
  background: red;
}

#add-new-card-cont {
  width: 100%;
  position: relative;

  #payment-provider-place-order-form {
    position: absolute;
    margin-left: 10px;
  }

  .payment-place-order-cont-label {
    width: 100%;
    position: absolute;
  }

  .payment-place-order-label {
    height: 2.75rem;
    padding: 0 2.5rem;
    border: 0;
    border-radius: 1.4rem;
    font-size: 0.9rem;
    line-height: 2.75rem;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    transition-property: background-color, color;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.15, 0.45, 0.6, 1);
    background-color: #d2d3d3;
    color: #202124;
    width: 100%;
    pointer-events: none;
    // opacity: 0.7;
  }

  .addCardValid {
    background-color: #202124;
    color: #fff;
    cursor: pointer;
  }

  .addCardInValid {
    pointer-events: none;
  }
}

.add-cancel-button {
  position: relative;
  .whiteButton {
    background-color: transparent;
  }
  @media #{$mobile-only} {
    .whiteButton {
      position: absolute;
      top: 50px;
      width: 100%;
      margin-left: 0;
    }
  }
}

.maximunCardWarning-container {
  display: inline-flex;
  flex-direction: column;

  .maximunCardWarning {
    background: $Alert-Yellow;
    color: $Brand-Black;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    border-radius: 4px;
    display: flex;
    padding: 15px;
    width: 100%;
    margin: 20px 0 40px 0;

    span {
      margin-left: 12px;
    }
  }
  @media #{$mobile-only} {
    .maximunCardWarning {
      width: 85%;
      margin: 20px auto 40px;
    }
  }
}

.disabledpostalcode {
  pointer-events: none;
}

.card-validate {
  .confirm-order-text {
    padding-top: 10px;

    @media #{$mobile-only} {
      padding-top: 20px;
    }
  }
  .progress-bar-text {
    font-family: $National2Regular !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}
.noAddressText {
  font-style: normal;
  font-weight: 600;
  // font-family: $National2Medium;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #ff2e00;
}
