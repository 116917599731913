.flexible-tout {
  background-color: $Mashed-Potato-Gray;
  color: $Brand-Black;
  border-radius: 8px;
  padding: 32px 16px;

  @media #{$breakpoint-above-tablet} {
    padding: 32px 104px;
  }

  &.bgBlack {
    background-color: $Brand-Black;
    color: $white;
  }
  &.bgGray {
    background-color: $Mashed-Potato-Gray;
    color: $Brand-Black;
  }
  &.bgRed {
    background-color: $Brand-Red;
    color: $white;
  }

  &--container {
    padding: 16px;
    background-color: $Brand-Black;

    @media #{$breakpoint-above-tablet} {
      // padding: 40px;
    }

    &.bgBlack {
      background-color: $Brand-Black;
    }
    &.bgGray {
      background-color: $Mashed-Potato-Gray;
    }
    &.bgRed {
      background-color: $Brand-Red;
    }
  }

  &--columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$breakpoint-above-tablet} {
      flex-direction: row;
    }
  }

  &--column {
    display: flex;

    &--1 {
      flex-flow: column;
      gap: 8px;
      margin-left: 96px;
      position: relative;

      @media #{$breakpoint-above-tablet} {
        //gap: 8px;
        margin-left: 104px;
      }
    }

    &--2 {
      margin-left: 96px;

      @media #{$breakpoint-above-tablet} {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
      }
    }
  }

  &--headline {
    margin-bottom: 0;
    font-family: $National2Condensed;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media #{$breakpoint-above-tablet} {
      font-size: 2.5rem;
    }
  }

  &--text {
    font-size: 14px;
    line-height: 21px;

    @media #{$breakpoint-above-tablet} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--pictogram-wrapper {
    float: left;
    position: absolute;
    margin-left: -96px;
    padding-top: 4px;

    @media #{$breakpoint-above-tablet} {
      margin-left: -104px;
      padding-top: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--button-container {
    margin-top: 32px;
    margin-bottom: 0;

    @media #{$breakpoint-above-tablet} {
      margin-top: 0;
    }
  }

  &--button {
    height: 44px;
    text-decoration: none;
    font-size: 16px;
    line-height: 2.75rem;
  }
}

// Customization

// Centered content
.center {
  .flexible-tout,
  &.flexible-tout {
    padding: 32px 16px;
    padding: 0;

    &--columns {
      align-items: center;
      flex-flow: column;
      justify-content: center;
    }

    &--column {
      width: 100%;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      text-align: center;
    }

    &--pictogram-wrapper {
      margin-left: 0;
      float: unset;
      position: unset;
      transform: unset;
    }

    &--headline {
      font-size: 2.5rem;
      letter-spacing: 1px;
      margin-bottom: 1rem;

      @media #{$breakpoint-below-tablet} {
        font-size: 2.4rem;
      }
    }

    &--text {
      color: $gray-lightbg;
      font-size: 1rem;
      font-weight: 700;

      @media #{$breakpoint-below-tablet} {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }
}

// Cta list component
.flexible-tout {
  &--cta-list-wrapper {
    width: 100%;
    display: flex;

    @media #{$breakpoint-below-tablet} {
      flex-direction: column;
    }

    &.horizontal {
      .flexible-tout--button-container:first-child {
        margin-right: 1rem;

        @media #{$breakpoint-below-tablet} {
          margin-top: 0;
          margin-right: 0;
        }
      }

      @media #{$breakpoint-below-tablet} {
        .flexible-tout--button-container {
          margin-top: 1rem;
        }
      }

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }
    }

    &.stacked {
      flex-direction: column;

      .flexible-tout--button-container:first-child {
        margin-bottom: 1rem;
      }

      @media #{$breakpoint-below-tablet} {
        .flexible-tout--button-container {
          margin-top: 0;
        }
      }

      &.left {
        align-items: flex-start;
      }

      &.center {
        align-items: center;
      }

      &.right {
        align-items: flex-end;
      }
    }
  }
}

// Padding
.noPadding {
  .flexible-tout,
  &.flexible-tout {
    &--container {
      padding: 0px;
    }
    &--columns {
      padding: 0px;
    }
  }
}
