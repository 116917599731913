.flexible-component {
  &--header {
    &-wrapper {
      padding: 48px 0 24px 0;
    }
    &-copy {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-family: $National2Condensed;
      font-size: 36px;
      font-weight: $font-weight-bold;
      line-height: 100%;
      margin-bottom: 8px;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 48px;
        margin-bottom: 24px;
      }
    }
    &-pictogram {
      width: 80px;
      height: 80px;
    }
  }

  &--sub-header-copy {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    font-family: $National2Regular;
    font-size: 16px;
    line-height: 150%;
    p {
      grid-column: span 9;
      margin-bottom: 0;
    }
  }
  &--pictogram {
    width: 80px;
    height: 80px;
  }
}
