.btnTextWhite {
  color: $white;
}

.button {
  padding: 0.656rem 2.75rem;
  border-radius: 10rem;
  //height: 44px;
  text-align: $center;
  align-items: $center;
  flex-direction: row;
  font-family: $National2Regular;
  font-style: normal;
  font-size: 0.875rem;
  cursor: pointer;
  // outline: none;
  :disabled {
    cursor: not-allowed;
  }
}

// .button {
// 	padding: 11px 40px;
// 	border-radius: 200px;
// 	height: 44px;
// 	text-align: $center;
// 	align-items: $center;
// 	flex-direction: row;
// 	font-family: $National2Regular;
// 	font-style: normal;
// 	font-size: 14px;
// 	cursor: pointer;
// 	// outline: none;
// 	:disabled {
// 		cursor: not-allowed;
// 	}
// }

.redButton {
  background-color: $Brand-Red;
  border: 1px solid $Brand-Red;
  color: $white;

  &:hover {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $Brand-Red;
  }

  &:focus-visible {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $Brand-Red;
    border: 2px solid $white;
    box-shadow: 0 0 0 2px #2e4abd;
  }

  &:active {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), $Brand-Red;
  }

  &:disabled {
    border: none;
    color: $grey;
    cursor: not-allowed;
    background-color: $Inactive-Gray;
  }
}

.activeBtn {
  &:active {
    transform: scale(1.2);
  }
}

.whiteButton {
  background-color: $white;
  border: 1px solid $white;
  color: $Brand-Black;
}

.blackButton {
  background-color: $Brand-Black;
  color: $white;
  border: 1px solid $white;

  &:hover {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $Brand-Black;
  }

  &:focus-visible {
    border: 2px solid $white;
    box-shadow: 0 0 0 2px #2e4abd;
  }

  &:active {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), $Brand-Black;
  }

  &:disabled {
    background-color: $Inactive-Gray;
    color: $Dark-Gray;
    cursor: not-allowed;
  }
}

.whiteButton.blackBorder {
  border: 1.5px solid $Brand-Black;
  margin-left: 5px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background-color: $Brand-Black;
    color: $white;
  }

  &:focus-visible {
    border: 2px solid $white;
    box-shadow: 0 0 0 2px $blue-done;
    background: $Brand-Black;
    color: $white;
  }

  &:active {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), $Brand-Black;
  }

  &:disabled {
    background: rgba(219, 219, 219, 0.3);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

.transparent-btn.white-border {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
}

.transparent-btn.black-border {
  background-color: transparent;
  border: 1px solid $Brand-Black;
  color: $Brand-Black;
}

.grayButton {
  background-color: $Light-Gray;
  border: 1px solid $Light-Gray;
}
