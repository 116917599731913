.Toastify__toast-container {
  &:has(#localize-toast) {
    top: 50% !important;
    bottom: 0 !important;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);

    .Toastify__toast {
      min-height: initial;
      margin-bottom: 0;
      border-radius: 4px;
      padding: 24px;
      background-color: $Brand-Black;

      .Toastify__toast-body {
        padding: 0 !important;

        .notifyText {
          min-height: 24px;
          font-size: 16px;
          letter-spacing: 0;
        }
      }
    }
  }
}
