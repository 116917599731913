.coupon-text-only {
  width: 74% !important;
  margin: auto !important;

  @media #{$breakpoint-below-tablet} {
    width: 91% !important;
    margin: 2% auto;
  }
}

.margin-bottom-5 {
  margin-bottom: 5% !important;
}

.mr-btm-0 {
  margin-bottom: 0;
}

.coupon-card-container.text-only {
  min-height: 160px !important;
}

.coupon-card-container {
  background: $Mashed-Potato-Gray;
  border-radius: 3px;
  width: 80%;
  margin: 2% auto;

  @media #{$breakpoint-above-tablet} {
    padding-left: 40px;
  }

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    margin: 2% auto;
  }

  // generic styles starts
  & .coupon-card-content {
    display: $d-flex;
    width: 90%;

    @media #{$breakpoint-below-tablet} {
      width: 91.5%;
      margin-left: 16px;
      padding-bottom: 24px;
      padding-top: 30px;
    }

    & .coupon-image-content {
      width: 36%;

      @media #{$breakpoint-below-tablet} {
        width: 28%;
      }

      @media #{$breakpoint-below-include-iphoneX} {
        width: 35%;
      }

      @media (max-width: 320px) {
        width: 40%;
      }

      & .coupon-image {
        width: 80%;
        height: auto;

        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }
      }
    }

    & .coupon-text-content.coupon-text-only {
      height: fit-content !important;

      @media #{$breakpoint-below-tablet} {
        min-height: 0 !important;
        margin: auto !important;
      }

      &.show {
        @media #{$breakpoint-above-tablet} {
          margin: 30px auto 10px auto !important;
        }

        @media #{$breakpoint-below-tablet} {
          min-height: 0 !important;
          margin: 30px auto 0 auto !important;
          transition: all 0.3s cubic-bezier(1, 0, 1, 0);
        }
      }

      .coupon-text-limiter {
        max-height: 64px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    & .coupon-text-content {
      width: 44%;
      text-align: $left;
      color: $Brand-Black;
      display: $d-flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: space-between;

      @media #{$breakpoint-below-tablet} {
        width: 56%;
        height: fit-content;
      }

      &.with-image {
        @media #{$breakpoint-below-tablet} {
          width: 100%;
          margin-left: 18px;
          margin-top: -5px;
          height: fit-content;
          max-height: 64px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      & h4.coupon-heading {
        font-family: $National2Condensed;
        font-style: normal;
        margin-bottom: 2%;
        font-weight: $font-weight-bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: $uppercase;
        max-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @media #{$breakpoint-above-tablet} {
          margin-bottom: 3%;
        }

        @media #{$breakpoint-below-tablet} {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          margin-bottom: 0 !important;
          height: fit-content;
          max-height: 40px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      & h5.coupon-desc {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: $font-weight-normal;
        font-size: 14px;
        letter-spacing: -0.1px;

        @media #{$breakpoint-above-tablet} {
          max-height: 40px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          margin-bottom: 3%;
        }
      }

      & .coupon-button {
        margin: 5% 0 7% 0;

        :disabled {
          border: 1px solid $Dark-Gray !important;
          color: $Dark-Gray !important;
        }
      }

      & button.coupon-button:disabled {
        border: 1px solid $Dark-Gray !important;
        color: $Dark-Gray !important;
      }

      & h5.coupon-terms {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;

        @media #{$breakpoint-above-tablet} {
          margin-bottom: 0 !important;
        }

        & .terms-arrow {
          transform: rotate(270deg);
          width: 12px;
          height: 12px;
          margin-left: 1%;
          transition: all 0.3s linear;
        }

        & .terms-arrow.inverted {
          transform: rotate(90deg);
          width: 12px;
          height: 12px;
          margin-left: 1%;
          transition: all 0.3s linear;
        }
      }

      .ml-rgt {
        margin-right: 10px !important;

        @media #{$breakpoint-below-tablet} {
          margin-right: 5px !important;
        }
      }

      & .coupon-time {
        padding-top: 2px;
        width: 100%;
        font-family: $National2Condensed;
        color: $Brand-Red;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: $uppercase;
        margin: 0;

        @media #{$breakpoint-below-include-iphone5} {
          font-size: 14px;
        }

        @media #{$breakpoint-below-desktop-above-tablet} {
          font-size: 14px;
        }

        @media #{$breakpoint-below-include-iphone5SE} {
          font-size: 12px;
        }

        & .coupon-time-block {
          display: flex;
          flex-wrap: nowrap;

          @media #{$breakpoint-above-tablet} {
            margin-bottom: 5px;
          }

          & img.coupon-time-icon {
            vertical-align: middle;
            padding-right: 3%;
          }
        }
      }

      .coupon-expiry-details {
        color: #494949;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        font-family: $National2Medium;
        line-height: 22px;
        display: inline-flex;
        align-items: center;
        letter-spacing: 0;
        //top: 2px;
        position: relative;

        @media #{$breakpoint-below-include-iphone5} {
          font-size: 11px;
        }

        @media #{$breakpoint-below-desktop-above-tablet} {
          font-size: 11px;
        }

        @media #{$breakpoint-below-include-iphone5SE} {
          font-size: 10px;
        }

        & img {
          height: 13px;
          width: 13px;
          position: relative;
          top: 0;

          @media #{$breakpoint-below-include-iphone5} {
            height: 11px;
            width: 11px;
          }

          @media #{$breakpoint-below-desktop-above-tablet} {
            height: 11px;
            width: 11px;
          }

          @media #{$breakpoint-below-include-iphone5SE} {
            height: 10px;
            width: 10px;
          }
        }

        & .capitalize {
          text-transform: capitalize;
          position: relative;
          top: 0;
        }

        & span {
          left: 3px;
          position: relative;
          top: 1px;
          text-transform: lowercase;
        }
      }

      & .coupon-accordion-content {
        width: 100%;
        overflow: hidden;
        max-height: 0;
        margin: 0% auto;
        transition: all 0.3s cubic-bezier(0, 1, 0, 1);
      }

      & .coupon-accordion-content.show {
        overflow: hidden;
        max-height: 9999px;
        margin: 4% auto;
        transition: all 0.3s cubic-bezier(1, 0, 1, 0);
      }
    }

    & .coupon-button-container {
      width: 26%;
      margin: auto;

      button {
        border: 2px solid $Brand-Black;
        margin: auto 0 auto auto;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: $d-flex;
        padding: 10px;
        min-width: 130px;

        .btn-text {
          margin: auto;
        }

        @media #{$breakpoint-above-desktop} {
          margin-left: 48%;
        }
      }

      button:disabled {
        border: 1.5px solid rgba(73, 73, 73, 0.5);
        color: $Dark-Gray;
      }
    }

    // desktop-only styles starts
    & .coupon-text-content.image-text {
      margin-top: -4px;
      margin-left: -8px;
      height: fit-content !important;
    }
    // desktop-only styles ends

    // Mobile-only styles starts
    & .coupon-text-content.coupon-below-image {
      width: 91%;
      margin: 1% auto;
      overflow: hidden;
      max-height: 0;
      transition: all 0.3s cubic-bezier(0, 1, 0, 1);
    }

    & .coupon-text-content.coupon-below-image.show {
      width: 91%;
      margin: auto;
      max-height: 9999px;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(1, 0, 1, 0);
    }

    & .coupon-mobile-content {
      display: $d-flex;
      width: 100%;
      flex-direction: column;
    }

    & .coupon-mobile-image-text {
      width: 100%;
      display: $d-flex;
    }

    & .coupon-accordian-button {
      width: 8%;
      height: 50%;
      margin: auto 0 auto auto;

      & .coupon-accordian-arrow {
        width: 12.5px;
        height: 12.5px;
        float: right;
        vertical-align: baseline;
        transform: rotate(270deg);
        transition: all 0.3s linear;
      }

      & .coupon-accordian-arrow.inverted {
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }
    }
    // Mobile-only styles ends
  }
  // generic styles ends

  // desktop-only styles starts
  & .coupon-card-content.text-image {
    padding-top: 31px;
    padding-bottom: 29px;
  }

  & .coupon-card-content.text-only {
    min-height: 160px;
  }
  // desktop-only styles ends

  // generic-styles starts
  & .terms-list {
    overflow: hidden;
    max-height: 0;
    width: 90%;
    margin: auto;
    transition: all 0.3s cubic-bezier(0, 1, 0, 1);

    & h4.terms-headline {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: $Brand-Black;
    }

    & ul.main-terms-list {
      font-family: $National2Regular;
      padding-left: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
    }
  }

  & .terms-list.show {
    overflow: hidden;
    height: auto;
    max-height: 9999px;
    transition: all 0.3s cubic-bezier(1, 0, 1, 0);
  }
  // generic-styles ends
}
