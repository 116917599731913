.searchStore-module-form {
  margin: 0 15%;

  ::-webkit-input-placeholder {
    /* Internet Explorer 10-11 & Edge */
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    opacity: 0.7;
    padding-left: 3%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 4%;
    }
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 & Edge */
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    opacity: 0.7;
    padding-left: 3%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 4%;
    }
  }

  ::placeholder {
    /* Firefox */
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    opacity: 0.7;
    padding-left: 3%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 4%;
    }
  }

  .search-container-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 1px solid $Inactive-Gray;
    box-sizing: border-box;
    border-radius: 200px;
    background: white;
    margin-top: 17px;
    // margin-bottom: 6%;
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 3%;
    }

    .google-location-img {
      padding-left: 22px;
    }

    .store-search-input {
      padding: 0 12px 0 12px !important;
      width: 100%;
      background: transparent;
      border: none;
      margin-bottom: -3px;
    }

    @-moz-document url-prefix() {
      .store-search-input {
        margin-bottom: 0 !important;
      }
    }

    .store-search-input::placeholder {
      padding-left: 0 !important;
    }

    .store-search-input::-webkit-input-placeholder {
      /* WebKit browsers */
      padding-left: 0 !important;
    }

    .store-search-inputinput:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      padding-left: 0 !important;
    }

    .store-search-input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      padding-left: 0 !important;
    }

    .store-search-input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      padding-left: 0 !important;
    }
  }

  .search-container-data.error-border {
    border: 1px solid $Red-Orange;
  }

  @media #{$breakpoint-below-tablet} {
    margin: 0;
  }

  .link {
    cursor: pointer;
  }

  & .search-address {
    & .input-address {
      position: relative;

      input {
        @media #{$breakpoint-below-tablet} {
          padding-left: 12px;
          background: transparent;
        }
      }

      .suggestion-list {
        margin-top: 2%;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        // height: 27vh;
        max-height: 45vh;
        overflow-y: auto;
        width: 100%;

        & .suggetion-pin {
          position: relative;
          top: 1px;
        }

        & .suggestion-items {
          background-color: $Brand-Black;
          color: white;
          font-family: $National2Regular;
          padding: 1% 1% 1% 3%;
          cursor: pointer;
        }

        & .suggest-text {
          font-family: $National2Regular;
          font-weight: 400;
          padding-left: 3%;
          color: rgba(255, 255, 255, 0.5);
        }

        & .suggested-address-alignment {
          display: flex;
          flex-direction: row;

          & .suggestion-description {
            padding-left: 10px;
          }
        }
      }
    }
  }

  & .store-distance {
    margin-left: 12px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: $Dark-Gray;
  }

  & .info-timing {
    font-family: $National2Medium;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    margin-top: 3px;
  }

  & .list-circle {
    display: list-item;
    color: $Brand-Red;
    margin-left: 23px;
    left: 19px;
  }

  & .info-timingClosed {
    color: $Brand-Red;
    margin-top: 3px;
    font-family: $National2Medium;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .form {
    .store-count {
      padding-top: 21px;
    }
  }

  & .store-count {
    display: flex;
    justify-content: space-between;

    & .location-img {
      margin-right: 5px;
      height: 9px;
      width: 9px;
    }

    & .map-link {
      color: $Brand-Black !important;
      font-weight: 500 !important;
      font-family: $National2Medium;
      font-size: 14px !important;
      line-height: 24px !important;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      text-transform: capitalize;

      & .map-img {
        margin-right: 5px;
        height: 10px;
        width: 11px;
      }
    }
  }

  // & .margin-bottom {
  // border-bottom: 1px solid $Light-Gray;
  // }

  & .favorite-img {
    cursor: pointer;
    @media #{$mobile-only} {
      top: 15px;
      right: 15px;
      position: absolute;
    }
  }
  & .searchstoreBtn {
    @media #{$mobile-only} {
      display: block !important;
    }
  }

  & .search-store-card-btn {
    width: 100%;
    font-family: $National2Medium;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    margin: 0 1%;
  }

  & .dine-in-error-btn {
    padding: 11px 20px;

    @media #{$breakpoint-below-tablet} {
      padding: 11px 10px;
      font-size: 12px;
    }
  }

  & .clearTextIcon {
    // position: absolute;
    // top: 26px;
    margin-right: 22px;
    cursor: pointer;
  }

  & .store-count-title {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #202124;
    opacity: 0.5;
    cursor: pointer;
    margin-left: 10px;
    &.savedKFC {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    @media #{$breakpoint-below-tablet} {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  & .active-tab {
    border-bottom: 2px solid #202124;
    opacity: 1;
  }

  & .guest-color {
    opacity: 1;
    margin-top: 3px;
  }

  & .fav-store {
    margin-right: auto;
    margin-left: 40px;

    @media #{$breakpoint-below-tablet} {
      margin-left: 20px;
      margin-right: auto;
    }
  }

  & .store-form-separation {
    margin-top: 50px !important;
    border-top: 1px solid $Light-Gray;

    @media #{$breakpoint-below-tablet} {
      margin-top: 20px !important;
    }
  }

  & .member-signin {
    color: $Brand-Black;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    width: 55%;
    padding-top: 1px;

    & a {
      margin-left: 2%;
      text-decoration: underline;
    }
  }

  .store-signin-link {
    color: $Brand-Black !important;
  }

  .store-location-link {
    color: $Brand-Black !important;
    vertical-align: middle;
    text-decoration: underline;
  }

  .enter-your-address-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: $National2Regular;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;
    opacity: 0.8;
  }

  .map-link {
    color: $Brand-Black !important;
    font-weight: bold;
    & .map-img {
      margin-right: 5px;
      height: 10px;
      width: 11px;
    }
  }
  .schedule-order-btn:first-of-type {
    background-color: $Brand-Red;
    border: 1px solid $Brand-Red !important;
    color: #fff;
  }
  .schedule-order-btn:first-of-type :hover {
    background-color: $Brand-Red;
    border: 1px solid $Brand-Red !important;
    color: #fff;
  }
}

.inline-error-container {
  margin-bottom: 3%;

  &.location-blocked {
    display: flex;
    background: $Alert-Yellow;
    border-radius: 4px;
    padding: 2%;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;

    & img {
      padding-right: 10.04px;
      width: 29px;
      height: 25px;
    }
  }
}

.search-store-global-container {
  & .modal-content {
    & .modal-body {
      padding: 3% 0;
    }
  }
  &.catering-modal {
    & .custom-modal-back {
      display: none !important;
    }
  }
}

.store-card-map-view {
  position: absolute !important;
  top: 52%;
  width: 355px;
  height: 278px;
  margin: 0 !important;
  padding: 1% 2% !important;
  left: 0;

  @media #{$breakpoint-above-desktop} {
    top: 38%;
    height: auto;
    left: 10px;
  }

  @media #{$breakpoint-between-tablet-desktop} {
    top: 50%;
  }

  @media #{$large-only} {
    top: 38%;
  }

  @media #{$breakpoint-below-desktop} {
    //top: 35%;
    height: auto;
    left: 6px;
  }

  & .cardMapView {
    margin: 5px;
    padding: 11px 38px;

    @media #{$breakpoint-below-tablet} {
      margin-left: 15px;
    }
  }

  .schedule-order-btn {
    border: 1.5px solid $Brand-Black;
    padding: 10px 20px;

    @media #{$breakpoint-below-tablet} {
      padding: 11px 28px;
    }
  }
}

.store-card-mobile-view {
  margin: 2% 0;

  & .store-distance {
    margin-left: 5px;
  }

  & .search-store-card-btn {
    width: 100%;
    font-family: $National2Medium;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    margin: 0 1%;
    @media #{$mobile-only} {
      margin: 10px 0;
    }
  }
}

.order-button-section {
  position: relative;

  .store-card-map-view-error {
    position: absolute !important;
    margin-top: 48px;
    width: 100%;
    left: 0%;
    z-index: 9;
  }
}

.store-card {
  position: relative;
  padding: 3% 5%;
  margin: 2% 0;
  transition: 0.4s all;
  background: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;

  & .store-distance {
    margin-left: 5px;

    @media screen and (max-width: 575px) {
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .store-index {
    @media screen and (max-width: 575px) {
      font-size: 12px;
      margin-top: 8px;
      font-weight: 500;
    }
  }

  & .info-timing {
    font-family: $National2Medium;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    margin-top: 3px;
  }

  & .store-dist-container {
    &.map-view {
      flex: 2;
      display: flex;

      .store-name-container {
        max-width: 75%;
        width: fit-content;
        min-width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //margin-right: 5px;
      }
    }

    .store-name-container {
      display: inline;
    }
  }

  & .card-store-name {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 30px;
    color: $Brand-Black;
    //margin-right: 12px;
  }

  & .name {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .card-content {
    margin: 0;
    padding-top: 0;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    & .map-link {
      display: inline-block;

      & .card-map-link {
        color: $Brand-Black !important;
        font-weight: 500 !important;
        font-family: $National2Medium;
        font-size: 14px !important;
        line-height: 24px !important;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .more-info-link {
      margin-left: 0;
    }

    & .order-items {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 1%;
      width: 100%;
    }

    & .store-order-options {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
      text-transform: capitalize;
      margin-right: 9px;

      & .tick-image {
        height: 10px;
        width: 12px;
        margin-right: 5px;
      }

      & .unavail-tick-image {
        height: 13px;
        width: 13px;
        vertical-align: text-bottom;
      }
    }

    & .store-address {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $Dark-Gray;
      margin-right: 8px;
    }

    & .map {
      margin-left: 3%;
    }

    & .facilities {
      //display: inline-flex;
      // flex-wrap: wrap;
      // grid-gap: 3%;
      // gap: 3%;
      width: 100%;
      margin: 1% 0%;

      & .facility-img {
        margin-bottom: 5px;
        margin-right: 1%;
      }
    }

    & .info-timingClosed {
      color: $Red-Orange !important;
      font-family: $National2Medium;
      font-weight: 500 !important;

      & ul {
        display: flex;
        font-size: 14px;
        margin-bottom: 0;
        padding-left: 24px;

        & :first-child {
          list-style: none;
          position: relative;
          right: 12px;

          & .closed-text {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .browse-menu-btn {
    line-height: 22px;
    margin-bottom: 3px;
    width: 50%;
    padding: 11px 30px;
    max-width: 215px;
  }

  .schedule-btn {
    line-height: 22px;
    margin-bottom: 3px;
    width: 48%;
  }

  & .no-store {
    text-align: center;
    color: #f00;
    font-size: 14px;

    & .no-store-text {
      margin-top: 8px;
      margin-bottom: 24px;
    }

    & .browse-menu-btn {
      line-height: 22px;
      margin-bottom: 3px;
      max-width: 215px;
    }
  }
}

.delivery-map {
  & .card-store-name {
    margin-right: 2%;
  }
}
