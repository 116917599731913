.button-contained {
  $button-height: 2.75rem;
  $button-height-half: 1.4rem;

  // min-width: 11rem;
  height: $button-height;
  padding: 0 2.5rem;
  border: 0;
  border-radius: $button-height-half;
  font-size: 0.8rem;
  line-height: $button-height;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: $font-weight-500;
  text-align: center;
  display: inline-block;
  position: relative;
  background-color: transparent;
  transition-property: background-color, color;
  transition-duration: 0.35s;
  transition-timing-function: $ease-out;

  @media #{$breakpoint-above-tablet} {
    min-width: 10.3rem;
    white-space: nowrap;
  }

  @media #{$mobile-only} {
    // min-width: 10.6rem;
    height: 3.75rem;
    border-radius: 2.4rem;
    line-height: 1.75rem;
    white-space: wrap;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 0.1s;
    transition-timing-function: $ease-out;
  }

  &::before {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
  }

  &::after {
    width: calc(100% + 0.4rem);
    height: calc(100% + 0.4rem);
    border-radius: 1.7rem;
    box-shadow: 0 0 0 0.2rem $focus inset, 0 0 0 0.3rem $white inset;
    left: -0.2rem;
    top: -0.2rem;
    z-index: 1;
  }

  &:focus {
    outline: 0;

    &::after {
      opacity: 1;
    }
  }

  &:hover {
    &::before {
      opacity: 0.1;
    }

    &::after {
      opacity: 0;
    }

    &:active {
      &::after {
        opacity: 0;
      }
    }
  }

  &:active {
    &::before {
      opacity: 0.2;
    }
  }

  &[data-type='primary'] {
    background-color: $Brand-Red;
    color: $white;

    &::before {
      background-color: $black;
    }

    &[data-inactive='true'] {
      background-color: $Inactive-Gray;
      color: $Dark-Gray;
    }
  }

  &[data-type='secondary'] {
    background-color: $black;
    color: $white;

    &::before {
      background-color: $white;
    }

    &[data-inverted='true'] {
      background-color: $white;
      color: $Brand-Black;

      &::before {
        background-color: $Brand-Black;
      }
    }

    &[data-inactive='true'],
    &[data-disabled='true'],
    &[disabled] {
      background-color: $Inactive-Gray;
      color: $Dark-Gray;
      width: 280px;
      margin-left: 6px;
      @media #{$mobile-only} {
        width: 282px;
      }
    }
  }

  &[data-type='tertiary'] {
    box-shadow: 0 0 0 0.06rem $Brand-Black inset;
    color: $Brand-Black;

    &::before {
      background-color: $black;
    }

    &[data-inverted='true'] {
      box-shadow: 0 0 0 0.06rem $white inset;
      color: $white;
    }

    &[data-inactive='true'] {
      box-shadow: 0 0 0 0.06rem $Inactive-Gray inset;
      color: $Inactive-Gray;
    }
  }

  &[data-type='inverted'] {
    background-color: $white;
    color: $Brand-Black;

    &::before {
      background-color: $black;
    }

    &[data-inactive='true'] {
      background-color: $Inactive-Gray;
    }
  }

  &[data-inactive='true'],
  &[data-disabled='true'],
  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &[data-full-width='true'] {
    width: 100%;
  }

  &[data-has-prices='true'] {
    display: flex;
    justify-content: space-between;
    padding: 0 1.4rem;
  }

  &[data-small='true'] {
    height: 2rem;
    padding: 0 1rem;
    min-width: 0rem;
    border-radius: 1rem;
    font-size: 0.7rem;
    line-height: 1.9rem;
    font-family: $National2Regular;
    font-weight: $font-weight-400;

    @media #{$breakpoint-above-tablet} {
      font-size: 0.85rem;
    }

    &::after {
      border-radius: 1.3rem;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[data-loading='true']::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -0.71428571rem;
    margin-left: -0.71428571rem;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: #000;
    -webkit-animation: button-spinner 0.8s linear infinite;
    animation: button-spinner 0.8s linear infinite;
    box-shadow: none;
  }

  @keyframes button-spinner {
    to {
      transform: rotate(1turn);
    }
  }
}

a.button {
  text-decoration: none;
}

.button-contained-disabled {
  background-color: $Inactive-Gray !important;
  color: $Dark-Gray !important;
}
