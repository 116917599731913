.coupon {
  &--badge {
    position: absolute;
    top: 4%;
    left: -11px;

    &_text {
      position: relative;
      z-index: 2;
      padding: 4px 8px;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 1;
      font-family: var(--fontFamilyCondensed);
      overflow-wrap: break-word;
      text-transform: uppercase;
      white-space: nowrap;
      background-color: #e4002b;
      color: #fff;
      box-shadow: -5px 2px 4px 1px rgba(32, 33, 36, 0.2392156863);

      @media #{$breakpoint-above-tablet} {
        font-size: 16px;
      }

      span {
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }
    }

    &--arrow {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #e4002c;
      position: absolute;

      &.start {
        bottom: 1.8px;
        left: 0.4px;
        transform: translateY(100%) rotate(45deg);
      }

      &.large {
        z-index: 1;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #e4002c;
      }

      &.end-top {
        top: -6px;
        right: -14px;
        transform: translateY(100%);
      }

      &.end-bottom {
        bottom: 24px;
        right: -14px;
        transform: translateY(100%) rotate(180deg);
      }

      @media #{$breakpoint-above-tablet} {
        &.end-top {
          top: -3.5px;
        }

        &.end-bottom {
          bottom: 26.5px;
        }
      }
    }
  }
}
