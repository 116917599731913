.clear-cart-modal {
  .modal-content {
    .modal-header .custom-modal-close {
      @media screen and (max-width: 420px) {
        margin: 5px;
        margin-top: 0;
      }
    }

    height: 344px;

    @media #{$breakpoint-below-tablet} {
      height: 100%;
    }

    @media #{$breakpoint-below-desktop-above-tablet} {
      width: 50%;
      left: 25%;
    }

    .modal-body {
      @media #{$breakpoint-below-include-iphone5} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .alert-title {
    padding: 0 3%;
    margin-top: 15px;

    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      padding: 0;
    }

    .alert-red-text {
      color: $Brand-Red;
    }
  }

  .alert-cart-container {
    margin-top: 30px;

    button {
      margin: 0;
    }
  }
}
