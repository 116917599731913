.rewards-container {
  overflow-x: hidden;

  // This overrides the default grid-container(_all.scss) because the Rewards v2 design features wider components
  // When the other pages will be updated to have wider components, this can be removed.
  .grid-container {
    max-width: 1224px;
    padding: 0;
  }

  @media #{$mobile-only} {
    overflow-x: visible;
  }

  @media #{$breakpoint-between-mob-tablet} {
    overflow-x: visible;
  }

  .rewards-page-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $Mashed-Potato-Gray;

    &--stripe-img {
      margin: 0 auto 16px;
      height: 20px;
    }

    &--title {
      margin-bottom: 16px;
      font-family: $National2Condensed;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.5px;
      @media #{$breakpoint-above-mobile} {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 1px;
      }
    }
  }
}
