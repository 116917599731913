.delivery-address-form {
  margin: 0 15px;

  &-search-text {
    color: $Brand-Black;
    font-style: normal;
    font-family: $National2Condensed;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
  }

  @media #{$breakpoint-tablet-only} {
    .address-field-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .login-section {
    justify-content: center;
    margin-bottom: 32px;
    width: 100%;
  }

  .address-search-form,
  .address-text-field-container {
    margin: 32px 0;
  }

  .text-field-container {
    margin: auto auto 32px;
    max-width: 392px;
    width: 100%;
  }

  .text-field-content {
    padding: 5px 0 10px;

    &.material-input.field .address-text-field {
      padding: 4px 0 8px;
    }

    &.material-input.field input:placeholder-shown + label {
      font-size: 11px;
    }

    &.material-input.field input:focus + label {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-confirm-cta {
    height: 44px;
    border-radius: 22px;
    border: none;
    padding: 0 40px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;
    background-color: $Brand-Black;
    color: $white;
    display: block;
    // needed to override another rule
    margin: auto !important;

    &.button-contained[data-type='secondary'][disabled] {
      width: auto;
    }
  }

  .undeliverable-address {
    .alert-location-popup {
      position: static;
      max-width: 392px;
      margin: 30px auto;
      text-align: center;
      padding: 0 24px 14px;
    }
  }

  .third-party-delivery-services {
    // width - to match adjacent elements
    max-width: 602px;
    margin: 0 auto;
    font-size: 14px;

    @media #{$breakpoint-below-tablet} {
      max-width: 392px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    &__service {
      display: block;
      color: inherit;
      text-align: center;
    }

    &__image-container {
      display: flex;
      justify-content: center;
      min-height: 162px;
      margin-bottom: 10px;
      background: $white;
      position: relative;

      @media #{$breakpoint-below-tablet} {
        min-height: 88px;
      }
    }

    &__images {
      max-width: 50%;
      position: absolute;
      top: 40%;
    }

    &__image {
      max-width: 100%;
      filter: brightness(2%);
    }

    &__label {
      display: inline-block;
      text-decoration: underline;
      margin-right: 5px;
    }

    &__notice {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
    }
  }

  .delivery-saved-addresses {
    margin: auto;
    max-width: 437px;
  }

  .placesautocomplete-border {
    border-top: 1px solid $Light-Gray;
    margin: 5% 0;
  }
}

.name-field,
.default-address-section {
  width: 100%;
  max-width: 392px;
  margin: 0 auto;
  @media #{$mobile-tablet-only} {
    width: auto;
    margin: 0 15px;
  }
}
.default-address-section {
  display: flex;
  margin-top: 14px;
  input[type='checkbox'] {
    margin-top: 5px;
  }
}
.default-label-text {
  margin: 0 0 0 10px;
}
