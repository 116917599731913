.communication-thankyou-container,
.communication-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  min-height: 60vh;
}

.communication-thankyou-confirm-icon,
.communication-error-icon {
  display: flex;
  width: 80px;
  height: 81.481px;
  padding: 4.074px 9.925px 2.037px 9.978px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.communication-main-text {
  color: $Brand-Black;
  text-align: center;
  font-family: $National2Condensed;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.communication-subTitle-text {
  color: #202124;
  text-align: center;
  font-family: $National2Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.1px;
  width: 100%;
}

@media #{$breakpoint-below-tablet} {
  .communication-main-text {
    font-size: 36px;
    line-height: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .communication-main-text {
    font-size: 44px;
    line-height: 30px;
  }
}
