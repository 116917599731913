a {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
}
