.banner-component-container {
  margin: 80px auto;
  width: 60%;
  max-width: 900px;
  background-color: #f8f7f5;
  text-align: center;
  height: 503px;
  @media only screen and (min-device-width: 1200px) and (max-device-width: 1499px) {
    width: 780px;
  }
  @media #{$mobile-only} {
    margin: 40px 20px 20px 20px !important;
    width: auto;
  }

  @media #{$breakpoint-below-tablet} {
    margin: 40px 20px;
    height: auto;
    width: auto;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    height: auto;
    @media (orientation: portrait) {
      margin: 40px 22%;
      height: auto;
      width: auto;
    }
  }
  @media #{$breakpoint-ipad-8-landscape} {
    @media (orientation: landscape) {
      margin: 40px 22%;
      height: 503px;
      width: auto;
    }
  }
  @media only screen and (min-device-width: 1200px) {
    height: auto;
  }

  .brand-headline {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 68px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #202124;

    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: unset;
      width: auto;
      padding-top: 11px;
    }
    @media #{$mobile-tablet-only} {
      font-size: 30px;
      padding-top: 40px;
    }
    @media #{$breakpoint-below-include-iphone5} {
      font-size: 21px;
      padding-top: 20px;
    }
    @media #{$breakpoint-iphone-se-2022} {
      font-size: 26px;
      padding-top: 20px;
    }
    @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
      font-size: 36px;
      padding-top: 40px;
    }
    @media #{$breakpoint-above-large-desktop} {
      width: auto;
    }
    @media #{$breakpoint-below-and-above-medium-desktop} {
      width: 80%;
    }
  }

  .banner-component-section {
    padding: 0px 17% 50px 17%;
    @media #{$breakpoint-below-desktop} {
      padding: 50px 100px;
    }
    @media #{$breakpoint-below-desktop-above-tablet} {
      padding: 50px;
    }
    @media #{$breakpoint-below-tablet} {
      padding: 10%;
    }
    @media #{$breakpoint-between-mob-tablet} {
      padding: 0% 35% 24px 35%;
    }
    // @media #{$breakpoint-iphone-plus} {
    //     padding: 10% 15%;
    // }
    @media #{$mobile-only} {
      padding: 0.5% 10% 15.5% 8% !important;
      height: auto;
    }

    .icon-section {
      display: flex;
      margin: 40px 0;
      justify-content: space-around;
      @media #{$breakpoint-below-tablet} {
        margin: 30px -10%;
      }
      @media #{$breakpoint-between-mob-tablet} {
        margin: 30px -35%;
      }
      @media #{$mobile-only} {
        margin: 40px -10% 25px;
      }

      .icon-wrapper {
        img {
          height: 75px;
        }
      }
      .icondrive {
        img {
          width: 65px;
          height: 78px;
          margin-top: 7px;
          @media #{$mobile-only} {
            width: 58px;
            height: 80px;
            margin-top: 0px;
          }
        }
        .icon-title {
          text-align: left;
          @media #{$mobile-only} {
            margin-top: 13px;
          }
        }
      }
      .iconpickup {
        img {
          width: 63px;
          height: 82px;
          margin-bottom: 3px;
          @media #{$mobile-only} {
            width: 56px;
            height: 73px;
            margin-bottom: 0px;
          }
        }
      }
      .icondelivery {
        img {
          width: 58px;
          height: 85px;
          @media #{$mobile-only} {
            width: 51px;
            height: 75px;
          }
        }
        .icon-title {
          @media #{$mobile-only} {
            margin-top: 17px;
          }
        }
      }
      .icon-title {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #202124;
        margin-top: 18px;
        @media #{$mobile-only} {
          margin-top: 20px;
        }
      }
    }

    .find-more-btn {
      margin-top: 30px;
      @media #{$breakpoint-below-tablet} {
        margin-top: 16px;
      }
      a {
        -webkit-appearance: button-bevel;
        -moz-appearance: button;
        appearance: button;
        text-decoration: none;
        color: inherit;
        font-family: $National2Medium;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
