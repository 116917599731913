.navigation {
  display: none;
  //position: sticky;
  //z-index: 9998;
  border-bottom: 1px solid $Inactive-Gray;
  margin: 0 !important;
  width: 100%;
  background: $Mashed-Potato-Gray;
  height: 50px;

  @media #{$mobile-only} {
    display: flex;
  }

  @media #{$breakpoint-between-mob-tablet} {
    display: flex;
    justify-content: center;
  }

  .navigation-list {
    display: flex;
    margin-bottom: 0;
    margin-top: 9px;
    overflow-y: hidden;
    padding: 0;
  }

  .navigation-list::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .navigation-item {
    display: flex;
    height: 40px;

    a {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      text-decoration: none;
      white-space: nowrap;
      padding: 0 8px;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    a.current {
      border-bottom: 2px solid $Brand-Black;
      font-family: $National2Medium;
      font-weight: 500;
      letter-spacing: -0.1px;
      background-color: transparent;
      color: $light-black-color;
      text-decoration: none;
    }
    a::after {
      display: none;
    }
  }
}
