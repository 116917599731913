// OTP SCREEN
.otp-main-container {
  height: 477px;
  // width: 580px;
  margin: 0 10%;
  left: 0px;
  top: 0px;
  border-radius: 3px;
  background-color: #f8f7f5;
  // margin: 0 0 80px 192px; in modal not needed this
  // width: 42%;
  display: flex;
  flex-direction: row;
  @media #{$breakpoint-below-tablet} {
    display: unset;
    height: 697px;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    margin: unset;
  }
  .left-section {
    width: 7rem;
    margin: 40px 20px;
    font-family: $National2Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-align: left;
    color: $Brand-Black;
    @media #{$breakpoint-below-tablet} {
      width: unset;
      text-align: center;
    }
  }
  .right-section {
    width: 75%;
    // margin: 40px 20px;
    margin: 40px 0px;
    font-family: $National2Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.10000000149011612px;
    text-align: center;
    margin-bottom: 10px;
    @media #{$breakpoint-below-tablet} {
      width: unset;
    }
    .otp-title-text {
      font-family: $National2Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.10000000149011612px;
      text-align: center;
      margin-bottom: 10px;
      color: #494949;
      @media #{$breakpoint-below-tablet} {
        display: none;
      }
    }
    .sendto {
      font-family: $National2Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
      color: $Brand-Black;
      @media #{$breakpoint-below-tablet} {
        margin-bottom: 10px;
      }
    }
    .useDifferentNumberButton {
      border: 0;
      background: transparent;
      width: fit-content;
      margin: 10px auto 0px auto;
      font-family: 'National 2 Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      text-decoration-line: underline !important;
      color: #494949 !important;
    }
    .otpDiv {
      text-align: center;
      display: flex;
      // margin: 0 auto;
      width: 60%;
      margin: 1% auto 4%;
      position: relative;
      img {
        position: absolute;
        right: 0px;
      }
    }
    .otpNum {
      border-radius: 0px;
      width: 13%;
      margin: 0 auto;
      border: none;
      border-bottom: 2px solid #494949;
      outline: none;
      text-align: center;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 44px;
      text-transform: uppercase;
      color: #202124;
      // color: #494949;
      background: transparent;
      padding: 2% !important;
      @media #{$breakpoint-above-tablet} {
        width: 15%;
        font-size: 40px;
      }
    }
    .otpNum-invalid {
      color: $Brand-Black !important;
      border-bottom: 2px solid red;
    }
    .otp-expired-text {
      margin-top: 35px;
      color: #494949;
    }
    .otp-link-button {
      margin: 10px 0px !important;
      border: none;
      background: transparent;
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      text-decoration-line: underline !important;
      color: #494949 !important;
      cursor: pointer;
      width: max-content;
      // margin: auto;
    }
    .disableOtpLinkCls {
      color: #dbdbdb !important;
    }
    .otp-link-button:focus {
      outline: none;
    }
    .buttons-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0px;
    }
    .buttons-group {
      border: 1.5px solid #202124;
      box-sizing: border-box;
      border-radius: 200px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .save {
      color: white;
      background: $Brand-Black;
      // margin-left: 8px;
      padding: 10px 40px;
      // width: 129px;
      font-family: $National2Regular;
    }
    .save-disabled {
      color: #202124;
      background: #dbdbdb;
      // margin-left: 8px;
      padding: 10px 40px;
      font-family: $National2Regular;
      border: 1.5px solid #dbdbdb;
    }
  }
  // @media #{$breakpoint-ipad-12-pro-portrait} {
  //     top: 10% !important;
  //     // height: unset;
  //     // width: unset;
  // }
}

.customized-otp-modal {
  & .modal-content {
    @media #{$breakpoint-ipad-12-pro-portrait} {
      top: 10% !important;
      height: unset;
      // left: 30%;
    }
    & .modal-body {
      overflow-y: auto;
    }
  }
  & .custom-modal-close {
    position: unset !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.otp-attempt-error-show {
  color: $Brand-Black;
  font-family: var(--fontFamilyRegular);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 56px;
  width: 85%;
  background: #ffeeee;
  padding: 0px 10px;
  border-radius: 5px;
  img {
    margin-right: 5px;
  }
  @media #{$breakpoint-below-desktop} {
    height: 64px;
    margin-left: 12px;
    width: 328px;
  }
}

.dob-error-show {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  img {
    margin-right: 5px;
  }
}
