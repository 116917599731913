.common-header {
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #dbdbdb;
}

.common-header img {
  margin: auto;
}
