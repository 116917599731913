.menu-links-container {
  display: flex;
  flex-direction: row;
  height: 17px;
  margin: auto 0;

  @media #{$breakpoint-below-tablet} {
    flex-direction: column;
    margin: 40px 18px 0px 22px;
    height: auto;
  }
  @media #{$mobile-only} {
    margin: 35px 18px 0px 22px;
  }

  .menu-link {
    cursor: pointer;
    margin-right: 40px;
    .menu-link-image {
      display: none;
    }
    .menu-link-name {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      color: $Brand-Black;
      margin: auto 0;

      &--jp {
        font-size: 18px;
        font-weight: 700;
      }
    }
    @media #{$mobile-only} {
      padding: 15px 0 15px 20px;
    }
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 10px;
      width: 100%;
      height: 115px;
      background-color: $Mashed-Potato-Gray;
      padding: 15px 0 15px 22px;
      border-radius: 3px;
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .menu-link-name {
        display: inline-block;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .menu-link-image {
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        width: 202px;
        height: 114px;
      }
    }
  }
  .menu-link:last-of-type {
    margin-right: 0;
  }
  .menu-link:last-child {
    margin-bottom: 0;
  }
}
